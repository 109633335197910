import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import imgMain from "../../../assets/Images/mainImg.png";
// import loginicon from "../../../assets/Images/nutaskLgScreen.png";
// import loginMobicon from "../../../assets/Images/nutasklogo.png";
// import { NuInputPassword, NuInputText } from '../../../Components/Utils/NuInput';
import jwt_decode from "jwt-decode";
import { notification } from 'antd';
import { Link } from 'react-router-dom';
import { NuInputPassword, NuInputText } from '../../../../Components/Utils/NuInput';
// import { Button, Form, Input, Checkbox } from "antd";

axios.defaults.baseURL = window.WEB_APP_API_URL;

function Login() {
  const [api, contextHolder] = notification.useNotification();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      window.location.replace("/main/dashboard");
    }
  }, []);

  const [createNew, setCreateNew] = useState({
    email: '',
    password: '',
  });
  const formik = useFormik({
    initialValues: createNew,
    validationSchema: yup.object({
      email: yup.string().required('Email is Required'),
      password: yup.string().required('Password is Required'),
    }),
    onSubmit: async (value) => {
      //   alert(value.username);
      await axios
        .post('/Auth/login', value)
        .then((res) => {
          if (res.data.status) {
            let userToken = res?.data?.token;
            let decodeToken = jwt_decode(userToken);
            localStorage.setItem('userDetails', JSON.stringify(decodeToken));
            localStorage.setItem('userToken', JSON.stringify(res?.data?.token));
            localStorage.setItem('userAccess', JSON.stringify(res?.data?.permissions));
            api.success({
              message: `Login successful`,
              description: res.data.msg || 'Successfully logged in  ',
              placement: 'bottomRight',
            });
            window.location.replace("/");
            // navigate('');
          } else {
            api.error({
              message: `Login failed`,
              description: res.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Login failed`,
            description: err.msg || 'Network error',
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <div className='w-full h-screen  flex'>
      {contextHolder}
      <div className=' w-full lg:w-1/2 h-full flex justify-center items-center'>

      {screenSize.width<="786"?
      
      <div className=' w-full h-full flex flex-col justify-center items-center'>
        <div className=' w-[98px]'>
        {/* <img src={loginMobicon} width="100%" alt="loginicon" /> */}
        </div>

        <p className="font-semibold text-[36px] text-[#344767] mb-32">Manage Easily</p>

        <div className=' w-11/12  text-left'>
          <div className='w-full  flex flex-col text-sm '>
            <form onSubmit={formik.handleSubmit} className=''>
              <div className=' justify-center '>
                <NuInputText
                  label='Email *'
                  placeholder='Enter Your Mail'
                  formik={formik}
                  name='email'
                  width='w-full'
                  padding='py-3'
                />

                <NuInputPassword
                  label='Password *'
                  placeholder='************'
                  formik={formik}
                  name='password'
                  width='w-full'
                  padding='py-3'
                />
              </div>

              <Link to="/auth/forget-password">
                <p className='text-[#3267ff] px-3 font-sans not-italic text-xs medium text-right'>Forgot Password-</p>
              </Link>
              <button type='submit' className=' border cursor-pointer text-white text-xs  border-[#EAEBED] bg-[#119DEE] h-12  mx-3 mt-6 rounded-md w-[95%]'>
                Login
              </button>
            </form>
          </div>
        </div>
        <p className=' mt-28 pt-9  font-semibold text-center text-xs text-[#B3B2B9]'>&#169;  numota</p>

      </div> 
       :
      <div className=' w-[60%] rounded-2xl shadow-2xl px-4 pt-9 pb-12'>
        
      <div className=' w-full h-[30%] flex justify-center gap-8 items-center pb-16'>
        <div className=' w-[63px] '>
        {/* <img src={loginicon} width="100%" alt="loginicon" /> */}
        </div>
        <p className="font-semibold text-[36px] text-[#344767]">Manage Easily</p>
      </div>

      <h3 className=' my-10 text-[24px] font-semibold text-center text-[#344767]'>Log in</h3>

      <div className='w-full h-[70%] flex items-start lg:items-center justify-center'>
        <div className=' w-10/12  text-left'>
          <div className='w-full  flex flex-col '>
            <form onSubmit={formik.handleSubmit} className=''>
              <div className=' justify-center '>
                <NuInputText
                  label='Email *'
                  placeholder='Enter Your Mail'
                  formik={formik}
                  name='email'
                  width='w-full'
                  padding='py-2'
                />

                <NuInputPassword
                  label='Password *'
                  placeholder='************'
                  formik={formik}
                  name='password'
                  width='w-full'
                  padding='py-2'
                />
              </div>

              <Link to="/auth/forget-password">
                <p className='text-[#3267ff] px-3 font-sans not-italic text-sm medium text-right'>Forgot Password-</p>
              </Link>
              <button type='submit' className=' border cursor-pointer text-white text-xs  border-[#EAEBED] bg-[#119DEE] h-10  mx-3 mt-6 rounded-md w-[95%]'>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>

      </div>
      }
      </div>
      <div className=' hidden lg:block w-1/2  h-[100%]'>
        {/* <img src={imgMain} width="87%" alt="#"  /> */}
        </div>
    </div>
  );
}
export default Login;
