import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import StatusPayable from './StatusPayable';
import CategoryPayable from './CategoryPayable';
import AccountTypePayable from './AccountTypePayable';
import DescriptionPayable from './DescriptionPayable';



function PayableSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Payable" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <AccountTypePayable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <CategoryPayable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusPayable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DescriptionPayable/>
        </div>
        
        
        </div>
    </div>
  )
}

export default PayableSettingsIndex;