import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import CategoryCash from './CategoryCash';
import PaidOrReceivedCash from './PaidOrReceivedCash';
// import ApprovedBy from './ApprovedByCash.jsx';



function CashSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Cash" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
       
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <CategoryCash/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            {/* <PaidOrReceivedCash/> */}
        </div>
       
        
        </div>
    </div>
  )
}

export default CashSettingsIndex;