import React from 'react'
import { Outlet } from 'react-router-dom'

function MainInvoiceIn() {
    return (
        <div className="w-full h-full">
        <Outlet/>
        </div>
      )
    }
    

export default MainInvoiceIn