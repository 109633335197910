import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import PeriodPayroll from './Payroll';


function PayrollSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Payroll" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <PeriodPayroll/>
        </div>
        
        </div>
    </div>
  )
}

export default PayrollSettingsIndex;