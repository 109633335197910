import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import Advances from './Advances';
import DivisionAdvance from './DivisionAdvances';
import TypeAdvances from './TypeAdvances';
import StatusAdvance from './StatusAdvance';


function AdvanceSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Advances" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Advances/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DivisionAdvance
            />
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusAdvance />
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeAdvances/>
        </div>
        
        </div>
    </div>
  )
}

export default AdvanceSettingsIndex;