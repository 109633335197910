import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
function EditReceivable() {
  const {value:DescriptionReceivable} = useFetchMataData(`/Metadata/receivable_description`)
  const {value:StatusReceivable} = useFetchMataData(`/Metadata/status_receivable`)
  const {value:AccountTypeReceivable} = useFetchMataData(`/Metadata/accountType_receivable`)
  const {value:CategoryReceivable} = useFetchMataData(`/Metadata/receivable_category`)

    const [api, contextHolder] = notification .useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const navigate = useNavigate();
    const { receivableEditId } = useParams();
    const [createNew, setCreateNew] = useState({
        invoiceOUTId:"",
        description:"",
        category:"",
        accountType:"",
        receiveFrom:"",
        amount:"",
        dueDate:"",
        daysElapsed:"",
        receivedAmount:"",
        balanceAmount:"",
        paymentMethod:"",
        bankId:"",
        receivedOn:"",
        comments:"",
        status:""
    });
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      //   firstName: yup.string().required("Name Required"),
      //   // dob: yup
      //   //   .string()
      //   //   .required("Date of Birth Required"),
      //   gender: yup.string().required("Gender is Required"),
      //   mobileNo: yup
      //     .string()
      //     .required("Mobile Number is required")
      //     .matches(
      //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //       "Enter Valid Mobile Number"
      //     )
      //     .min(9, "Invalid Mobile Number")
      //     .max(10, "Invalid Mobile Number"),
      //   email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        axiosInstance.put(`/receivable/${receivableEditId}`, value)
            .then((res) => {
              if (res?.data?.status === true) {
                // send_notif();
                resetForm();
                navigate(-1);
                api.success({
                  message: 'Success',
                  description:res.data.msg || 'Receivable Updated.',
                  placement: "bottomRight",
                });
              } else {
                api.error({
                  message: `Failed`,
                  description: res?.data?.msg || 'Network error',
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              api.error({
                message: `Failed`,
                description: err?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            });
      },
    });
  
    async function getReceivable() {
      await axiosInstance
        .get(`/receivable/${receivableEditId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setCreateNew(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const bankDropDown = () =>{
      const params = {
        page:1,
        per_page:20,
      };
      axiosInstance.get('/bank/',{ params })
      .then((res)=>{
          let finalArray = [];
          // console.log(res?.data?.data,'ddddd');
          let banks = res?.data?.data.bankDetails
          banks.forEach(element => {
              let tempData = {value:element?.bankId, label:element?.bankName};
              finalArray.push(tempData);
          });
          res?.data?.data && setBanks(finalArray);
         })
        .catch(
          (err)=>{
            console.log(err);
          }
        )
  }
    useEffect(()=>{
      getReceivable();
      bankDropDown();    
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        {contextHolder}
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Receivable</p> },
            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <div className=" overflow-y-scroll h-[calc(100%-55px)]">
          <form onSubmit={formik.handleSubmit}>
          <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuInputNumber
              label="InvoiceOut Id"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="invoiceOUTId"
              width="w-full"
            />
              <NuSelect
              label="Description"
              // isRequired={true}
              options={DescriptionReceivable}
              formik={formik}
              placeholder="Choose"
              name="description"
              width="w-full"
            /> 
              <NuSelectwithSearch
              label="Category"
              isRequired={true}
              options={CategoryReceivable}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full"
            />
              <NuSelectwithSearch
                label="Account Type"
                isRequired={true}
                options={AccountTypeReceivable}
                formik={formik}
                placeholder="Choose"
                name="accountType"
                width="w-full"
              />
              <NuSelectwithSearch
                label="Receive From"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  // { value: "offline", label: "Cash" },
                  { value: "client", label: "client" },
                  { value: "customer", label: "customer" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="receiveFrom"
                width="w-full"
              />
              <NuInputNumber
                label="Amount"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="amount"
                width="w-full"
              />
              <NuDatePicker
              label=" Due Date"
              formik={formik}
              name="dueDate"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuInputNumber
                label="Days Elapsed"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="daysElapsed"
                width="w-full"
              />
            
              <NuInputNumber
                label="Received Amount"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="receivedAmount"
                width="w-full"
              />
              <NuInputNumber
                label="Balance Amount"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="balanceAmount"
                width="w-full"
              />
  
              <NuSelectwithSearch
                label="Payment Method"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  { value: "offline", label: "Cash" },
                  { value: "online", label: "Online" },
                  { value: "cheque", label: "Cheque" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="paymentMethod"
                width="w-full"
              />
              {(formik.values.paymentMethod == 'cheque') && (<NuInputNumber
                label="Cheque Number"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="chequeNumber"
                width="w-full"
              />)}
  
              {(formik.values.paymentMethod == "online") && (
                <NuSelect
                  label="Bank Name"
                  isRequired={true}
                  options={[
                    // { value: "Bank", label: "Bank" },
                    { value: "SBI", label: "SBI" },
                    { value: "Indian", label: "Indian" },
                    { value: "HDFC", label: "HDFC" },
                  ]}
                  placeholder="Name"
                  formik={formik}
                  name="bankId"
                  width="w-full"
                />
                
              )}
              <NuInputNumber
                label="Bank Id"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="bankId"
                width="w-full"
              />
              <NuDatePicker
              label="Received On"
              formik={formik}
              name="receivedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuSelectwithSearch
              label="Status"
              isRequired={true}
              options={StatusReceivable}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full"
            />
              
              {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
                <NuInputText
                  label="IFSE"
                  isRequired={true}
                  placeholder="IFSE"
                  formik={formik}
                  name="ifse"
                  width="w-full"
                />
              )}
  
              {formik.values.transaction_method == "Bank" && (
                <NuInputText
                  label="Transaction Reference"
                  isRequired={true}
                  placeholder="Transaction Reference"
                  formik={formik}
                  name="transaction_reference"
                  width="w-full"
                />
              )} */}
  
  {/* {formik.values.transaction_method == "Cash" && (
                <NuInputText
                  label="Voucher Number"
                  isRequired={true}
                  placeholder="Voucher Number"
                  formik={formik}
                  name="voucher_number"
                  width="w-full"
                />
              )} */}
  
  {/* {formik.values.transaction_method == "Online" && (
                <NuInputText
                  label="Online Transaction Number"
                  isRequired={true}
                  placeholder="Online Transaction Number"
                  formik={formik}
                  name="online_transaction_number"
                  width="w-full"
                />
              )} */}
  
  {/* {formik.values.transaction_method == "Check" && (
                <NuInputText
                  label="Bank Name"
                  isRequired={true}
                  placeholder="Name"
                  formik={formik}
                  name="bank"
                  width="w-full"
                />
              )} */}
  
              <div className=" col-span-2">
                  <NuTextArea
                  label="Comments"
                  // isRequired={true}
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full"
                />
                </div>
            </div>
            <div className='flex justify-center gap-2'>
              <SubmitButton/>
              <CancelButton handleClick={()=>{navigate(-1)}}/>
            </div>
          </form>
        </div>
      </div>
    );
  }
export default EditReceivable