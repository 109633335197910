import React, { useEffect, useState } from 'react'
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";

function CreateVat() {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  let navigate = useNavigate  ();
  const [createNew, setCreateNew] = useState({
    branchId:1,
    accountType:"",
    accountName:"",
    accountNumber:"",
    bankName:"",
    address:"",
    routingNumber:"",
    ifscCode:"",
    swiftBicCode:"",
    micrCode:"",
    contactNumber:"",
    email:""
});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
  
    }),
    onSubmit: async (value, { resetForm }) => {
      value.contactNumber = `${value.contactNumber}`
      axiosInstance.post("/bank/", value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Accounts created.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    axiosInstance.get('/bank/',{ params })
    .then((res)=>{
        let finalArray = [];
        console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect(()=>{
    bankDropDown();    
  },[]);

  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Vat</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuSelectwithSearch
              label="Account Type"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "Current", label: "Current" },
                { value: "Saving", label: "Saving" },
               
              ]}
              formik={formik}
              placeholder="Choose"
              name="accountType"
              width="w-full"
            />
             <NuInputText
                label="Account Name"
                isRequired={true}
                placeholder="Account Name"
                formik={formik}
                name="accountName"
                width="w-full"
            />
           
            <NuInputNumber
              label="Account Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="accountNumber"
              width="w-full"
            />
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}

export default CreateVat