import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import AxiosInstance from '../../../Services/axiosCommon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';

function EditVouchers() {
  // const {value:voucherDivision} = useFetchMataData(`/Metadata/voucher_division`)
  // const {value:receivedOrPaid} = useFetchMataData(`/Metadata/receivedOrPaid`)
  const {value:voucherDescription} = useFetchMataData(`/Metadata/voucher_description`)
  const {value:voucherCategory} = useFetchMataData(`/Metadata/voucher_category`)
  const {value:voucherType} = useFetchMataData(`/Metadata/account_type`)
 
  const {vouchersEditId} = useParams()
  let location = useLocation();
  const {data} = location.state||[]
  console.log("cc",data);
  let navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    branchId: 1,
    date: "",
    description: "",
    type: "",
    category: "",
    division: "",
    receivedOrPaid: "",
    invoiceId: "",
    paymentType: "",
    receivedfromOrPaidTo: "",
    representativeId:null,
    comments: "",
    chequeNumber: "",
    
  });

  useEffect(()=>{
    data && setCreateNew({
      branchId: data?.branchId||"",
      date: data?.date||"",
      description: data?.description||"",
      type: data?.type||"",
      category:data?.category||"",
      division: data?.division||"",
      receivedOrPaid: data?.receivedOrPaid||"",
      invoiceId: data?.invoiceId||"",
      paymentType: data?.paymentType||"",
      receivedfromOrPaidTo:data?.receivedfromOrPaidTo||"",
      representativeId: data?.representativeId||null,
      comments: data?.comments||"",
      chequeNumber:data?.chequeNumber|| "",
    })
  },[data])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData()
      formData.append('voucher',JSON.stringify(value) )
      formData.append('attachments',[])
      AxiosInstance.put(`/voucher/${vouchersEditId}`,formData).then(res=>{
        if (res?.data?.status === true) {
          // send_notif();
          resetForm();
          navigate(-1);
          api.success({
            message: 'Success',
            description:res.data.msg || 'Cash created.',
            placement: "bottomRight",
          });
        } else {
          api.error({
            message: `Failed`,
            description: res?.data?.msg || 'Network error',
            placement: "bottomRight",
          });
        }
      }).catch(err=>{
        api.error({
          message: `Failed`,
          description: err?.data?.msg || 'Network error',
          placement: "bottomRight",
        });
      })
    },
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Vouchers</p> },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
      <form onSubmit={formik.handleSubmit}>
          <div className=" w-full h-full flex justify-between items-start gap-2">
            <div className=" w-[74%] h-full">
            <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
                {/* <NuInputNumber
                  label="ID"
                  placeholder="ID"
                  // disable={true}
                  formik={formik}
                  name="id"
                  width="w-full"
                /> */}
                <NuDatePicker
                  label="Date"
                  formik={formik}
                  name="date"
                  width=" w-full "
                  max={NuDate({
                    value: Date.now(),
                    format: "yyyy-MM-DD",
                  })}
                  // isRequired={true}
                />
               
               <NuSelectwithSearch
                  label="Description"
                  // isRequired={true}
                  options={voucherDescription}
                  formik={formik}
                  placeholder="Choose"
                  name="description"
                  width="w-full"
                />
                  
                  <NuSelectwithSearch
                  label="Type"
                  // isRequired={true}
                  options={voucherType}
                  formik={formik}
                  placeholder="Choose"
                  name="type"
                  width="w-full"
                />
                <NuInputText
                  label="Division"
                  // isRequired={true}
                  // options={voucherDivision}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full"
                />


                <NuSelectwithSearch
                  label="Category"
                  // isRequired={true}
                  options={voucherCategory}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full"
                />

                <NuSelectwithSearch
                  label="Received/paid"
                  // isRequired={true}
                  options={[
                    {value:"Paid",label:"Paid"},
                    {value:"Received",label:"Received"}
                  ]}
                  formik={formik}
                  placeholder="Choose Received/paid"
                  name="receivedOrPaid"
                  width="w-full"
                />


                <NuInputText
                  label="Invoice / Bill Reference #"
                  // isRequired={true}
                  placeholder="Invoice / Bill Reference #"
                  formik={formik}
                  name="invoiceId"
                  width="w-full"
                />

                <NuSelectwithSearch
                  label="Payment type"
                  // isRequired={true}
                  options={[
                    { value: "Cash", label: "Cash" },
                    { value: "Cheque", label: "Cheque" },
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="paymentType"
                  width="w-full"
                  disabled={true}
                />

                {
                  formik.values.paymentType == "Cheque" ?
                  <NuInputText
                  label="Cheque Number"
                  // isRequired={true}
                  placeholder="Cheque Number"
                  formik={formik}
                  name="chequeNumber"
                  width="w-full"
                />
                :""
                }

                <NuSelectwithSearch
                  label="Received from / Paid to"
                  // isRequired={true}
                  options={[
                    { value: "Customer", label: "Customer" },
                    { value: "Internal", label: "Internal" },
                    { value: "Purchaser", label: "Purchaser" },
                  ]}
                  formik={formik}
                  placeholder="Choose Received from / Paid to"
                  name="receivedfromOrPaidTo"
                  width="w-full"
                />

                {formik.values.receivedfromOrPaidTo == "Customer" ? (
                  <>
                    <NuInputText
                      label="Representative Name"
                      // isRequired={true}
                      placeholder="Representative Name"
                      formik={formik}
                      name="Representative Name"
                      width="w-full"
                    />

                    <NuMobileNumber
                      label="Mobile"
                      placeholder="Mobile"
                      // disable={true}
                      formik={formik}
                      nunCode=""
                      name="mobile"
                      width="w-full"
                    />

                    <NuInputText
                      label="ID Type"
                      // isRequired={true}
                      placeholder="ID Type"
                      formik={formik}
                      name="ID Type"
                      width="w-full"
                    />

                    <NuInputNumber
                      label="ID Number"
                      placeholder="ID Number"
                      // disable={true}
                      formik={formik}
                      name="ID Number"
                      width="w-full"
                    />
                  </>
                ) : (
                  ""
                  // <NuSelectwithSearch
                  //   label="Received from / Paid to"
                  //   // isRequired={true}
                  //   options={[
                  //     { value: 1, label: "Customer" },
                  //     { value: 2, label: "Internal" },
                  //     { value: 3, label: "Purchaser" },
                  //   ]}
                  //   formik={formik}
                  //   placeholder="Choose Received from / Paid to"
                  //   name="representativeId"
                  //   width="w-full"
                  // />
              )}

                <NuTextArea
                  label="Comments"
                  // isRequired={true}
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full"
                />
              </div>
            </div>
            <div className=" w-[25%] mt-2 mb-6 h-full">
              <NuInputfile
                label="Attachment"
                placeholder="Attachment"
                // disable={true}
                // formik={formik}
                name="attachment"
                width="w-full"
              />
            </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}

export default EditVouchers;