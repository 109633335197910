import React, { useEffect, useState } from 'react'
import Delete from '../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function PayrollIndex() {
  const {officeId} =useParams();
  const [data, setData] = useState ([]);
  async function getRevenue() {
    const params = {
      page:1,
      per_page:20,
    };
    await axiosInstance
      .get(`/payroll/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data.payrollDetails);
        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Period</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Paid On</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Amount</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Payment Method</span>,
      width: "w-[10%]",
    },
    {
      label: <span>comments</span>,
      width: "w-[10%]",
    },
    
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getRevenue();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Payroll</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to="/main/payroll/${officeId}/create">
            <CreateButton label="Payroll" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={100}
        pageIndex={1}
        setPageIndex={() => {}}
        dataCount={20}
        setDataCount={() => {}}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {"#"+item?.payrollId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.period || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.paidOn || 0}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.amount || "-"}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.paymentMethod || 0}
                </td>
               
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.comments || ""}
                </td>
               
               
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/payroll/${officeId}/view/${item?.payrollId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/payroll/${officeId}/edit/${item?.payrollId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}
export default PayrollIndex;