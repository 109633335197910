import React, { useEffect, useState } from 'react'
import Delete from '../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function CashIndex() {
  const [data, setData] = useState([]);
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [totalCount,setTotalCount] = useState(0)
  const {officeId} =useParams();
  async function getCash() {
    const params = {
      page:page,
      per_page:pageCount,
    };
    await axiosInstance
      .get(`/cash/`,{ params })
      .then(async (res) => {
        console.log(res.data.data.cashDetails)
        res?.data?.data && setData(res?.data?.data.cashDetails);
        res?.data?.data?.totalRecords && setTotalCount(res?.data?.data?.totalRecords)

        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Paid/Received</span>,
      width: "w-[15%]",
    },
    {
      label: <span>To/From</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Delivered by</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Prepared by</span>,
      width: "w-[10%]",
    },
   
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getCash();
  },[page,pageCount]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Cash</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/cash/${officeId}/create`}>
            <CreateButton label="Cash" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {"#"+item?.cashId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.date || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.paidOrReceived || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.toOrFrom || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.deliveryBy || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.preparedBy || 0}
                </td>
              
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/cash/${officeId}/view/${item?.cashId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/cash/${officeId}/edit/${item?.cashId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default CashIndex;