import { Outlet } from "react-router-dom"



const Auth = () =>{
    return (
        <div className=" w-[100vw] h-screen">
            <Outlet />
        </div>
    )
}

export default Auth;