import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import CategoryInvoiceOut from './CategoryInvoiceOut';
import StatusInvoiceOut from './StatusInvoiceOut';
import ProjectInvoiceOut from './ProjectInvoiceOut';
import DivisionInvoiceOut from './DivisionInvoiceOut';
import AccountTypeInvoiceOut from './AccountTypeInvoiceOut';


function InvoiceoutSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Invoice-Out" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <AccountTypeInvoiceOut/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <CategoryInvoiceOut/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusInvoiceOut/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <ProjectInvoiceOut/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DivisionInvoiceOut/>
        </div>
        
        </div>
    </div>
  )
}

export default InvoiceoutSettingsIndex;