import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import PaymentTremCreditNote from './PaymentTremCreditNote';
import StatusCreditNote from './StatusCreditNote';


function CreditNoteSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Credi tNote" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <PaymentTremCreditNote/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusCreditNote/>
        </div>
        
        </div>
    </div>
  )
}

export default CreditNoteSettingsIndex;