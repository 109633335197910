import { useEffect, useState } from 'react'
import AxiosInstance from '../../Services/axiosCommon';


function useBankData() {
    const [bankList,setBankList] = useState([])
    function getData(){
        AxiosInstance.get(`/bank/drop_down`).then(res=>{
            let finalArray = [];
            let banks = res?.data?.data;
            res?.data?.data && setBankList(res?.data?.data)
            res?.data?.data && banks.forEach(element => {
                let tempData = {value:element?.bankId, label:`${element?.bankName} - ${element?.accountname||""}`,acNumber:element?.accountNumber};
                finalArray.push(tempData);
            });
            res?.data?.data && setBankList(finalArray);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {bankList}||[]
}

export default useBankData;