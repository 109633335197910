import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
import Attachement from '../../../Components/Utils/Attachement';

function EditCreateNotes() {
  const {value:PaymentTremCreditNote} = useFetchMataData(`/Metadata/paymentTrem_creditNote`)
  const {value:StatusCreditNote} = useFetchMataData(`/Metadata/status_creditNote`)

  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  const { creditNotesEditId } = useParams();
  const [banks, setBanks] = useState([]);
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    invoiceOutId:"",
    reasonForCreditNote:"",
    amount:"",
    discountRate:"",
    discountAmount:"",
    taxRate:"",
    taxAmount:"",
    totalAmount:"",
    requestedBy:"",
    reviewedBy:"",
    approvedBy:"",
    reviewedOn:"",
    approvedOn:"",
    paymentTerms:"",
    issuanceDate:"",
    paymentDueDate:"",
    refundReceivedOn:"",
    paymentMethod:"",
    bankId:"",
    comments:"",
    status:""

  });

  function handleFileChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("file", tempArr);
    setAttachment(tempArr);
  }

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedImage(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (imagetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("image", tempArr);
    setImage(tempArr);
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
    //   firstName: yup.string().required("Name Required"),
    //   // dob: yup
    //   //   .string()
    //   //   .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(9, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.put(`/credit_note/${creditNotesEditId}`, value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Credit_Note Updated.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  async function getCreditNotes() {
    await axiosInstance
      .get(`/credit_note/${creditNotesEditId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setCreateNew(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    axiosInstance.get('/bank/',{ params })
    .then((res)=>{
        let finalArray = [];
        // console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect(()=>{
    getCreditNotes();
    bankDropDown();    
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Credit_Note</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuInputNumber
              label="invoice #"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="invoiceOutId"
              width="w-full"
            />
             <NuInputText 
            label="Reason For CreditNote"
            isRequired={true}
            placeholder="Reason For CreditNote"
            formik={formik}
            name="reasonForCreditNote"
            width="w-full"
          />
           <NuInputNumber
            label="Amount"
            isRequired={true}
            placeholder="Amount"
            formik={formik}
            name="amount"
            width="w-full"
          />
           <NuInputNumber
            label="Discount Rate"
            isRequired={true}
            placeholder="%"
            formik={formik}
            name="discountRate"
            width="w-full"
          />
           <NuInputNumber
            label="Discount Amount"
            isRequired={true}
            placeholder="Discount Amount"
            formik={formik}
            name="discountAmount"
            width="w-full"
          />
           <NuInputNumber
            label="Tax Rate"
            isRequired={true}
            placeholder="Tax Rate"
            formik={formik}
            name="taxRate"
            width="w-full"
          />
           <NuInputNumber
            label=" Tax Amount"
            isRequired={true}
            placeholder=" Tax Amount"
            formik={formik}
            name="taxAmount"
            width="w-full"
          />
           <NuInputNumber
            label="Total Amount"
            isRequired={true}
            placeholder=" Total Amount"
            formik={formik}
            name="totalAmount"
            width="w-full"
          />
          
           
            <NuSelectwithSearch
              label="Requested By"
              isRequired={true}
              options={[
               
                { value: "Paid", label: "Paid" },
                { value: "Received", label: "Received" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Reviewed By"
              isRequired={true}
              options={[
               
                { value: "To", label: "To" },
                { value: "From", label: "From" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="reviewedBy"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Approved By"
              isRequired={true}
              options={[
               
                { value: "Credit", label: "Credit" },
                { value: "Debit", label: "Debit" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="approvedBy"
              width="w-full"
            />
           
            <NuDatePicker
              label="Reviewed On"
              formik={formik}
              name="reviewedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Approved On"
              formik={formik}
              name="approvedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuSelectwithSearch
              label="Payment Terms "
              isRequired={true}
              options={PaymentTremCreditNote}
              formik={formik}
              placeholder="Choose"
              name="paymentTerms"
              width="w-full"
            />
          <NuDatePicker
              label="Issuance Date"
              formik={formik}
              name="issuanceDate"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Payment Due Date"
              formik={formik}
              name="paymentDueDate"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Refund ReceivedOn"
              formik={formik}
              name="refundReceivedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
           
           <NuSelectwithSearch
              label="Payment Method"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="paymentMethod"
              width="w-full"
            />
            {(formik.values.paymentMethod == 'cheque') && (<NuInputNumber
              label="Cheque Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="chequeNumber"
              width="w-full"
            />)}

            {(formik.values.paymentMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  { value: "SBI", label: "SBI" },
                  { value: "Indian", label: "Indian" },
                  { value: "HDFC", label: "HDFC" },
                ]}
                placeholder="Name"
                formik={formik}
                name="bankId"
                width="w-full"
              />
              
            )}
         
         {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )}
             <NuInputNumber
              label="Bank Id "
              placeholder="0"
              // disable={true}
              formik={formik}
              name="bankId"
              width="w-full"
            />
            <NuSelect
              label="Status"
              placeholder="Choose"
              options={StatusCreditNote}
              formik={formik}
              name="status"
              width="w-full"
            />
              <div className=" col-span-2">
              <NuTextArea
                label="Comments"
                // isRequired={true}
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full"
              />
            </div>
{/* {formik.values.transactionMethod == "cheque" && (
              <NuInputNumber
                label="Cheque Number"
                // isRequired={true}
                placeholder="0"
                formik={formik}
                name="chequeNumber"
                width="w-full"
              />
            )} */}

            {/* {(formik.values.transactionMethod == "Bank"||formik.values.transactionMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={banks}
                placeholder="Choose"
                formik={formik}
                name="bankId"
                width="w-full"
              />
            )} */}
            {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
              <NuInputText
                label="IFSE"
                isRequired={true}
                placeholder="IFSE"
                formik={formik}
                name="ifse"
                width="w-full"
              />
            )}

            {formik.values.transaction_method == "Bank" && (
              <NuInputText
                label="Transaction Reference"
                isRequired={true}
                placeholder="Transaction Reference"
                formik={formik}
                name="transaction_reference"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )}

{formik.values.transaction_method == "Online" && (
              <NuInputText
                label="Online Transaction Number"
                isRequired={true}
                placeholder="Online Transaction Number"
                formik={formik}
                name="online_transaction_number"
                width="w-full"
              />
            )} */}
            <div className='flex-wrap pb-4'>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement
                    handleChange={handleImageChange}
                    preview={image || ""}
                    width="w-full"
                    label="Credit_Note Images"
                    multiple={true}
                  />
                </div>
              </div>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement 
                    handleChange={handleFileChange}
                    preview={attachment || ""}
                    width="w-full"
                    label="Ref. Docs"
                    multiple={true}
                  />
                </div>
              </div>
          </div>
          </div>
          <div className='flex justify-center gap-2'>
            <SubmitButton />

            <CancelButton handleClick={()=>{navigate(-1)}}/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCreateNotes;