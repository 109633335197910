// Node
import React, { useEffect } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// Views
import PrivateRoute from "./Services/PrivateRouter";
import App from "./App";
import MainPage from "./Views/Mian/MainPage";
import Auth from "./Views/Mian/MainAuth";
import Login from "./Views/Web/Auth/login";
import MainCapital from "./Views/Web/Capital/MainCapital";
import CapitalIndex from "./Views/Web/Capital";
import CreateCapital from "./Views/Web/Capital/CreateCapital";
import EditCapital from "./Views/Web/Capital/EditCapital";
import ViewCapital from "./Views/Web/Capital/ViewCapital";
import MainAssets from "./Views/Web/Assets/MainAssets";
import AssetsIndex from "./Views/Web/Assets";
import CreateAssets from "./Views/Web/Assets/CreateAssets";
import EditAssets from "./Views/Web/Assets/EditAssets";
import ViewAssets from "./Views/Web/Assets/ViewAssets";
import BankMain from "./Views/Web/Banks/BankMain";
import MainAccounts from "./Views/Web/Banks/Accounts/MainAccounts";
import CreateAccounts from "./Views/Web/Banks/Accounts/CreateAccounts";
import AccountsIndex from "./Views/Web/Banks/Accounts";
import EditAccounts from "./Views/Web/Banks/Accounts/EditAccounts";
import ViewAccounts from "./Views/Web/Banks/Accounts/ViewAccounts";
import MainDeposit from "./Views/Web/Banks/Deposit/MainDeposit";
import DepositIndex from "./Views/Web/Banks/Deposit";
import CreateDeposit from "./Views/Web/Banks/Deposit/CreateDeposit";
import EditDeposit from "./Views/Web/Banks/Deposit/EditDeposit";
import ViewDeposit from "./Views/Web/Banks/Deposit/ViewDeposit";
import MainInteralTransfer from "./Views/Web/Banks/InteralTransfer/MainInteralTransfer";
import InteralTransferIndex from "./Views/Web/Banks/InteralTransfer";
import CreateInteralTransfer from "./Views/Web/Banks/InteralTransfer/CreateInteralTransfer";
import EditInteralTransfer from "./Views/Web/Banks/InteralTransfer/EditInteralTransfer";
import ViewInteralTransfer from "./Views/Web/Banks/InteralTransfer/ViewInteralTransfer";
import MainWithdraw from "./Views/Web/Banks/Withdraw/MainWithdraw";
import WithdrawIndex from "./Views/Web/Banks/Withdraw";
import CreateWithdraw from "./Views/Web/Banks/Withdraw/CreateWithdraw";
import EditWithdraw from "./Views/Web/Banks/Withdraw/EditWithdraw";
import ViewWithdraw from "./Views/Web/Banks/Withdraw/ViewWithdraw";
import MainRevenue from "./Views/Web/Revenue/MainRevenue";
import RevenueIndex from "./Views/Web/Revenue";
import CreateRevenue from "./Views/Web/Revenue/CreateRevenue";
import EditRevenue from "./Views/Web/Revenue/EditRevenue";
import ViewRevenue from "./Views/Web/Revenue/ViewRevenue";
import MainExpense from "./Views/Web/Expense/MainExpense";
import ExpenseIndex from "./Views/Web/Expense";
import CreateExpense from "./Views/Web/Expense/CreateExpense";
import EditExpense from "./Views/Web/Expense/EditExpense";
import ViewExpense from "./Views/Web/Expense/ViewExpense";
import MainPayroll from "./Views/Web/Payroll/MainPayroll";
import PayrollIndex from "./Views/Web/Payroll";
import CreatePayroll from "./Views/Web/Payroll/CreatePayroll";
import EditPayroll from "./Views/Web/Payroll/EditPayroll";
import ViewPayroll from "./Views/Web/Payroll/ViewPayroll";
import MainJournalEntry from "./Views/Web/JournalEntry/MainJournalEntry";
import JournalEntryIndex from "./Views/Web/JournalEntry";
import CreateJournalEntry from "./Views/Web/JournalEntry/CreateJournalEntry";
import EditJournalEntry from "./Views/Web/JournalEntry/EditJournalEntry";
import ViewJournalEntry from "./Views/Web/JournalEntry/ViewJournalEntry";
import MainLoansInstallments from "./Views/Web/LoansInstallments/MainLoansInstallments";
import LoansInstallmentsIndex from "./Views/Web/LoansInstallments";
import CreateLoansInstallments from "./Views/Web/LoansInstallments/CreateLoansInstallments";
import EditLoansInstallments from "./Views/Web/LoansInstallments/EditLoansInstallments";
import ViewLoansInstallments from "./Views/Web/LoansInstallments/ViewLoansInstallments";
import MainCash from "./Views/Web/Cash/MainCash";
import CashIndex from "./Views/Web/Cash";
import CreateCash from "./Views/Web/Cash/CreateCash";
import EditCash from "./Views/Web/Cash/EditCash";
import ViewCash from "./Views/Web/Cash/ViewCash";
import MainCreateNotes from "./Views/Web/CreditNotes/MainCreateNotes";
import CreateNotesindex from "./Views/Web/CreditNotes";
import CreateCreateNotes from "./Views/Web/CreditNotes/CreateCreateNotes";
import EditCreateNotes from "./Views/Web/CreditNotes/EditCreateNotes";
import ViewCreateNotes from "./Views/Web/CreditNotes/ViewCreateNotes";
import MainDebitNotes from "./Views/Web/DebitNotes/MainDebitNotes";
import DebitNotesIndex from "./Views/Web/DebitNotes";
import CreateDebitNotes from "./Views/Web/DebitNotes/CreateDebitNotes";
import EditDebitNotes from "./Views/Web/DebitNotes/EditDebitNotes";
import ViewDebitNotes from "./Views/Web/DebitNotes/ViewDebitNotes";
import MainDeductions from "./Views/Web/Deductions/MainDeductions";
import DeductionsIndex from "./Views/Web/Deductions";
import CreateDeductions from "./Views/Web/Deductions/CreateDeductions";
import EditDeductions from "./Views/Web/Deductions/EditDeductions";
import ViewDeductions from "./Views/Web/Deductions/ViewDeductions";
import MainPettyCash from "./Views/Web/PettyCash/MainPettyCash";
import PettyCashIndex from "./Views/Web/PettyCash";
import CreatePettyCash from "./Views/Web/PettyCash/CreatePettyCash";
import EditPettyCash from "./Views/Web/PettyCash/EditPettyCash";
import ViewPettyCash from "./Views/Web/PettyCash/ViewPettyCash";
import MainVouchers from "./Views/Web/Vouchers/MainVouchers";
import VouchersIndex from "./Views/Web/Vouchers";
import CreateVouchers from "./Views/Web/Vouchers/CreateVouchers";
import EditVouchers from "./Views/Web/Vouchers/EditVouchers";
import ViewVouchers from "./Views/Web/Vouchers/ViewVouchers";
import MainReceiptPayments from "./Views/Web/ReceiptsPayments/MainReceiptPayments";
import ReceiptPaymentsIndex from "./Views/Web/ReceiptsPayments/Index";
import CreateReceiptPayments from "./Views/Web/ReceiptsPayments/CreateReceiptPayments";
import EditReceiptPayments from "./Views/Web/ReceiptsPayments/EditReceiptPayments";
import ViewReceiptPayments from "./Views/Web/ReceiptsPayments/ViewReceiptPayments";
import MainContracts from "./Views/Web/Contacts/MainContracts";
import MainVat from "./Views/Web/Vat/MainVat";
import VatIndex from "./Views/Web/Vat/Index";
import CreateVat from "./Views/Web/Vat/CreateVat";
import EditVat from "./Views/Web/Vat/EditVat";
import ViewVat from "./Views/Web/Vat/ViewVat";
import MainAdvance from "./Views/Web/Advance/MainAdvance";
import AdvanceIndex from "./Views/Web/Advance";
import CreateAdvance from "./Views/Web/Advance/CreateAdvance";
import EditAdvance from "./Views/Web/Advance/EditAdvance";
import ViewAdvance from "./Views/Web/Advance/ViewAdvance";
import CreateReceivable from "./Views/Web/Receivable/CreateReceivable";
import EditReceivable from "./Views/Web/Receivable/EditReceivable";
import ViewReceivable from "./Views/Web/Receivable/ViewReceivable";
import ReceivableIndex from "./Views/Web/Receivable";
import MainReceivable from "./Views/Web/Receivable/MainReceivable";
import EditInvoiceOut from "./Views/Web/InvoiceOut/EditInvoiceOut";
import ViewInvoiceOut from "./Views/Web/InvoiceOut/ViewInvoiceOut";
import CreateInvoiceOut from "./Views/Web/InvoiceOut/CreateInvoiceOut";
import InvoiceOutIndex from "./Views/Web/InvoiceOut";
import MainInvoiceOut from "./Views/Web/InvoiceOut/MainInvoiceOut";
import CreateInvoiceIn from "./Views/Web/InvoiceIn/CreateInvoiceIn";
import EditInvoiceIn from "./Views/Web/InvoiceIn/EditInvoiceIn";
import ViewInvoiceIn from "./Views/Web/InvoiceIn/ViewInvoiceIn";
import InvoiceInIndex from "./Views/Web/InvoiceIn";
import MainInvoiceIn from "./Views/Web/InvoiceIn/MainInvoiceIn";
import MainPayable from "./Views/Web/Payabale/MainPayable";
import PayableIndex from "./Views/Web/Payabale";
import CreatePayable from "./Views/Web/Payabale/CreatePayable";
import EditPayable from "./Views/Web/Payabale/EditPayable";
import ViewPayable from "./Views/Web/Payabale/ViewPayable";
import EditClient from "./Views/Web/Contacts/Client/EditClient";
import ViewClient from "./Views/Web/Contacts/Client/ViewClient";
import CreateClient from "./Views/Web/Contacts/Client/CreateClient";
import ClientIndex from "./Views/Web/Contacts/Client";
import MainClient from "./Views/Web/Contacts/Client/MainClient";
import MainStaff from "./Views/Web/Contacts/Staff/MainStaff";
import StaffIndex from "./Views/Web/Contacts/Staff";
import CreateStaff from "./Views/Web/Contacts/Staff/CreateStaff";
import EditStaff from "./Views/Web/Contacts/Staff/EditStaff";
import ViewStaff from "./Views/Web/Contacts/Staff/ViewStaff";
import MainSubContractor from "./Views/Web/Contacts/SubContractor.jsx/MainSubContractor";
import SubContractorIndex from "./Views/Web/Contacts/SubContractor.jsx";
import EditSubContractor from "./Views/Web/Contacts/SubContractor.jsx/EditSubContractor.jsx";
import ViewSubContractor from "./Views/Web/Contacts/SubContractor.jsx/ViewSubContractor.jsx";
import CreateSubContractor from "./Views/Web/Contacts/SubContractor.jsx/CreateSubContractor.jsx";
import MainVendor from "./Views/Web/Contacts/Vendor/MainVendor.jsx";
import VendorIndex from "./Views/Web/Contacts/Vendor/index.jsx";
import CreateVendor from "./Views/Web/Contacts/Vendor/CreateVendor.jsx";
import EditVendor from "./Views/Web/Contacts/Vendor/EditVendor.jsx";
import ViewVendor from "./Views/Web/Contacts/Vendor/ViewVendor.jsx";
import MainProject from "./Views/Mian/MainProject.jsx";
import OfficeIndex from "./Views/Web/offices/index.jsx";
import MainSettings from "./Views/Mian/MainSettings.jsx";
import SettingsRoutes from "./Routes/Settings.routes.jsx";
import MainCheckClearence from "./Views/Web/Banks/CheckClearnce/MainCheckClearence";
import CheckClearenceIndex from "./Views/Web/Banks/CheckClearnce/Index";
import CreateCheckClearence from "./Views/Web/Banks/CheckClearnce/CreateCheckClearence";
import EditCheckClearence from "./Views/Web/Banks/CheckClearnce/EditCheckClearence";
import ViewCheckClearence from "./Views/Web/Banks/CheckClearnce/ViewCheckClearence";
import MainBankCharges from "./Views/Web/Banks/BankCharges/MainBankCharges";
import BankChargesIndex from "./Views/Web/Banks/BankCharges/BankChargesIndex";
import CreateBankCharges from "./Views/Web/Banks/BankCharges/CreateBankCharges";
import EditBankCharges from "./Views/Web/Banks/BankCharges/EditBankCharges";
import ViewBankCharges from "./Views/Web/Banks/BankCharges/ViewBankCharges";

function MainRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      window.location.replace("/main-page/dashboard");
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if (location.pathname === "/main") {
      navigate("/main/dashboard");
    }
    if(location.pathname==="/settings"){
      navigate('/settings/company-info');
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
      >
        {SettingsRoutes()}
        <Route path="main-page" element={<MainProject />}>
          <Route path="dashboard" element={<>Main Dashboard</>}></Route>
          <Route path="offices" element={<OfficeIndex />}></Route>
        </Route>
        <Route path="/main" element={<MainPage />}>
          <Route path="dashboard/:officeId" element={<>Dashboard</>}></Route>

          <Route path="capital/:officeId" element={<MainCapital />}>
            <Route index element={<CapitalIndex />} />
            <Route path="create" element={<CreateCapital />} />
            <Route path="edit/:capitalEditId" element={<EditCapital />} />
            <Route path="view/:capitalViewId" element={<ViewCapital />} />
          </Route>

          <Route path="cash/:officeId" element={<MainCash />}>
            <Route index element={<CashIndex />} />
            <Route path="create" element={<CreateCash />} />
            <Route path="edit/:cashEditId" element={<EditCash />} />
            <Route path="view/:cashViewId" element={<ViewCash />} />
          </Route>

          <Route path="credit-notes/:officeId" element={<MainCreateNotes />}>
            <Route index element={<CreateNotesindex />} />
            <Route path="create" element={<CreateCreateNotes />} />
            <Route
              path="edit/:creditNotesEditId"
              element={<EditCreateNotes />}
            />
            <Route
              path="view/:creditNotesViewId"
              element={<ViewCreateNotes />}
            />
          </Route>

          <Route path="debit-notes/:officeId" element={<MainDebitNotes />}>
            <Route index element={<DebitNotesIndex />} />
            <Route path="create" element={<CreateDebitNotes />} />
            <Route path="edit/:debitNotesEditId" element={<EditDebitNotes />} />
            <Route path="view/:debitNotesViewId" element={<ViewDebitNotes />} />
          </Route>
          <Route path="advance/:officeId" element={<MainAdvance />}>
            <Route index element={<AdvanceIndex />} />
            <Route path="create" element={<CreateAdvance />} />
            <Route path="edit/:advanceEditId" element={<EditAdvance />} />
            <Route path="view/:advanceViewId" element={<ViewAdvance />} />
          </Route>
          <Route path="deductions/:officeId" element={<MainDeductions />}>
            <Route index element={<DeductionsIndex />} />
            <Route path="create" element={<CreateDeductions />} />
            <Route path="edit/:deductionsEditId" element={<EditDeductions />} />
            <Route path="view/:deductionsViewId" element={<ViewDeductions />} />
          </Route>

          <Route path="asset/:officeId" element={<MainAssets />}>
            <Route index element={<AssetsIndex />} />
            <Route path="create" element={<CreateAssets />} />
            <Route path="edit/:assetEditId" element={<EditAssets />} />
            <Route path="view/:assetViewId" element={<ViewAssets />} />
          </Route>

          <Route path="bank/:officeId" element={<BankMain />}>
            <Route path="accounts" element={<MainAccounts />}>
              <Route index element={<AccountsIndex />} />
              <Route path="create" element={<CreateAccounts />} />
              <Route path="edit/:accountsEditId" element={<EditAccounts />} />
              <Route path="view/:accountsViewId" element={<ViewAccounts />} />
            </Route>
            <Route path="deposit" element={<MainDeposit />}>
              <Route index element={<DepositIndex />} />
              <Route path="create" element={<CreateDeposit />} />
              <Route path="edit/:depositEditId" element={<EditDeposit />} />
              <Route path="view/:depositViewId" element={<ViewDeposit />} />
            </Route>
            <Route path="interal-transfer" element={<MainInteralTransfer />}>
              <Route index element={<InteralTransferIndex />} />
              <Route path="create" element={<CreateInteralTransfer />} />
              <Route
                path="edit/:interalTransferEditId"
                element={<EditInteralTransfer />}
              />
              <Route
                path="view/:interalTransferViewId"
                element={<ViewInteralTransfer />}
              />
            </Route>
            <Route path="withdraw" element={<MainWithdraw />}>
              <Route index element={<WithdrawIndex />} />
              <Route path="create" element={<CreateWithdraw />} />
              <Route path="edit/:withdrawEditId" element={<EditWithdraw />} />
              <Route path="view/:withdrawViewId" element={<ViewWithdraw />} />
            </Route>
            <Route path="check-clearnce" element={<MainCheckClearence />}>
              <Route index element={<CheckClearenceIndex />} />
              <Route path="create" element={<CreateCheckClearence />} />
              <Route path="edit/:checkClearenceEditId" element={<EditCheckClearence />} />
              <Route path="view/:checkClearenceViewId" element={<ViewCheckClearence />} />
            </Route>
            <Route path="bank-charges" element={<MainBankCharges />}>
              <Route index element={<BankChargesIndex />} />
              <Route path="create" element={<CreateBankCharges />} />
              <Route path="edit/:bankChargesEditId" element={<EditBankCharges />} />
              <Route path="view/:bankChargesViewId" element={<ViewBankCharges />} />
            </Route>
          </Route>

          <Route path="revenue/:officeId" element={<MainRevenue />}>
            <Route index element={<RevenueIndex />} />
            <Route path="create" element={<CreateRevenue />} />
            <Route path="edit/:revenueEditId" element={<EditRevenue />} />
            <Route path="view/:revenueViewId" element={<ViewRevenue />} />
          </Route>

          <Route path="expense/:officeId" element={<MainExpense />}>
            <Route index element={<ExpenseIndex />} />
            <Route path="create" element={<CreateExpense />} />
            <Route path="edit/:expenseEditId" element={<EditExpense />} />
            <Route path="view/:expenseViewId" element={<ViewExpense />} />
          </Route>
          <Route path="invoice-out/:officeId" element={<MainInvoiceOut />}>
            <Route index element={<InvoiceOutIndex />} />
            <Route path="create" element={<CreateInvoiceOut />} />
            <Route path="edit/:invoiceOutEditId" element={<EditInvoiceOut />} />
            <Route path="view/:invoiceOutViewId" element={<ViewInvoiceOut />} />
          </Route>
          <Route path="invoice-in/:officeId" element={<MainInvoiceIn />}>
            <Route index element={<InvoiceInIndex />} />
            <Route path="create" element={<CreateInvoiceIn />} />
            <Route path="edit/:invoiceInEditId" element={<EditInvoiceIn />} />
            <Route path="view/:invoiceInViewId" element={<ViewInvoiceIn />} />
          </Route>

          <Route
            path="receipts-payments/:officeId"
            element={<MainReceiptPayments />}
          >
            <Route index element={<ReceiptPaymentsIndex />} />
            <Route path="create" element={<CreateReceiptPayments />} />
            <Route
              path="edit/:receiptsPaymentsEditId"
              element={<EditReceiptPayments />}
            />
            <Route
              path="view/:receiptsPaymentsViewId"
              element={<ViewReceiptPayments />}
            />
          </Route>
          <Route path="payable/:officeId" element={<MainPayable />}>
            <Route index element={<PayableIndex />} />
            <Route path="create" element={<CreatePayable />} />
            <Route path="edit/:payableEditId" element={<EditPayable />} />
            <Route path="view/:payableViewId" element={<ViewPayable />} />
          </Route>

          <Route path="payroll/:officeId" element={<MainPayroll />}>
            <Route index element={<PayrollIndex />} />
            <Route path="create" element={<CreatePayroll />} />
            <Route path="edit/:payrollEditId" element={<EditPayroll />} />
            <Route path="view/:payrollViewId" element={<ViewPayroll />} />
          </Route>

          <Route path="petty-cash/:officeId" element={<MainPettyCash />}>
            <Route index element={<PettyCashIndex />} />
            <Route path="create" element={<CreatePettyCash />} />
            <Route path="edit/:pettyCashEditId" element={<EditPettyCash />} />
            <Route path="view/:pettyCashViewId" element={<ViewPettyCash />} />
          </Route>
          <Route path="receivable/:officeId" element={<MainReceivable />}>
            <Route index element={<ReceivableIndex />} />
            <Route path="create" element={<CreateReceivable />} />
            <Route path="edit/:receivableEditId" element={<EditReceivable />} />
            <Route path="view/:receivableViewId" element={<ViewReceivable />} />
          </Route>

          <Route path="journal-entry/:officeId" element={<MainJournalEntry />}>
            <Route index element={<JournalEntryIndex />} />
            <Route path="create" element={<CreateJournalEntry />} />
            <Route
              path="edit/:journalEntryEditId"
              element={<EditJournalEntry />}
            />
            <Route
              path="view/:journalEntryViewId"
              element={<ViewJournalEntry />}
            />
          </Route>

          {/* <Route path="contacts/:officeId" element={<MainContracts />}> */}
            <Route path="client/:officeId" element={<MainClient />}>
              <Route index element={<ClientIndex />} />
              <Route path="create" element={<CreateClient />} />
              <Route path="edit/:clientEditId" element={<EditClient />} />
              <Route path="view/:clientViewId" element={<ViewClient />} />
            </Route>
            <Route path="staff/:officeId" element={<MainStaff />}>
              <Route index element={<StaffIndex />} />
              <Route path="create" element={<CreateStaff />} />
              <Route path="edit/:staffEditId" element={<EditStaff />} />
              <Route path="view/:staffViewId" element={<ViewStaff />} />
            </Route>
            <Route path="sub-contract/:officeId" element={<MainSubContractor />}>
              <Route index element={<SubContractorIndex />} />
              <Route path="create" element={<CreateSubContractor />} />
              <Route
                path="edit/:subContactEditId"
                element={<EditSubContractor />}
              />
              <Route
                path="view/:subContactViewId"
                element={<ViewSubContractor />}
              />
            </Route>
            <Route path="vendor/:officeId" element={<MainVendor />}>
              <Route index element={<VendorIndex />} />
              <Route path="create" element={<CreateVendor />} />
              <Route path="edit/:vendorEditId" element={<EditVendor />} />
              <Route path="view/:vendorViewId" element={<ViewVendor />} />
            </Route>
          {/* </Route> */}

          <Route
            path="loans-advances/:officeId"
            element={<MainLoansInstallments />}
          >
            <Route index element={<LoansInstallmentsIndex />} />
            <Route path="create" element={<CreateLoansInstallments />} />
            <Route
              path="edit/:loansAdvancesEditId"
              element={<EditLoansInstallments />}
            />
            <Route
              path="view/:loansAdvancesViewId"
              element={<ViewLoansInstallments />}
            />
          </Route>

          <Route path="vouchers/:officeId" element={<MainVouchers />}>
            <Route index element={<VouchersIndex />} />
            <Route path="create" element={<CreateVouchers />} />
            <Route path="edit/:vouchersEditId" element={<EditVouchers />} />
            <Route path="view/:vouchersViewId" element={<ViewVouchers />} />
          </Route>

          <Route path="vat/:officeId" element={<MainVat />}>
            <Route index element={<VatIndex />} />
            <Route path="create" element={<CreateVat />} />
            <Route path="edit/:vatEditId" element={<EditVat />} />
            <Route path="view/:vatViewId" element={<ViewVat />} />
          </Route>
        </Route>
      </Route>
      <Route path="/auth" element={<Auth />}>
        <Route path="login" element={<Login />} />
        {/* <Route path="forget-password" element={<ChangePassword />} /> */}
      </Route>
    </Routes>
  );
}

export default MainRoutes;
