import React from 'react'
import Asset from './Asset';
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import TypeAsset from './TypeAsset';
import StatusAsset from './StatusAsset';


function AssetSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Asset" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Asset/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeAsset/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusAsset/>
        </div>
        </div>
    </div>
  )
}

export default AssetSettingsIndex;