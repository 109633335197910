import React, { useEffect, useState } from 'react'
import { NuDatePicker, NuInputNumber, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import NuDate from '../../../../Components/Utils/NuDate';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import useUserData from '../../../../Assets/Hooks/useUserData';
import useBankData from '../../../../Assets/Hooks/useBankData';


function EditWithdraw() {
  const {bankList} = useBankData();
  const {userList} = useUserData();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const navigate = useNavigate();
  const { withdrawEditId } = useParams();
  const [createNew, setCreateNew] = useState({
    bankId:"",
    branchId:1,
    amount:"",
    withdrawnMethod:"",
    requestedBy:"",
    approvedBy:"",
    withdrawnOn:"",
    withdrawnBy:"",
    comments:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
    //   firstName: yup.string().required("Name Required"),
    //   // dob: yup
    //   //   .string()
    //   //   .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(9, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.put(`/withdraw/${withdrawEditId}`, value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Withdraw Updated.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  async function getCapital() {
    await axiosInstance
      .get(`/withdraw/${withdrawEditId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setCreateNew(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    axiosInstance.get('/bank/',{ params })
    .then((res)=>{
        let finalArray = [];
        // console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect(()=>{
    getCapital();
    bankDropDown();    
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Withdraw</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuSelectwithSearch
              label="BankId"
              placeholder="Choose"
              // disable={true}
              formik={formik}
              name="bankId"
              width="w-full"
              options={bankList}
            />
             <NuInputNumber
              label="Amount"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="amount"
              width="w-full"
            />
            
             

          <NuSelectwithSearch
              label="Withdrawn Method"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="withdrawnMethod"
              width="w-full"
            />
             {(formik.values.withdrawnMethod == 'cheque') && (<NuInputNumber
              label="Cheque Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="chequeNumber"
              width="w-full"
            />)}

            {(formik.values.withdrawnMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  { value: "SBI", label: "SBI" },
                  { value: "Indian", label: "Indian" },
                  { value: "HDFC", label: "HDFC" },
                ]}
                placeholder="Name"
                formik={formik}
                name="bankId"
                width="w-full"
              />
              
            )}
             <NuSelectwithSearch
              label="Requested By"
              isRequired={true}
              options={userList}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full"
            />

            <NuSelectwithSearch
              label="Approved By"
              isRequired={true}
              options={userList}
              formik={formik}
              placeholder="Choose"
              name="approvedBy"
              width="w-full"
            />
             <NuDatePicker
              label="Withdrawn On"
              isRequired={true}
              formik={formik}
              placeholder="Choose"
              name="withdrawnOn"
              width="w-full"
            />
             <NuSelectwithSearch
              label="Withdrawn By"
              isRequired={true}
              options={userList}
              formik={formik}
              placeholder="Choose"
              name="withdrawnBy"
              width="w-full"
            />
          
            
{/* {formik.values.transactionMethod == "cheque" && (
              <NuInputNumber
                label="Cheque Number"
                // isRequired={true}
                placeholder="0"
                formik={formik}
                name="chequeNumber"
                width="w-full"
              />
            )} */}

            {/* {(formik.values.transactionMethod == "Bank"||formik.values.transactionMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={banks}
                placeholder="Choose"
                formik={formik}
                name="bankId"
                width="w-full"
              />
            )} */}
            {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
              <NuInputText
                label="IFSE"
                isRequired={true}
                placeholder="IFSE"
                formik={formik}
                name="ifse"
                width="w-full"
              />
            )}

            {formik.values.transaction_method == "Bank" && (
              <NuInputText
                label="Transaction Reference"
                isRequired={true}
                placeholder="Transaction Reference"
                formik={formik}
                name="transaction_reference"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )}

{formik.values.transaction_method == "Online" && (
              <NuInputText
                label="Online Transaction Number"
                isRequired={true}
                placeholder="Online Transaction Number"
                formik={formik}
                name="online_transaction_number"
                width="w-full"
              />
            )} */}

            <div className=" col-span-2">
                <NuTextArea
                label="Comments"
                // isRequired={true}
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full"
              />
              </div>
          </div>
          <div className='flex justify-center gap-2'>
            <SubmitButton/>
            <CancelButton handleClick={()=>{navigate(-1)}}/>
          </div>
        </form>
      </div>
    </div>
  );
}
export default EditWithdraw;