import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import TypeLoansInstallment from './TypeLoansInstallment';
import Loans from './Loan';
import TermsLoansInstallment from './TermsLoanInstallment';
import StatusLoansInstallment from './StatusLoansInstallment';
import LoansInstallmentsIndex from '../../LoansInstallments';
import LoansInstallment from './LoansInstallment';
import CurrencyLoanInstallment from './CurrencyLoan';


function LoansInstallmentSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Loans/Installments" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <LoansInstallment/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeLoansInstallment/>
        </div>
       
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TermsLoansInstallment/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusLoansInstallment/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <CurrencyLoanInstallment/>
        </div>
        
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Loans/>
        </div>
        
        </div>
    </div>
  )
}

export default LoansInstallmentSettingsIndex;