import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelectwithSearch, NuTextArea } from '../../../../Components/Utils/NuInput';
// import NuDate from '../../../../Components/Utils/NuDate';
import AxiosInstance from '../../../../Services/axiosCommon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Attachement from '../../../../Components/Utils/Attachement';
import { NuInputEmail } from '../../../../Components/Utils/NuInput';
import { CountryMIN } from '../../../../Assets/Others/CountryMini';

function EditClient() {
  const {clientEditId} = useParams()
  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  let location = useLocation()
  let {data} = location.state;
  console.log("data",data);
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
      branchId:1,
      companyName:"",
      website:"",
      country:"",
      state:"",
      city:"",
      pincode:"",
      mobile:"",
      email:"",
      country_code_one:"",
      country_code_two:"",
      clientName:"",
      clientPosition:"",
      clientMobile:"",
      clientEmail:"",
      vatNumber:""
  });

  function handleFileChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("file", tempArr);
    setAttachment(tempArr);
  }

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedImage(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (imagetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("image", tempArr);
    setImage(tempArr);
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      value.clientMobile = `${value.clientMobile}`
      value.mobile= `${value.mobile}`
      value.country_code_one = `${value.country_code_one}`
      value.country_code_two = `${value.country_code_two}`
      value.pincode = `${value.pincode}`
      let formData = new FormData()
      formData.append('clientData',JSON.stringify(value) )
      if (selectedImage !== null) {
        selectedImage.forEach((file) => {
          formData.append("clientImage", file);
        });
      } else {
        formData.append("clientImage", [])
      }
      AxiosInstance.put(`/clientInfo/${clientEditId}`,formData).then(res=>{
        if (res?.data?.status === true) {
          setIsLoading(false);
          resetForm();
          navigate(-1);
          api.success({
            message: `Success`,
            description: res?.data?.msg || 'clientInfo added successfully.',
            placement: 'bottomRight',
          });
        } else {
          setIsLoading(false);
          console.log(res?.data, 'dasd');
          api.error({
            message: `Failed`,
            description: res?.data?.msg || 'Creation failed.',
            placement: 'bottomRight',
          });
        }
      }).catch(err=>{
        setIsLoading(false);
        api.error({
          message: `Failed`,
          description: err?.response?.data?.msg || 'Creation failed.',
          placement: 'bottomRight',
        });
      })
      
    },
  });

  useEffect(()=>{
    if(data){
      setCreateNew({
      branchId:data?.branchId||"",
      companyName:data?.companyName||"",
      website:data?.website||"",
      country:data?.country||"",
      state:data?.state||"",
      city:data?.city||"",
      pincode:data?.pincode||"",
      mobile:data?.mobile||"",
      email:data?.email||"",
      country_code_one:data?.country_code_one||"",
      country_code_two:data?.country_code_two||"",
      clientName:data?.clientName||"",
      clientPosition:data?.clientPosition||"",
      clientMobile:data?.clientMobile||"",
      clientEmail:data?.clientEmail||"",
      vatNumber:data?.vatNumber||""
      })
    }
  },[data])


  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">ClientInfo</p> },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
      <form onSubmit={formik.handleSubmit}>
          <div className=" w-full h-full flex justify-between items-start gap-2">
            <div className=" w-[74%] h-full">
            <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
                 
                 
                 <NuInputText
                   label="Company Name"
                   // isRequired={true}
                   placeholder="Company Name"
                   formik={formik}
                   name="companyName"
                   width="w-full"
                 />
                  <NuInputText
                   label="Website"
                   // isRequired={true}
                   placeholder=" Website"
                   formik={formik}
                   name="website"
                   width="w-full"
                 />
                  <NuSelectwithSearch
                   label=" Country"
                   // isRequired={true}
                   placeholder="Country"
                   formik={formik}
                   name="country"
                   width="w-full"
                   options={CountryMIN}
                 />
                  <NuInputText
                   label=" State"
                   // isRequired={true}
                   placeholder="State"
                   formik={formik}
                   name="state"
                   width="w-full"
                 />
                  <NuInputText
                   label=" City"
                   // isRequired={true}
                   placeholder="city"
                   formik={formik}
                   name="city"
                   width="w-full"
                 />
                  <NuInputNumber
                 label="Pincode"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="pincode"
                 width="w-full"
               />
                <NuMobileNumber
                 label="Mobile"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="mobile"
                 width="w-full"
                 nunCode="country_code_one"
               />
                <NuInputEmail
                 label="Email"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="email"
                 width="w-full"
               />
                {/* <NuInputNumber
                 label="Country-Code-One"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="country_code_one"
                 width="w-full"
               /> */}
                {/* <NuInputNumber
                 label="Country-Code-One"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="country_code_two"
                 width="w-full"
               /> */}
                <NuInputText
                   label=" Client Name"
                   // isRequired={true}
                   placeholder="Client Name"
                   formik={formik}
                   name="clientName"
                   width="w-full"
                 />
                  <NuInputText
                   label=" Client Position"
                   // isRequired={true}
                   placeholder="Client Position"
                   formik={formik}
                   name="clientPosition"
                   width="w-full"
                 />


                <NuMobileNumber
                 label="Client Mobile"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="clientMobile"
                 width="w-full"
                 nunCode="country_code_two"
               />
                <NuInputEmail
                   label=" Client Email"
                   // isRequired={true}
                   placeholder="Client Email"
                   formik={formik}
                   name="clientEmail"
                   width="w-full"
                 />
                  <NuInputNumber
                 label="Vat Number"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="vatNumber"
                 width="w-full"
               />

       
             </div> 
            </div>
            <div className='flex-wrap pb-4'>
            <div className="md:flex justify-between px-3 mt-5 w-full">
              <div className="w-full flex gap-2 items-center">
                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full"
                  label="clientInfo Images"
                  multiple={true}
                />
              </div>
            </div>
            {/* <div className="md:flex justify-between px-3 mt-5 w-full">
              <div className="w-full flex gap-2 items-center">
                <Attachement
                  handleChange={handleFileChange}
                  preview={attachment || ""}
                  width="w-full"
                  label="Ref. Docs"
                  multiple={true}
                />
              </div>
            </div> */}
        </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}

export default EditClient