import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import Attachement from '../../../Components/Utils/Attachement';
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
function EditAdvance() {
  const {value:StatusAdvance} = useFetchMataData(`/Metadata/status_advance`)
  const {value:accountType} = useFetchMataData(`/Metadata/accountType_advance`)
  const {value:DescriptionAdvance} = useFetchMataData(`/Metadata/designation_advance`)
  const {value:DivisionAdvance} = useFetchMataData(`/Metadata/advance_division`)

    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const navigate = useNavigate();
    const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
    const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const { advanceEditId } = useParams();
    const [createNew, setCreateNew] = useState({
      accountType: "",
      amount: "",
      approvedBy: "",
      approvedOn: "",
      bankId: "",
      branchId: 1,
      comments: "",
      deductionFrequency: "",
      deductionStartDate: "",
      deductionType: "",
      designation: "",
      division: "",
      issuedOn: "",
      issuedTo: "",
      paymentMethod: "",
      status: ""
    });
    function handleFileChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = [];
      files.map((item) => {
        if (item.size < maxFileSize) {
          fileArr.push(item);
        } else {
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      });
      let tempArr = [];
      setSelectedFile(fileArr);
      files.map((item) => {
        if (item.size < maxFileSize) {
          let fileName = item?.name;
          let fileExtention = fileName.split(".").reverse()[0];
          if (filetype?.includes(fileExtention)) {
            tempArr.push(fileName);
          } else {
            let x = URL.createObjectURL(item);
            tempArr.push(x);
          }
        }
      });
      console.log("file", tempArr);
      setAttachment(tempArr);
    }
  
    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = [];
      files.map((item) => {
        if (item.size < maxFileSize) {
          fileArr.push(item);
        } else {
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      });
      let tempArr = [];
      setSelectedImage(fileArr);
      files.map((item) => {
        if (item.size < maxFileSize) {
          let fileName = item?.name;
          let fileExtention = fileName.split(".").reverse()[0];
          if (imagetype?.includes(fileExtention)) {
            tempArr.push(fileName);
          } else {
            let x = URL.createObjectURL(item);
            tempArr.push(x);
          }
        }
      });
      console.log("image", tempArr);
      setImage(tempArr);
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      //   firstName: yup.string().required("Name Required"),
      //   // dob: yup
      //   //   .string()
      //   //   .required("Date of Birth Required"),
      //   gender: yup.string().required("Gender is Required"),
      //   mobileNo: yup
      //     .string()
      //     .required("Mobile Number is required")
      //     .matches(
      //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //       "Enter Valid Mobile Number"
      //     )
      //     .min(9, "Invalid Mobile Number")
      //     .max(10, "Invalid Mobile Number"),
      //   email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        axiosInstance.put(`/advance/${advanceEditId}`, value)
            .then((res) => {
              if (res?.data?.status === true) {
                // send_notif();
                resetForm();
                navigate(-1);
                api.success({
                  message: 'Success',
                  description:res.data.msg || 'Advance Updated.',
                  placement: "bottomRight",
                });
              } else {
                api.error({
                  message: `Failed`,
                  description: res?.data?.msg || 'Network error',
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              api.error({
                message: `Failed`,
                description: err?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            });
      },
    });
  
    async function getCapital() {
      await axiosInstance
        .get(`/advance/${advanceEditId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setCreateNew(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const bankDropDown = () =>{
      const params = {
        page:1,
        per_page:20,
      };
      axiosInstance.get('/bank/',{ params })
      .then((res)=>{
          let finalArray = [];
          // console.log(res?.data?.data,'ddddd');
          let banks = res?.data?.data.bankDetails
          banks.forEach(element => {
              let tempData = {value:element?.bankId, label:element?.bankName};
              finalArray.push(tempData);
          });
          res?.data?.data && setBanks(finalArray);
         })
        .catch(
          (err)=>{
            console.log(err);
          }
        )
  }
    useEffect(()=>{
      getCapital();
      bankDropDown();    
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        {contextHolder}
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Advance</p> },
              { title: <p className=" text-[12px] ">Edit</p> },

            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <div className=" overflow-y-scroll h-[calc(100%-55px)]">
          <form onSubmit={formik.handleSubmit}>
          <div className='w-full'>
              <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
             
              <NuSelectwithSearch
                label="Account Type"
                isRequired={true}
                options={accountType}
                formik={formik}
                placeholder="Choose"
                name="accountType"
                width="w-full"
              />
              <NuDatePicker
                label="Issued To"
                formik={formik}
                name="issuedTo"
                width=" w-full "
                max={NuDate({
                  value: Date.now(),
                  format: "yyyy-MM-DD",
                })}
                // isRequired={true}
              />
               
                <NuSelectwithSearch
                label="Division"
                isRequired={true}
                options={DivisionAdvance}
                formik={formik}
                placeholder="Choose"
                name="division"
                width="w-full"
              />
              <NuSelectwithSearch
                label="Designation"
                isRequired={true}
                options={DescriptionAdvance}
                formik={formik}
                placeholder="Choose"
                name="designation"
                width="w-full"
              />
               <NuInputNumber
                label="Amount"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="amount"
                width="w-full"
              />
              <NuDatePicker
                label="Issued on"
                formik={formik}
                name="issuedOn"
                width=" w-full "
                max={NuDate({
                  value: Date.now(),
                  format: "yyyy-MM-DD",
                })}
                // isRequired={true}
              />
              <NuSelectwithSearch
                label="Approved by"
                isRequired={true}
                options={[
                  { value: "Land", label: "Land" },
                  { value: "Building", label: "Building" },
                  { value: "Portables", label: "Portables" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="approvedBy"
                width="w-full"
              />
              
               <NuSelectwithSearch
                label="Approved on"
                isRequired={true}
                options={[
                  { value: "Land", label: "Land" },
                  { value: "Building", label: "Building" },
                  { value: "Portables", label: "Portables" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="approvedOn"
                width="w-full"
              />
               <NuSelectwithSearch
                label="Payment Method"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  { value: "offline", label: "Cash" },
                  { value: "online", label: "Online" },
                  { value: "cheque", label: "Cheque" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="paymentMethod"
                width="w-full"
              />
               {(formik.values.paymentMethod == 'cheque') && (<NuInputNumber
              label="Cheque Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="chequeNumber"
              width="w-full"
            />)}

            {(formik.values.paymentMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={[
                  // { value: "Bank", label: "Bank" },
                  { value: "SBI", label: "SBI" },
                  { value: "Indian", label: "Indian" },
                  { value: "HDFC", label: "HDFC" },
                ]}
                placeholder="Name"
                formik={formik}
                name="bankId"
                width="w-full"
              />
              
            )}
             <NuSelectwithSearch
                label="Created By"
                isRequired={true}
                options={[
                  { value: "Land", label: "Land" },
                  { value: "Building", label: "Building" },
                  { value: "Portables", label: "Portables" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="createdBy"
                width="w-full"
              />
               <NuInputNumber
                label="Deduction Frequency"
                placeholder="0"
                // disable={true}
                formik={formik}
                name="deductionFrequency"
                width="w-full"
              />
              <NuDatePicker
                label="DeductionStart Date"
                formik={formik}
                name="deductionStartDate"
                width=" w-full "
                max={NuDate({
                  value: Date.now(),
                  format: "yyyy-MM-DD",
                })}
                
                // isRequired={true}
              />
             
               <NuSelectwithSearch
                label="Deduction Type"
                isRequired={true}
                options={[
                  { value: "Land", label: "Land" },
                  { value: "Building", label: "Building" },
                  { value: "Portables", label: "Portables" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="deductionType"
                width="w-full"
              />
             
             
             <NuInputNumber
              label="Bank Id"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="bankId"
              width="w-full"
            />
                <NuSelect
                label="Status"
                placeholder="Choose"
                options={StatusAdvance}
                formik={formik}
                name="status"
                width="w-full"
              />
              <div className=" col-span-2">
                <NuTextArea
                  label="Comments"
                  // isRequired={true}
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full"
                />
              </div>
            </div>  
            <div className='flex-wrap pb-4'>
                <div className="md:flex justify-between px-3 mt-5 w-full">
                  <div className="w-full flex gap-2 items-center">
                    <Attachement
                      handleChange={handleImageChange}
                      preview={image || ""}
                      width="w-full"
                      label="Advance Images"
                      multiple={true}
                    />
                  </div>
                </div>
                <div className="md:flex justify-between px-3 mt-5 w-full">
                  <div className="w-full flex gap-2 items-center">
                    <Attachement
                      handleChange={handleFileChange}
                      preview={attachment || ""}
                      width="w-full"
                      label="Ref. Docs"
                      multiple={true}
                    />
                  </div>
                </div>
            </div>
            </div>
            <div className='flex justify-center gap-2'>
              <SubmitButton/>
              <CancelButton handleClick={()=>{navigate(-1)}}/>
            </div>
          </form>
        </div>
      </div>
    );
  }
export default EditAdvance