import React, { useEffect } from 'react'
import { useState } from 'react';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../Components/Layouts/DataTable';
import View from '../../../Components/Icons/View';
import Edit from '../../../Components/Icons/Edit';
import Delete from '../../../Components/Icons/Delete';
import axiosInstance from '../../../Services/axiosCommon';

function LoansInstallmentsIndex() {
  const {officeId} =useParams();
  const [data, setData] = useState([]);
  async function getLoansInstallments() {
    const params = {
      page:1,
      per_page:20,
    };
    await axiosInstance
      .get(`/loan/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data);
        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Reference #</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Loan Amount</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Status</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getLoansInstallments();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
    <div className="w-full h-[46px]  flex justify-between ">
      <BreadCrumb
        items={[
          { title: <p className=" text-[12px] ">Test</p> },
          { title: <p className=" text-[12px] ">Loans & Installments</p> },
        ]}
      />
      <div className=" flex gap-2">
        <SearchBar />
        <CreateButton label="Import" />
        <Link to="/main/loans-advances/${officeId}/create">
          <CreateButton label="Loan" />
        </Link>
      </div>
    </div>

    <DataTable
      headers={headers}
      total={100}
      pageIndex={1}
      setPageIndex={() => {}}
      dataCount={20}
      setDataCount={() => {}}
    >
      {data?.loanDetails?.length > 0 ? (
        data.loanDetails.map((item, i) => {
          return (
            <tr
              className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
              key={i}
            >
              <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                {"#"+item?.branchId || "#"}
              </td>
              <td className="w-[10%] p-2 text-center bg-white">
                {item?.type || ""}
              </td>
              <td className="w-[15%] p-2 text-center bg-white">
                {item?.colThree || "-"}
              </td>
              <td className="w-[10%] p-2 text-center bg-white">
                {item?.date || ""}
              </td>
              {/* <td className="w-[15%] p-2 text-right ">test</td> */}
              <td className="w-[10%] p-2  text-right bg-white">
                {item?.loanAmount || 0}
              </td>
              <td className="w-[10%] p-2  text-center bg-white">
                {item?.status || 0}
              </td>
              <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                <div className=" w-full flex justify-center items-center gap-2">
                  <Link to={`/main/loans-advances/${officeId}/view/${item?.branchId}`}>
                    <View />
                  </Link>
                  <Link to={`/main/loans-advances/${officeId}/edit/${item?.branchId}`}>
                    <Edit />
                  </Link>
                  <Link>
                    <Delete />
                  </Link>
                </div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr className=" text-center my-2 font-semibold text-sm">
          <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
          No Data Found
          </div>
        </tr>
      )}
    </DataTable>
  </div>
);
}


export default LoansInstallmentsIndex;