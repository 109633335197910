import { notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import * as yup from "yup";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuInputfile,
  NuMobileNumber,
  NuSelectwithSearch,
  NuTextArea,
} from "../../../../Components/Utils/NuInput";
// import NuDate from "../../../Components/Utils/NuDate";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../../../Services/axiosCommon";
import Attachement from "../../../../Components/Utils/Attachement";
import { NuInputEmail } from "../../../../Components/Utils/NuInput";
import { CountryMIN } from "../../../../Assets/Others/CountryMini";
import useFetchMataData from "../../../../Assets/Hooks/useFetchMataData";

function CreateSubContractor() {
  const { value: Position } = useFetchMataData(`/Metadata/job_position`);
  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
      branchId:1,  
      companyName:"",
      website:"",
      country:"",
      state:"",
      city:"",
      pincode:"",
      mobile:"",
      email:"",
      country_code_one:"",
      country_code_two:"",
      vendorName:"",
      vendorPosition:"",
      vendorMobile:"",
      vendorEmail:"",
      vatNumber:"",
      type:2
  });

  function handleFileChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("file", tempArr);
    setAttachment(tempArr);
  }

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedImage(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (imagetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("image", tempArr);
    setImage(tempArr);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      value.vendorMobile = `${value.vendorMobile}`
      value.mobile= `${value.mobile}`
      value.country_code_one = `${value.country_code_one}`
      value.country_code_two = `${value.country_code_two}`
      value.pincode = `${value.pincode}`
      let formData = new FormData();
      formData.append('vendorData', JSON.stringify(value));
      // if (selectedFile !== null) {
      //   selectedFile.forEach((file) => {
      //     formData.append("referenceDoc", file);
      //   });
      // } else {
      //   formData.append("referenceDoc", [])
      // }
      if (selectedImage !== null) {
        selectedImage.forEach((file) => {
          formData.append("vendorImage", file);
        });
      } else {
        formData.append("vendorImage", [])
      }
      AxiosInstance
        .post('/Vendor/', formData)
        .then((res) => {
          if (res?.data?.status === true) {
            setIsLoading(false);
            resetForm();
            navigate(-1);
            api.success({
              message: `Success`,
              description: res?.data?.msg || 'Vendor added successfully.',
              placement: 'bottomRight',
            });
          } else {
            setIsLoading(false);
            console.log(res?.data, 'dasd');
            api.error({
              message: `Failed`,
              description: res?.data?.msg || 'Creation failed.',
              placement: 'bottomRight',
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Contacts</p> },
            { title: <p className=" text-[12px] ">Sub-Contract</p> },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-95px)]">
        <form onSubmit={formik.handleSubmit}>
          <div className='w-full'>
          <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
                 
                 
                 <NuInputText
                   label="Company Name"
                   // isRequired={true}
                   placeholder="Company Name"
                   formik={formik}
                   name="companyName"
                   width="w-full"
                 />
                  <NuInputText
                   label="Website"
                   // isRequired={true}
                   placeholder=" Website"
                   formik={formik}
                   name="website"
                   width="w-full"
                 />
                  <NuSelectwithSearch
                   label=" Country"
                   // isRequired={true}
                   placeholder="Country"
                   formik={formik}
                   name="country"
                   width="w-full"
                   options={CountryMIN}
                 />
                  <NuInputText
                   label=" State"
                   // isRequired={true}
                   placeholder="State"
                   formik={formik}
                   name="state"
                   width="w-full"
                 />
                  <NuInputText
                   label=" City"
                   // isRequired={true}
                   placeholder="city"
                   formik={formik}
                   name="city"
                   width="w-full"
                 />
                  <NuInputNumber
                 label="Zip / Postal Code"
                 placeholder="Zip / Postal Code"
                 // disable={true}
                 formik={formik}
                 name="pincode"
                 width="w-full"
               />
                <NuMobileNumber
                 label="Mobile"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="mobile"
                 width="w-full"
                 nunCode="country_code_one"

               />
                <NuInputEmail
                 label="Email"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="email"
                 width="w-full"
               />

                <NuInputText
                   label=" Sub-Contract  Name"
                   // isRequired={true}
                   placeholder=" Name"
                   formik={formik}
                   name="vendorName"
                   width="w-full"
                 />
                  <NuSelectwithSearch
                   label="Position"
                   // isRequired={true}
                   placeholder="Position"
                   formik={formik}
                   name="vendorPosition"
                   width="w-full"
                   options={Position}
                 />


                <NuMobileNumber
                 label="Sub-Contract Mobile"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="vendorMobile"
                 width="w-full"
                 nunCode="country_code_two"
               />
                <NuInputEmail
                   label="Sub-Contract Email"
                   // isRequired={true}
                   placeholder=" Email"
                   formik={formik}
                   name="vendorEmail"
                   width="w-full"
                 />
                  <NuInputNumber
                 label="Vat Number"
                 placeholder="0"
                 // disable={true}
                 formik={formik}
                 name="vatNumber"
                 width="w-full"
               />

       
             </div> 
          <div className='flex-wrap pb-4'>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement
                    handleChange={handleImageChange}
                    preview={image || ""}
                    width="w-full"
                    label="Vendor Images"
                    multiple={true}
                  />
                </div>
              </div>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement
                    handleChange={handleFileChange}
                    preview={attachment || ""}
                    width="w-full"
                    label="Ref. Docs"
                    multiple={true}
                  />
                </div>
              </div>
          </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton isLoading={isLoading}/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  )
}

export default CreateSubContractor