import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText'
import JobRole from './JobRole'

function JobRoleSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Job Position" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
         <div className=' w-full md:w-1/2 lg:w-1/3'>
            <JobRole/>
         </div>
        </div>
    </div>
  )
}

export default JobRoleSettingsIndex;