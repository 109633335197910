import React, { useEffect, useState } from 'react'
import Delete from '../../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../../Components/Icons/Edit';
import View from '../../../../Components/Icons/View';
import DataTable from '../../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../../Services/axiosCommon';
import NuDate from '../../../../Components/Utils/NuDate';
import NuAmount from '../../../../Components/Utils/NuAmount';

function DepositIndex() {
  const [data, setData] = useState([]);
  const [pageIndex,setPageIndex] = useState(1);
  const [total,setTotal] = useState(0)
  const [dataCount,setDataCount] = useState(20)
  
  
  const {officeId} =useParams();
  
  
  async function getDeposit() {
    const params = {
      page:pageIndex,
      per_page:dataCount,
    };
    await axiosInstance
      .get(`/deposit/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data.depositDetails);
        res?.data?.data && setTotal(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Bank</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Category</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Amount</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Deposit Method</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Deposited On</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Deposited by</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Comments</span>,
      width: "w-[10%]",
    },
    
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  // useEffect(()=>{
  //   getDeposit();
  // },[]);


  useEffect(()=>{
    getDeposit()
  },[pageIndex,dataCount])

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Deposit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/bank/${officeId}/deposit/create`}>
            <CreateButton label="Deposit" />
          </Link>
        </div>
      </div>

      <div className=' w-full flex gap-2 '>
      <Link to={`/main/bank/${officeId}/accounts`}><CreateButton svg={false} label='Bank Accounts' color='bg-white' textColor='text-black'  /></Link>
        <CreateButton svg={false} label='Deposit' /> 
        <Link to={`/main/bank/${officeId}/withdraw`}><CreateButton svg={false} label='Withdrawals' color='bg-white' textColor='text-black' /></Link>
        <Link to={`/main/bank/${officeId}/interal-transfer`}><CreateButton svg={false} label='Internal Transaction' color='bg-white' textColor='text-black' /></Link>
        <Link to={`/main/bank/${officeId}/check-clearnce`}><CreateButton svg={false} label='Check Clearence' color='bg-white' textColor='text-black' /></Link>    
        <Link to={`/main/bank/${officeId}/bank-charges`}><CreateButton svg={false} label='Bank Charges' color='bg-white' textColor='text-black' /></Link>
      </div>

      <DataTable
        headers={headers}
        total={total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
      >
        {data?.length > 0 ? (
          data?.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.depositId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.bankId || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.category|| "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.amount && <NuAmount value={item?.amount} /> || 0}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.depositMethod || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.depositedOn !=="None" && item?.depositedOn !==" " && <NuDate value={item?.depositedOn} format="DD-MMM-YYYY" /> || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.depositedBy || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.comments || ""}
                </td>
                
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/bank/${officeId}/deposit/view/${item?.depositId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/bank/${officeId}/deposit/edit/${item?.depositId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default DepositIndex;