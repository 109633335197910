import React,{ useState } from 'react';
import { useFormik } from "formik";
import * as yup from "yup";
import { notification } from "antd";

// src
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import { BackButton, CancelButton, SubmitButton } from "../../../Components/Utils/Buttons";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuSelectwithSearch,
  NuSelect,
  NuTextArea,
} from "../../../Components/Utils/NuInput";
import NuDate from "../../../Components/Utils/NuDate";
import { useNavigate } from 'react-router-dom';
import Attachement from '../../../Components/Utils/Attachement';
import axiosInstance from '../../../Services/axiosCommon';
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';

function CreateAssets() {
  const {value:StatusAsset} = useFetchMataData(`/Metadata/status_asset`)
  const {value:RefurbishmentAsset} = useFetchMataData(`/Metadata/refurbishment_asset`)
  const {value:AssetType} = useFetchMataData(`/Metadata/asset_type`)



  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    branchId: 1,
    serialNumber: "",
    assetType:"",
    assetName:"",
    unit:"",
    quantity:"",
    purchaseAmount:"",
    purchaseDate:"",
    paymentType:"",
    vendor:"",
    rateOfDeprecation:"",
    valueOfAsset:"",
    revaluation:"",
    revaluationDate:"",
    revaluationAmount:"",
    maintenanceExpense:"",
    refurbishment:"",
    status:"",
    comments:"",
  });
 
  function handleFileChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("file", tempArr);
    setAttachment(tempArr);
  }

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedImage(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (imagetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("image", tempArr);
    setImage(tempArr);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      let formData = new FormData();
      formData.append('assetData', JSON.stringify(value));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("referenceDoc", file);
        });
      } else {
        formData.append("referenceDoc", [])
      }
      if (selectedImage !== null) {
        selectedImage.forEach((file) => {
          formData.append("assetImage", file);
        });
      } else {
        formData.append("assetImage", [])
      }
      axiosInstance
        .post('/asset/', formData)
        .then((res) => {
          if (res?.data?.status === true) {
            setIsLoading(false);
            resetForm();
            navigate(-1);
            api.success({
              message: `Success`,
              description: res?.data?.msg || 'Asset added successfully.',
              placement: 'bottomRight',
            });
          } else {
            setIsLoading(false);
            console.log(res?.data, 'dasd');
            api.error({
              message: `Failed`,
              description: res?.data?.msg || 'Creation failed.',
              placement: 'bottomRight',
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Asset</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-95px)]">
        <form onSubmit={formik.handleSubmit}>
          <div className='w-full'>
            <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
            <NuInputNumber
              label="Serial Number"
              placeholder="Serial Number"
              // disable={true}
              formik={formik}
              name="serialNumber"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Type"
              isRequired={true}
              options={AssetType}
              formik={formik}
              placeholder="Choose"
              name="assetType"
              width="w-full"
            />
            <div className='col-span-2'>
              <NuInputText
                label="Asset Name"
                isRequired={true}
                placeholder="Name"
                formik={formik}
                name="assetName"
                width="w-full"
              />
            </div>
            <NuInputNumber
              label="Unit"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="unit"
              width="w-full"
            />
            <NuInputNumber
              label="Quantity"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="quantity"
              width="w-full"
            />
            <NuInputNumber
              label="Purchase Amount"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="purchaseAmount"
              width="w-full"
            />
            <NuDatePicker
              label="Purchase Date"
              formik={formik}
              name="purchaseDate"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            {/* <NuInputText
                label="Ref. Document"
                isRequired={true}
                placeholder="Ref. Doc"
                formik={formik}
                name="refDocument"
                width="w-full"
              /> */}
            <NuSelectwithSearch
              label="Payment Type"
              isRequired={true}
              options={[
                { value: "Bank", label: "Bank" },
                { value: "Cash", label: "Cash" },
                { value: "Online", label: "Online" },
                { value: "Check", label: "Check" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="paymentType"
              width="w-full"
            />
            <NuInputNumber
              label="Vendor"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="vendor"
              width="w-full"
            />
            <NuInputNumber
              label="Rate of Depreciation"
              placeholder="0"
              formik={formik}
              name="rateOfDeprecation"
              width="w-full"
            />
            <NuInputNumber
              label="Value of Asset"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="valueOfAsset"
              width="w-full"
            />
            <NuInputText
                label="Revaluation"
                isRequired={true}
                placeholder="Revaluation"
                formik={formik}
                name="revaluation"
                width="w-full"
            />
            <NuDatePicker
              label="Revaluation Date"
              formik={formik}
              name="revaluationDate"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuInputNumber
              label="Revaluation Amount"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="revaluationAmount"
              width="w-full"
            />
            <NuInputNumber
              label="Maintenance Expense"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="maintenanceExpense"
              width="w-full"
            />
             <NuSelect
              label="Refurbishment"
              placeholder="Choose"
              options={RefurbishmentAsset}
              formik={formik}
              name="refurbishment"
              width="w-full"
            />
            <NuSelect
              label="Status"
              placeholder="Choose"
              options={StatusAsset}
              formik={formik}
              name="status"
              width="w-full"
            />
            <div className=" col-span-2">
              <NuTextArea
                label="Comments"
                // isRequired={true}
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full"
              />
            </div>
          </div>  
          <div className='flex-wrap pb-4'>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement
                    handleChange={handleImageChange}
                    preview={image || ""}
                    width="w-full"
                    label="Asset Images"
                    multiple={true}
                  />
                </div>
              </div>
              <div className="md:flex justify-between px-3 mt-5 w-full">
                <div className="w-full flex gap-2 items-center">
                  <Attachement
                    handleChange={handleFileChange}
                    preview={attachment || ""}
                    width="w-full"
                    label="Ref. Docs"
                    multiple={true}
                  />
                </div>
              </div>
          </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton isLoading={isLoading}/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  )
}

export default CreateAssets