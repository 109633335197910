import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';

function CreateLoansInstallments() {
  const {value:agreementType} = useFetchMataData(`/Metadata/agreement_type`)
  const {value:receivedOrIssued} = useFetchMataData(`/Metadata/receivedOrIssued`)
  const {value:loanStatus} = useFetchMataData(`/Metadata/loan_status`)
  const {value:loanTerm} = useFetchMataData(`/Metadata/loan_term`)
  const {value:loanType} = useFetchMataData(`/Metadata/loan_type`)
  const {value:Currency} = useFetchMataData(`/Metadata/currency`)


  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  let navigate = useNavigate();
  const [createNew, setCreateNew] = useState({
    branchId:"1",
    agreementType:"",
    receivedOrIssued:"",
    type:"",
    date:"",
    provider:"",
    loanAmount:"",
    interestRate:"",
    loanPurpose:"",
    loanTerm:"",
    loanTenure:"",
    collateralDetails:"",
    loanDetails:"",
    bankDetails:"",
    prePaymentAmount:"",
    emiStartDate:"",
    emiEndDate:"",
    closingFees:"",
    currency:"",
    comments:"",
    status:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // dob: yup
      //   .string()
      //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post("/loan/", value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Loan Created.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

//   const bankDropDown = () =>{
//     const params = {
//       page:1,
//       per_page:20,
//     };
//     axiosInstance.get('/bank/',{ params })
//     .then((res)=>{
//         let finalArray = [];
//         console.log(res?.data?.data,'ddddd');
//         let banks = res?.data?.data.bankDetails
//         banks.forEach(element => {
//             let tempData = {value:element?.bankId, label:element?.bankName};
//             finalArray.push(tempData);
//         });
//         res?.data?.data && setBanks(finalArray);
//        })
//       .catch(
//         (err)=>{
//           console.log(err);
//         }
//       )
// }
//   useEffect(()=>{
//     bankDropDown();    
//   },[]);
  return (
    <div className="w-full h-full px-4 py-2">
    {contextHolder}
    <div className="w-full h-[46px]  flex justify-between ">
      <BreadCrumb
        items={[
          { title: <p className=" text-[12px] ">Test</p> },
          { title: <p className=" text-[12px] ">Loans & Installments</p> },
          { title: <p className=" text-[12px] ">Create</p> },
        ]}
      />
      <div className=" flex gap-2">
        <BackButton />
      </div>
    </div>
    <div className=" overflow-y-scroll h-[calc(100%-55px)]">
      <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
        <NuSelectwithSearch
            label="Agreement Type"
            // isRequired={true}
            options={agreementType}
            formik={formik}
            placeholder="Choose"
            name="agreementType"
            width="w-full"
          />
           <NuSelectwithSearch
            label="Received / Issued"
            // isRequired={true}
            options={receivedOrIssued}
            formik={formik}
            placeholder="Choose"
            name="receivedOrIssued"
            width="w-full"
          />
           

          <NuSelectwithSearch
            label="Type"
            // isRequired={true}
            options={loanType}
            formik={formik}
            placeholder="Choose"
            name="type"
            width="w-full"
          />
          <NuDatePicker
            label="Date"
            formik={formik}
            name="date"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />

            <NuInputText
              label="Provider"
              // isRequired={true}
              placeholder="Provider"
              formik={formik}
              name="provider"
              width="w-full"
            />

            <NuInputNumber
            label="Loan Amount"
            placeholder="0"
            // disable={true}
            formik={formik}
            name="loanAmount"
            width="w-full"
            />  

            <NuInputNumber
            label="Interest Rate"
            placeholder="Interest Rate"
            // disable={true}
            formik={formik}
            name="interestRate"
            width="w-full"
            />     

            <NuInputText
              label="Loan Purpose"
              // isRequired={true}
              placeholder="Loan Purpose"
              formik={formik}
              name="loanPurpose"
              width="w-full"
            />  

            <NuSelectwithSearch
              label="Loan Term"
              isRequired={true}
              options={loanTerm}
              formik={formik}
              placeholder="Choose"
              name="loanTerm"
              width="w-full"
            />
             <NuInputText
              label="Loan Tenure"
              // isRequired={true}
              placeholder="Loan Tenure"
              formik={formik}
              name="loanTenure"
              width="w-full"
            /> 
             <NuInputText
              label="Collateral Details"
              // isRequired={true}
              placeholder="Collateral Details"
              formik={formik}
              name="collateralDetails"
              width="w-full"
            /> 

             <NuInputText
              label="Loan Detail"
              // isRequired={true}
              placeholder="Loan Detail"
              formik={formik}
              name="loanDetails"
              width="w-full"
            /> 

            <NuInputNumber
            label="Bank Details"
            placeholder="Bank Details"
            // disable={true}
            formik={formik}
            name="bankDetails"
            width="w-full"
            />

            <NuInputNumber
            label="Pre-payment Amount"
            placeholder="Pre-payment Amount"
            // disable={true}
            formik={formik}
            name="prePaymentAmount"
            width="w-full"
            />
             <NuDatePicker
            label="EMI Start Date"
            formik={formik}
            name="emiStartDate"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
            /> 

            <NuDatePicker
            label="EMI End Date"
            formik={formik}
            name="emiEndDate"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
            /> 
            <NuInputNumber
            label="Closing Fees"
            placeholder="Closing Fees"
            // disable={true}
            formik={formik}
            name="closingFees"
            width="w-full"
            />

              <NuSelect
              label="Currency"
              // isRequired={true}
              options={Currency}
              formik={formik}
              placeholder="Choose"
              name="currency"
              width="w-full"
            /> 
              <NuSelect
              label="Status"
              // isRequired={true}
              options={loanStatus}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full"
            /> 
             <NuTextArea
              label="Comments"
              // isRequired={true}
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full"
            />
          
            
        </div>
        <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>

      </form>
    </div>
  </div>
);
}

export default CreateLoansInstallments;