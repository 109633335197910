import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function ViewCash() {
  const { cashViewId ,officeId} = useParams();
  const [data, setData] = useState([]);
  async function getCash() {
    await axiosInstance
      .get(`/cash/${cashViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCash();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Cash</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/cash/${officeId}/edit/${cashViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Cash Information</h2>
          <div className='w-full py-5 flex gap-3 '>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2 '>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.cashId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.date || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.amount && "₹"+data?.amount ||'-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Paid  / Received</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paidOrReceived || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>To / From</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.toOrFrom || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Credit / Debit to</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.creditOrDebit || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Delivered by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.deliveryBy ||'-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID Number</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.idNumber || '-'}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Accepted by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.acceptedBy || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date of Acceptance</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.dateOfAcceptance || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewedBy || '-'}</p>
                </div>
                
              </div>
              <div className='w-6/12 flex-col'>
              <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedBy || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Received and Acknowledged</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.receivedAndAcknowledged || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>prepared by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.preparedBy || '-'}</p>
                </div>
              </div>
            </div>
            
            
          </div>
      </div>
    </div>
  )
}


export default ViewCash;