import React from "react";
import { Route } from "react-router-dom";
import MainSettings from "../Views/Mian/MainSettings";
import MainCompanyInfo from "../Views/Web/Settings/CompanyInfo/MainCompanyInfo";
import CompanyInfoIndex from "../Views/Web/Settings/CompanyInfo";
import CapitalSettingsIndex from "../Views/Web/Settings/Capital";
import AssetSettingsIndex from "../Views/Web/Settings/Asset";
import RevenueSettingsIndex from "../Views/Web/Settings/Revenue";
import ExpenseSettingsIndex from "../Views/Web/Settings/Expense";
import PayableSettingsIndex from "../Views/Web/Settings/Payable";
import ReceivableSettingsIndex from "../Views/Web/Settings/Receivable";
import JournalEntrySettingsIndex from "../Views/Web/Settings/JournalEntry/Index";
import LoansInstallmentSettingsIndex from "../Views/Web/Settings/LoansInstallments";
import VoucherSettingsIndex from "../Views/Web/Settings/Vouchers";
import DeductionSettingsIndex from "../Views/Web/Settings/Deductions";
import AdvanceSettingsIndex from "../Views/Web/Settings/Advances";
import PayrollSettingsIndex from "../Views/Web/Settings/Payroll";
import PaymentMethodSettingsIndex from "../Views/Web/Settings/PaymentMethod";
import TempSettingsIndex from "../Views/Web/Settings/Temp";
import BankSettingsIndex from "../Views/Web/Settings/Bank";
import EditCompany from "../Views/Web/Settings/CompanyInfo/Update";
import CashSettingsIndex from "../Views/Web/Settings/Cash";
import PettyCashSettingsIndex from "../Views/Web/Settings/PettyCash";
import MainBranch from "../Views/Web/Settings/Branch/MainBranch";
import BranchSettingIndex from "../Views/Web/Settings/Branch";
import CreateBranch from "../Views/Web/Settings/Branch/createBranch";
import EditBranch from "../Views/Web/Settings/Branch/editBranch";
import CreditNoteSettingsIndex from "../Views/Web/Settings/CreditNotes";
import DebitNoteSettingsIndex from "../Views/Web/Settings/DebitNotes";
import InvoiceoutSettingsIndex from "../Views/Web/Settings/InvoiceOut";
import InvoiceInSettingsIndex from "../Views/Web/Settings/InvoiceIn";
import JobRoleSettingsIndex from "../Views/Web/Settings/JobRole/index";
import AccountTypeIndex from "../Views/Web/Settings/AccountType";


function SettingsRoutes() {
  return (
    <Route path="settings" element={<MainSettings />}>
      <Route path="company-info" element={<MainCompanyInfo/>} >
        <Route index element={<CompanyInfoIndex/>} />
        <Route path="edit" element={<EditCompany/>} />
      </Route>
      <Route path="branch-other-business" element={<MainBranch/>} >
        <Route index element={<BranchSettingIndex/>} />
        <Route path="create" element={<CreateBranch/>} />
        <Route path="edit/:branchId" element={<EditBranch/>} />
      </Route>
      <Route path="capital" element={<CapitalSettingsIndex/>} />
      <Route path="cash" element={<CashSettingsIndex/>} />
      <Route path="credit-notes" element={<CreditNoteSettingsIndex/>} />
      <Route path="debit-notes" element={<DebitNoteSettingsIndex/>} />
      <Route path="advance" element={<AdvanceSettingsIndex/>} />
      <Route path="deductions" element={<DeductionSettingsIndex/>} />
      <Route path="asset" element={<AssetSettingsIndex/>} />
      <Route path="bank" element={<BankSettingsIndex/>} />
      <Route path="revenue" element={<RevenueSettingsIndex/>} />
      <Route path="expense" element={<ExpenseSettingsIndex/>} />
      <Route path="invoice-out" element={<InvoiceoutSettingsIndex/>} />
      <Route path="invoice-in" element={<InvoiceInSettingsIndex/>} />
      <Route path="receipts-payments" element={<>receipts-payments</>} />
      <Route path="payroll" element={<PayrollSettingsIndex/>} />
      <Route path="payable" element={<PayableSettingsIndex/>} />
      <Route path="receivable" element={<ReceivableSettingsIndex/>} />
      <Route path="petty-cash" element={<PettyCashSettingsIndex/>} />
      <Route path="journal-entry" element={<JournalEntrySettingsIndex/>} />
      <Route path="contracts" element={<>contracts</>} />
      <Route path="loans-advances" element={<LoansInstallmentSettingsIndex/>} />
      <Route path="vat" element={<>vat</>} />
      <Route path="vouchers" element={<VoucherSettingsIndex/>} />
      <Route path="payment-method" element={<PaymentMethodSettingsIndex/>} />
      <Route path="Temp" element={<TempSettingsIndex/>} />
      <Route path="job-position" element={<JobRoleSettingsIndex/>} />
      <Route path="account-type" element={<AccountTypeIndex/>} />
    </Route>
  );
}

export default SettingsRoutes;
