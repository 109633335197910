import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'
import axiosInstance from '../../../Services/axiosCommon';

function ViewCapital() {
  const { capitalViewId,officeId } = useParams();
  const [data, setData] = useState([]);
  async function getCapital() {
    await axiosInstance
      .get(`/capital/${capitalViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCapital();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Capital</p> },
            { title: <p className=" text-[12px] ">view</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/capital/${officeId}/edit/${capitalViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Capital Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex flex-wrap gap-2'>
              <div className='w-4/12 flex-col'>
                <p className='py-3 text-sm font-medium text-[#111537]'>ID</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Date</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Description</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Amount</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Cheque Number</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Transaction Method</p>
              </div>
              <div className='w-4/12'>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.capitalId}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.date}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.description}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.amount && '₹'+data.amount}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.chequeNumber || '-'}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.transactionMethod}</p>
              </div>
            </div>
            <div className='w-1/2 rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
              <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
              <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ViewCapital