import React, { useEffect, useState } from 'react'
import Delete from '../../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../../Components/Icons/Edit';
import View from '../../../../Components/Icons/View';
import DataTable from '../../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../../Services/axiosCommon';

function AccountsIndex() {
  const [data, setData] = useState([]);
  const [pageIndex,setPageIndex] = useState(1);
  const [total,setTotal] = useState(0)
  const [dataCount,setDataCount] = useState(20)
  const {officeId} =useParams();
  async function getCapitals() {
    const params = {
      page:pageIndex,
      per_page:dataCount,
    };
    await axiosInstance
      .get(`/bank/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data.bankDetails
          );
          res?.data?.data && setTotal(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Account Type</span>,
      width: "w-[9%]",
    },
    {
      label: <span>Account Name</span>,
      width: "w-[9%]",
    },
    {
      label: <span>Account Number</span>,
      width: "w-[9%]",
    },
    {
      label: <span>Bank Name</span>,
      width: "w-[9%]",
    },
    // {
    //   label: <span>Branch</span>,
    //   width: "w-[9%]",
    // },
    {
      label: <span>IFSC Code</span>,
      width: "w-[9%]",
    },
    {
      label: <span>SWIFT Code</span>,
      width: "w-[9%]",
    },
    {
      label: <span>MIRC Code</span>,
      width: "w-[9%]",
    },
    {
      label: <span>Contact</span>,
      width: "w-[9%]",
    },
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getCapitals();
  },[pageIndex,dataCount]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Accounts</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/bank/${officeId}/accounts/create`}>
            <CreateButton label="Accounts" />
          </Link>
        </div>
      </div>
      <div className=' w-full flex gap-2 '>
        <CreateButton svg={false} label='Bank Accounts'  />
        <Link to={`/main/bank/${officeId}/deposit`}><CreateButton svg={false} label='Deposit'  color='bg-white' textColor='text-black'/></Link> 
        <Link to={`/main/bank/${officeId}/withdraw`}><CreateButton svg={false} label='Withdrawals' color='bg-white' textColor='text-black' /></Link>
        <Link to={`/main/bank/${officeId}/interal-transfer`}><CreateButton svg={false} label='Internal Transaction' color='bg-white' textColor='text-black' /></Link>
        <Link to={`/main/bank/${officeId}/check-clearnce`}><CreateButton svg={false} label='Check Clearence' color='bg-white' textColor='text-black' /></Link>    
        <Link to={`/main/bank/${officeId}/bank-charges`}><CreateButton svg={false} label='Bank Charges' color='bg-white' textColor='text-black' /></Link>    
      </div>

      <DataTable
        headers={headers}
        total={total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.bankId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.accountType || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.accountName || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.accountNumber || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.bankName || 0}
                </td>
                {/* <td className="w-[10%] p-2  text-center bg-white">
                  {item?.address || 0}
                </td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.ifscCode || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.swiftBicCode || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.micrCode || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.contactNumber || 0}
                </td>
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/bank/${officeId}/accounts/view/${item?.bankId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/bank/${officeId}/accounts/edit/${item?.bankId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}


export default AccountsIndex;