import React from "react";

function Xmark() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.11719 1.66797L9.45052 10.0013"
        stroke="#009858"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.45052 1.66797L1.11719 10.0013"
        stroke="#009858"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Xmark;
