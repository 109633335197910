import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import ViewFiles from '../../../Components/Utils/viewFiles';

function ViewAssets() {
  const { assetViewId,officeId } = useParams();
  const [data, setData] = useState([]);
  async function getCapital() {
    await axiosInstance
      .get(`/asset/${assetViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCapital();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Asset</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/asset/${officeId}/edit/${assetViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Asset Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.assetId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Type</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.assetType || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Asset Number</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.serialNumber || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Unit</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.unit || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Quantity</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.quantity || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Purchase Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.purchaseAmount && "₹"+data?.purchaseAmount ||'-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Type</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentType || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Rate of Depreciation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.rateOfDeprecation || '-'}</p>
                </div>
              </div>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Value of Asset</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.valueOfAsset && "₹"+data?.valueOfAsset || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revaluation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.revaluaation || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revaluation Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.revaluationDate || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Revaluation Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.revaluationAmount && "₹"+data.revaluationAmount || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maintenance Expense</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.maintenanceExpense && "₹"+data.maintenanceExpense || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Refurbishment</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.refurbishment || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                </div>
              </div>
            </div>
            <div className='w-1/2'>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] mb-3'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data?.comments}</p>
              </div>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] max-h-[260.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Asset Images</p>
                <div className="flex flex-wrap gap-3">
                {data?.assetImage &&
                  data?.assetImage.map((imgs, idx) => {
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                      >

                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                          {imgs && <ViewFiles data={imgs} key={idx} />}
                        </div>
                      </div>
                    );
                  })}
              </div>
              </div>
            </div>
            
          </div>
      </div>
    </div>
  )
}

export default ViewAssets;