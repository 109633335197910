import React, { useEffect, useState } from 'react';
import Delete from '../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function AdvanceIndex() {
  const {officeId} =useParams();
    const [data, setData] = useState([]);
    async function getAdvance() {
      const params = {
        page:1,
        per_page:20,
      };
      await axiosInstance
        .get(`/advance/`,{ params })
        .then(async (res) => {
          (await res?.data?.data) && setData(res?.data?.data.advanceDetails);
          // setTotalSubs(res.data?.totalRecords);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(()=>{
      getAdvance();
    },[]);
  
    let headers = [
      {
        label: <span>ID</span>,
        width: "w-[7%]",
      },
      {
        label: <span>Account Type</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Issued To</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Issued On</span>,
        width: "w-[7%]",
      },
      {
        label: <span>Division</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Designation</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Comments</span>,
        width: "w-[10%]",
      },
     
      {
        label: <span>Action</span>,
        width: "w-[10%]",
      },
    ];
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Advance</p> },
            ]}
          />
          <div className=" flex gap-2">
            <SearchBar />
            <CreateButton label="Import" />
            <Link to="/main/advance/${officeId}/create">
              <CreateButton label="Advance" />
            </Link>
          </div>
        </div>
  
        <DataTable
          headers={headers}
          total={100}
          pageIndex={1}
          setPageIndex={() => {}}
          dataCount={20}
          setDataCount={() => {}}
        >
          {data.length > 0 ? (
            data.map((item, i) => {
              return (
                <tr
                  className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                  key={i}
                >
                  <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                    {item?.advanceId || "-"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.accountType || "-"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.issuedTo || "-"}
                  </td>
                  <td className="w-[7%] p-2 text-center bg-white">
                    {item?.issuedOn || "-"}
                  </td>
                  {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.division || "0.00"}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.designation || "-"}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.comments || "-"}
                  </td>
                 
                  <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                    <div className=" w-full flex justify-center items-center gap-2">
                      <Link to={`/main/advance/${officeId}/view/${item?.advanceId}`}>
                        <View />
                      </Link>
                      <Link to={`/main/advance/${officeId}/edit/${item?.advanceId}`}>
                        <Edit />
                      </Link>
                      <Link>
                        <Delete />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className=" text-center my-2 font-semibold text-sm">
              <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
              No Data Found
              </div>
            </tr>
          )}
        </DataTable>
      </div>
    )
  }
export default AdvanceIndex