import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import JournalEntry from './JournalEntry';
import TypeJournalEntry from './TypeJournalEntry';
import LedgerJournalEntry from './LedgerJournalEntry';
import AccountJournalEntry from './AccountJournalEntry';


function JournalEntrySettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Journal Entry" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <AccountJournalEntry/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeJournalEntry/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <LedgerJournalEntry/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <JournalEntry/>
        </div>
        
        </div>
    </div>
  )
}

export default JournalEntrySettingsIndex;