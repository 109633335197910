import React, { useEffect, useState } from 'react'
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import AxiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { notification } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
function EditPayable() {
  const {value:AccountTypePayable} = useFetchMataData(`/Metadata/accountType_payable`)
  const {value:CategoryPayable} = useFetchMataData(`/Metadata/category_payable`)
  const {value:StatusPayable} = useFetchMataData(`/Metadata/status_payable`)
  const {value:DescriptionPayable} = useFetchMataData(`/Metadata/description_payable`)

    const {payableEditId} = useParams()
    let location = useLocation();
    const {data} = location.state||[]
    console.log("cc",data);
    let navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [createNew, setCreateNew] = useState({
        invoiceINId:"",
        description:"",
        category:"",
        accountType:"",
        payTo:"",
        amount:"",
        dueDate:"",
        daysElapsed:"",
        paidAmount:"",
        balanceAmount:"",
        paymentMethod:"",
        bankId:"",
        paidOn:"",
        comments:"",
        status:""
    });
  
    useEffect(()=>{
      data && setCreateNew({
        branchId: data?.branchId||"",
        date: data?.date||"",
        description: data?.description||"",
        type: data?.type||"",
        category:data?.category||"",
        division: data?.division||"",
        receivedOrPaid: data?.receivedOrPaid||"",
        invoiceId: data?.invoiceId||"",
        paymentType: data?.paymentType||"",
        receivedfromOrPaidTo:data?.receivedfromOrPaidTo||"",
        representativeId: data?.representativeId||"",
        comments: data?.comments||"",
        chequeNumber:data?.chequeNumber|| "",
      })
    },[])
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // firstName: yup.string().required("Name Required"),
        // // dob: yup
        // //   .string()
        // //   .required("Date of Birth Required"),
        // gender: yup.string().required("Gender is Required"),
        // mobileNo: yup
        //   .string()
        //   .required("Mobile Number is required")
        //   .matches(
        //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Enter Valid Mobile Number"
        //   )
        //   .min(9, "Invalid Mobile Number")
        //   .max(10, "Invalid Mobile Number"),
        // email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        AxiosInstance.post("/payable/", value)
            .then((res) => {
              if (res?.data?.status === true) {
                // send_notif();
                resetForm();
                navigate(-1);
                api.success({
                  message: 'Success',
                  description:res.data.msg || 'Payable created.',
                  placement: "bottomRight",
                });
              } else {
                api.error({
                  message: `Failed`,
                  description: res?.data?.msg || 'Network error',
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              console.log(err);
              api.error({
                message: `Failed`,
                description: err?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            });
      },
    });
    return (
      <div className="w-full h-full px-4 py-2">
        {contextHolder}
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Payable</p> },
              { title: <p className=" text-[12px] ">Edit</p> },
            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full h-full flex justify-between items-start gap-2">
              <div className=" w-[74%] h-full">
                <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
                  <NuInputNumber
                    label="Invoice #"
                    placeholder="ID"
                    // disable={true}
                    formik={formik}
                    name="invoiceINId"
                    width="w-full"
                  />
                  
                 
                  <NuSelect
              label=" Description"
              // isRequired={true}
              options={DescriptionPayable}
              formik={formik}
              placeholder="Choose"
              name="description"
              width="w-full"
            /> 
                  
                 
  
                  <NuSelectwithSearch
                    label="Category"
                    // isRequired={true}
                    options={CategoryPayable}
                    formik={formik}
                    placeholder="Choose"
                    name="category"
                    width="w-full"
                  />
  
                     <NuSelectwithSearch
                    label="Account Type"
                    // isRequired={true}
                    options={AccountTypePayable}
                    formik={formik}
                    placeholder="Choose "
                    name="accountType"
                    width="w-full"
                  />
  
                  <NuSelectwithSearch
                    label="Pay To"
                    // isRequired={true}
                    options={[
                      { value: "Cash", label: "Cash" },
                      { value: "Cheque", label: "Cheque" },
                    ]}
                    formik={formik}
                    placeholder="Choose"
                    name="payTo"
                    width="w-full"
                  />
                     <NuInputNumber
                        label="Amount"
                        placeholder="0"
                        // disable={true}
                        formik={formik}
                        name="amount"
                        width="w-full"
                        />
                    <NuDatePicker
                    label="Due Date"
                    formik={formik}
                    name="dueDate"
                    width=" w-full "
                    max={NuDate({
                        value: Date.now(),
                        format: "yyyy-MM-DD",
                    })}
                    // isRequired={true}
                    />
                    <NuDatePicker
                    label="Days Elapsed"
                    formik={formik}
                    name="daysElapsed"
                    width=" w-full "
                    max={NuDate({
                        value: Date.now(),
                        format: "yyyy-MM-DD",
                    })}
                    // isRequired={true}
                    />
                     <NuInputNumber
                    label="Paid Amount"
                    placeholder="0"
                    // disable={true}
                    formik={formik}
                    name="paidAmount"
                    width="w-full"
                    />
                     <NuInputNumber
                        label="Balance Amount"
                        placeholder="0"
                        // disable={true}
                        formik={formik}
                        name="balanceAmount"
                        width="w-full"
                        />
                         <NuSelectwithSearch
                            label="Payment Method"
                            isRequired={true}
                            options={[
                                // { value: "Bank", label: "Bank" },
                                { value: "offline", label: "Cash" },
                                { value: "online", label: "Online" },
                                { value: "cheque", label: "Cheque" },
                            ]}
                            formik={formik}
                            placeholder="Choose"
                            name="paymentMethod"
                            width="w-full"
                            />
                            {(formik.values.paymentMethod == 'cheque') && (<NuInputNumber
                            label="Cheque Number"
                            placeholder="0"
                            // disable={true}
                            formik={formik}
                            name="chequeNumber"
                            width="w-full"
                            />)}

                            {(formik.values.paymentMethod == "online") && (
                            <NuSelect
                                label="Bank Name"
                                isRequired={true}
                                options={[
                                // { value: "Bank", label: "Bank" },
                                { value: "SBI", label: "SBI" },
                                { value: "Indian", label: "Indian" },
                                { value: "HDFC", label: "HDFC" },
                                ]}
                                placeholder="Name"
                                formik={formik}
                                name="bankId"
                                width="w-full"
                            />
                            
                            )}
                            <NuInputNumber
                            label="Bank Id"
                            placeholder="0"
                            // disable={true}
                            formik={formik}
                            name="bankId"
                            width="w-full"
                            />
                         <NuDatePicker
                            label="Paid On"
                            formik={formik}
                            name="paidOn"
                            width=" w-full "
                            max={NuDate({
                                value: Date.now(),
                                format: "yyyy-MM-DD",
                            })}
                            // isRequired={true}
                            />
                             <NuSelect
                                label="Status"
                                placeholder="Choose"
                                options={StatusPayable}
                                formik={formik}
                                name="status"
                                width="w-full"
                                />
                 

                        
        
                            <NuTextArea
                                label="Comments"
                                // isRequired={true}
                                placeholder="Comments"
                                formik={formik}
                                name="comments"
                                width="w-full"
                            />
                </div>
              </div>
             
            </div>
            <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
          </form>
        </div>
      </div>
    );
  }

export default EditPayable