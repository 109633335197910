import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import ViewFiles from '../../../Components/Utils/viewFiles';

function ViewCreateNotes() {
  const { creditNotesViewId,officeId } = useParams();
  const [data, setData] = useState([]);
  async function getCreditNotes() {
    await axiosInstance
      .get(`/credit_note/${creditNotesViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCreditNotes();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Credit-Notes</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/credit-notes/${officeId}/edit/${creditNotesViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Credit_Note Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.credit_noteId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Invoice #</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.invoiceOutId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reason For CreditNote</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reasonForCreditNote || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.amount && "₹"+data?.Amount|| '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Discount Rate</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.discountRate && "%"+data?.discountRate || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Discount Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.discountAmount && "₹"+data?.discountAmount ||'-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Tax Rate</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.taxRate || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Requested By</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.requestedBy || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed By</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewedBy || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved By</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedBy || '-'}</p>
                </div>
                
              </div>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed On</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewedOn || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved On</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedOn || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Terms</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentTerms || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Issuance Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.issuanceDate || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Due Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentDueDate || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Refund ReceivedOn</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.refundReceivedOn || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Method</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentMethod || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                </div>
              </div>
            </div>
            <div className='w-1/2'>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] mb-3'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data?.comments}</p>
              </div>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] max-h-[260.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Credit Images</p>
                <div className="flex flex-wrap gap-3">
                {data?.Image &&
                  data?.credit_noteImage.map((imgs, idx) => {
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                      >

                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                          {imgs && <ViewFiles data={imgs} key={idx} />}
                        </div>
                      </div>
                    );
                  })}
              </div>
              </div>
            </div>
            
          </div>
      </div>
    </div>
  )
}

export default ViewCreateNotes
