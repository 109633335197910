import React from "react";

function Delete() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.199463" width="25" height="25" rx="7" fill="#FFE4E4" />
      <path
        d="M17.3387 11.0973L16.2635 19.6994H8.73658L7.66132 11.0973"
        stroke="#FF4141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.0484 8.94678H18.9516"
        stroke="#FF4141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76886 8.63495V6.77473C9.76886 6.48955 9.88215 6.21605 10.0838 6.0144C10.2855 5.81275 10.5589 5.69946 10.8441 5.69946H14.0699C14.3551 5.69946 14.6286 5.81275 14.8303 6.0144C15.0319 6.21605 15.1452 6.48955 15.1452 6.77473V8.92527"
        stroke="#FF4141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Delete;
