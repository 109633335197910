import React from 'react'

function NuAmount({value}) {
    if(value){
        return Number(value).toFixed(2)
    }else{
        return 0.00
    }
}

export default NuAmount;