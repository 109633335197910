import React, { useState } from "react";
import { TextBoldSM } from "../../../../Components/Text/BoldText";
import AxiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from "formik";
import * as yup from "yup";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { NuInputEmail, NuInputNumber, NuInputText, NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import { CountryMIN } from "../../../../Assets/Others/CountryMini";

function CreateBranch() {
  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  const [createNew, setCreateNew] = useState({
    companyId:1,
    type:"",
    branchName:"",
    branchEmail:"",
    branchPhone:"",
    country:"",
    state:"",
    city:"",
    pincode:"",
    website:"",
    currency:"",
    branchHead:"",
    isActive:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
    }),
    onSubmit: async (value, { resetForm }) => {
        value.branchPhone = `${value.branchPhone}`
      AxiosInstance.post("/company_branch/", value)
        .then((res) => {
          if (res?.data?.status === true) {
            // send_notif();
            resetForm();
            navigate(-1);
            api.success({
              message: "Success",
              description: res.data.msg || "Branch created.",
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          api.error({
            message: `Failed`,
            description: err?.data?.msg || "Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  return (
    <div className=" w-full h-full overflow-y-auto">
      {contextHolder}
      <TextBoldSM content={"Company Info"} />
      <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuInputText
            label="Branch / Business Name"
            isRequired={true}
            placeholder="Name"
            formik={formik}
            name="branchName"
            width="w-full"
          />
        <NuSelectwithSearch
              label="Type"
              isRequired={true}
              options={[
                { value: "Branch", label: "Branch" },
                { value: "Business", label: "Business" },
                { value: "Project", label: "Project" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full"
            />
       <NuInputEmail
            label="Email"
            placeholder="Email"
            formik={formik}
            name="branchEmail"
            width="w-full"
              />
        <NuInputNumber
            label="Contact Number"
            placeholder="Contact Number"
            formik={formik}
            name="branchPhone"
            width="w-full"
              />
                  <NuInputText
            label="Website"
            isRequired={true}
            placeholder="Name"
            formik={formik}
            name="website"
            width="w-full"
          />
          <NuSelectwithSearch
              label="Currency"
              isRequired={true}
              options={[
                { value: "INR", label: "INR" },
                { value: "SAR", label: "SAR" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="currency"
              width="w-full"
            />
                      <NuSelectwithSearch
              label="Country"
              isRequired={true}
              options={CountryMIN}
              formik={formik}
              placeholder="Choose"
              name="country"
              width="w-full"
            />
                              <NuInputText
            label="State"
            isRequired={true}
            placeholder="Name"
            formik={formik}
            name="state"
            width="w-full"
          />
                            <NuInputText
            label="City"
            isRequired={true}
            placeholder="Name"
            formik={formik}
            name="city"
            width="w-full"
          />
                  <NuInputNumber
            label="Zip / Postal Code"
            placeholder="Zip / Postal Code"
            formik={formik}
            name="pincode"
            width="w-full"
              />
                            <NuSelectwithSearch
              label="Branch Head / Manager / Lead"
              isRequired={true}
              options={[]}
              formik={formik}
              placeholder="Choose"
              name="branchHead"
              width="w-full"
            />
              {/* <NuSelectwithSearch
              label="Status"
              isRequired={true}
              options={[
                { value: 1, label: "Active" },
                { value: 0, label: "In-Active" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="isActive"
              width="w-full"
            /> */}
        </div>
        <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
      </form>
    </div>
  );
}

export default CreateBranch;
