import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import AxiosInstance from '../../../Services/axiosCommon';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';

function EditJournalEntry() {
  const {value:AccountJournalEntry} = useFetchMataData(`/Metadata/journalEntry_accountType`)
  const {value:JournalEntryDescription} = useFetchMataData(`/Metadata/journalEntry_description`)
  const {value:TypeJournalEntry} = useFetchMataData(`/Metadata/journalEntry_type`)
  const {value:JournalEntryCategory} = useFetchMataData(`/Metadata/journalEntry_category`)
  
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const navigate = useNavigate();
  const { journalEntryViewId } = useParams();
  const [createNew, setCreateNew] = useState ({
    branchId:1,
    date:"",
    description:"",
    accountType:"",
    type:"",
    category:"",
    amount:"",
    comments:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
    //   firstName: yup.string().required("Name Required"),
    //   // dob: yup
    //   //   .string()
    //   //   .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(9, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      AxiosInstance.put(`/journalEntry/${journalEntryViewId}`, value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'JournalEntry Updated.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  async function getCash() {
    await AxiosInstance
      .get(`/journalEntry/${journalEntryViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setCreateNew(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    AxiosInstance.get('/bank/',{ params })
    .then((res)=>{
        let finalArray = [];
        // console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect(()=>{
    getCash();
    bankDropDown();    
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">JournalEntry</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          
          <NuDatePicker
            label="Date"
            formik={formik}
            name="dob"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />

            <NuSelect
              label=" Account Type"
              // isRequired={true}
              options={AccountJournalEntry}
              formik={formik}
              placeholder="Choose"
              name="accountType"
              width="w-full"
            /> 

            <NuSelect
              label="Category"
              // isRequired={true}
              options={JournalEntryCategory}
              formik={formik}
              placeholder="Choose"
              name="Category"
              width="w-full"
            /> 
             <NuSelect
              label=" Type"
              // isRequired={true}
              options={TypeJournalEntry}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full"
            /> 
             <NuSelect
              label=" Description"
              // isRequired={true}
              options={JournalEntryDescription}
              formik={formik}
              placeholder="Choose"
              name="description"
              width="w-full"
            /> 
            <NuInputNumber
            label="Amount"
            placeholder="Amount"
            // disable={true}
            formik={formik}
            name="Amount"
            width="w-full"
            />

            <NuTextArea
              label="Comments"
              // isRequired={true}
              placeholder="Comments"
              formik={formik}
              name="comments"
              width="w-full"
            />
            
        </div>
          <div className='flex justify-center gap-2'>
            <SubmitButton/>
            <CancelButton handleClick={()=>{navigate(-1)}}/>
          </div>
        </form>
      </div>
    </div>
  );
}
export default EditJournalEntry;