import React, { useEffect, useState } from 'react'
import ViewFiles from '../../../Components/Utils/viewFiles';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';

function ViewVat() {
  const { accountsViewId } = useParams();
  const [data, setData] = useState([]);
  async function getAccounts() {
    await axiosInstance
      .get(`/bank/${accountsViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getAccounts();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Vat</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/bank/accounts/edit/${accountsViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Vat Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.bankId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Account Type</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.accountType || '-'}</p>
                </div>
              </div>
              <div className='w-6/12 flex-col'>
                 <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Address</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.address  || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Contact Number</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.contactNumber  || '-'}</p>
                </div>
              </div>
            </div>           
          </div>
      </div>
    </div>
  )
}

export default ViewVat