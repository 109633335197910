import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import Expense from './Expense';
import TypeExpense from './TypeExpense';
import PayeeExpense from './PayeeExpense';
import DescriptionExpense from './DescriptionExpense';


function ExpenseSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Expense" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        {/* <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Expense/>
        </div> */}
        {/* <div className=' w-full md:w-1/2 lg:w-1/3'>
            <PayeeExpense/>
        </div> */}
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DescriptionExpense/>
        </div>

        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeExpense/>
        </div>
        
        </div>
    </div>
  )
}

export default ExpenseSettingsIndex;