import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import AxiosInstance from '../../../Services/axiosCommon';

function ViewPayable() {
    const { payableViewId,officeId } = useParams();
    const [data, setData] = useState([]);
    async function getCapital() {
      await AxiosInstance
        .get(`/payable/${payableViewId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(()=>{
      getCapital();
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Payable</p> },
            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
            <Link to={`/main/payable/${officeId}/edit/${payableViewId}`} state={{data:data}}>
              <EditButton />
            </Link>
          </div>
        </div>
        <div>
            
            <div className='w-full pb-5 flex gap-3'>
              <div className='w-1/2  rounded-[20px]  bg-white py-4 px-5 flex flex-wrap gap-4'>
              {/* <h2 className='text-base font-medium text-[#111537]'>Capital Information</h2> */}
                <div className=' w-[48%] flex flex-col gap-[3px] h-full '>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>ID</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.payableId||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Ivoice #</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.invoiceINId||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Description</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.description||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Category</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.category||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Account Type</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.accountType||"-"}</span>
                  </div>
                 
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Pay To</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.payTo||""}</span>
                  </div>
                 
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Amount</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.amount||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Due Date</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.dueDate||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Days Elapsed</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.daysElapsed||"-"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Paid Amount</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.paidAmount||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Balance Amount</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.balanceAmount||"#"}</span>
                  </div>
                </div>
    
                <div className=' w-[48%] flex flex-col gap-[3px] h-full'>
                <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Payment Method</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.paymentMethod||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Bank Id</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.bankId||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Paid On</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.paidOn||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Status</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.status||"#"}</span>
                  </div>
                </div>
                
                
                {/* <div className='w-4/12 flex-col'>
                  <p className='py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Date</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Description</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Amount</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Towards</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Transaction Reference</p>
                </div>
                <div className='w-4/12'>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.capitalId}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.date}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.description}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.amount && '₹'+data.amount}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.towards || '-'}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.chequeNumber}</p>
                </div> */}
              </div>
              <div className='w-1/2 flex flex-col gap-3'>
              <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p>
              </div>
              <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'></p>
                {/* <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p> */}
              </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
export default ViewPayable