import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NuInputSubmit } from '../../../../Components/Utils/NuInput';
import { Modal, notification } from 'antd';
import AxiosInstance from '../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Xmark from '../../../../Components/Icons/Xmark';
import { TextMediumXS } from '../../../../Components/Text/MediumText';


const { confirm } = Modal;


function CategoryInvoiceIn() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [priority, setPriority] = useState([]);
  const [createNew, setCreateNew] = useState({
    itemName: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      itemName: yup.string().required('Status is Required'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let finalArray = priority;
      finalArray.push(value.itemName);
      let finalObject = {
        keyname: 'category_invoiceIn',
        value: finalArray,
      };
      AxiosInstance
        .put('/Metadata/update', finalObject)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            resetForm();
            getDatas();
            api.success({
              message: `Category  Created successfully`,
              description: response.data.msg || '',
              placement: 'bottomRight',
            });
          } else {
            api.error({
              message: `Category Created failed`,
              description: response.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          }
        })
        .catch((error) => {
          api.error({
            message: `Category Created failed`,
            description: error.data.msg || 'Sever error (or) Network error',
            placement: 'bottomRight',
          });
        });
    },
  });

  const getDatas = async () => {
    await AxiosInstance
      .get(`/Metadata/category_invoiceIn`)
      .then((res) => {
        let data = res.data?.data?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.keyValues && setData(tempData);
        res.data?.data?.keyValues && setPriority(res.data?.data?.keyValues);
      })
      .catch((err) => {
        api.error({
          message: `Category fail to Load`,
          description: err.data.msg | 'Network error',
          placement: 'bottomRight',
        });
      });
  };

  const handleDelete = async (removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Category`,
      onOk() {
        let newArray = [];

        for (let i = 0; i < priority.length; i++) {
          if (priority[i] !== removevalue) {
            newArray.push(priority[i]);
          }
        }

        let finalObject = {
          keyname: 'category_invoiceIn',
          value: newArray,
        };
        AxiosInstance
          .put('/Metadata/update', finalObject)
          .then((response) => {
            getDatas();
            api.success({
              message: `Category Deleted successfully`,
              description: response.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          })
          .catch((error) => {
            api.error({
              message: `Category  Deleted unsuccessfully`,
              description: error?.response?.data?.msg || 'Network error or Server Error',
              placement: 'bottomRight',
            });
          });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    getDatas();
  }, []);
  return (
    <div className=' w-full'>
      {contextHolder}
      <form onSubmit={formik.handleSubmit}>
        <NuInputSubmit label='Category' placeholder='Type Here..' name='itemName' width='w-full' formik={formik} />
      </form>
      <div className=' px-3'>
        <div className='w-full rounded-lg h-44 bg-[#EEF4F6] my-4 overflow-x-hidden overflow-y-auto'>
          <ul className=''>
            {data.map((List, idx) => {
              return (
                <li
                  className=' m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50'
                  key={idx}
                >
                  <TextMediumXS color={"text-[#009858]"} content={List.value || "-"} />
                  {List?.value && (
                    <span
                      onClick={() => {
                        handleDelete(List?.value);
                      }}
                      className=' text-blue-900 cursor-pointer'
                    >
                      <Xmark/>
                      {/* <FontAwesomeIcon icon={faXmark} /> */}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default CategoryInvoiceIn