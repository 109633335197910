import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../Services/axiosCommon';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';

function ViewRevenue() {
  const { revenueViewId,officeId } = useParams();
  const [data, setData] = useState([]);
  async function getRevenue() {
    await axiosInstance
      .get(`/income/${revenueViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getRevenue();
  },[]);
  
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Revenue</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/revenue/${officeId}/edit/${revenueViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Revenue Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
              <div className='w-6/12 flex-col'>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.incomeId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.date || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Account Type</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.accountType || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Client/Customer</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.unit || '-'}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Description</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.description || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.amount && "₹"+data?.amount ||'-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Invoice #</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.invoiceINPaymentId || '-'}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Method</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentmethod || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Voucher Number</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.voucherNumber || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Transaction Reference</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.transactionReference || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Receipt</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentReceipt || '-'}</p>
                </div> */}
              </div>
             
            </div>
            <div className='w-1/2 rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
              <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
              <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p>
            </div>
            
          </div>
      </div>
    </div>
  )
}

export default ViewRevenue;