import React, { useEffect, useState } from 'react'
import Delete from '../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function ReceivableIndex() {
  const {officeId} =useParams();
    const [data, setData] = useState([]);
    async function getReceivable() {
      const params = {
        page:1,
        per_page:20,
      };
      await axiosInstance
        .get(`/receivable/`,{ params })
        .then(async (res) => {
          (await res?.data?.data) && setData(res?.data?.data.receivableDetails);
          // setTotalSubs(res.data?.totalRecords);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let headers = [
      {
        label: <span>ID</span>,
        width: "w-[7%]",
      },
      {
        label: <span>Invoice #</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Description</span>,
        width: "w-[15%]",
      },
      {
        label: <span>Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Due Date</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Days Elapsed</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Received Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Balance Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Action</span>,
        width: "w-[10%]",
      },
    ];
    useEffect(()=>{
      getReceivable();
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Receivable</p> },
            ]}
          />
          <div className=" flex gap-2">
            <SearchBar />
            <CreateButton label="Import" />
            <Link to="/main/receivable/${officeId}/create">
              <CreateButton label="Receivable" />
            </Link>
          </div>
        </div>
  
        <DataTable
          headers={headers}
          total={100}
          pageIndex={1}
          setPageIndex={() => {}}
          dataCount={20}
          setDataCount={() => {}}
        >
          {data?.length > 0 ? (
            data.map((item, i) => {
              return (
                <tr
                  className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                  key={i}
                >
                  <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                    {"#"+item?.receivableId || "#"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.invoiceOUTId || ""}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.description || 0}
                  </td>
                  <td className="w-[15%] p-2 text-center bg-white">
                    {item?.amount || "-"}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.dueDate || 0}
                  </td>
                 
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.daysElapsed || ""}
                  </td>
                  {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.receivedAmount || 0}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.balanceAmount || 0}
                  </td>
                 
                  <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                    <div className=" w-full flex justify-center items-center gap-2">
                      <Link to={`/main/receivable/${officeId}/view/${item?.receivableId}`}>
                        <View />
                      </Link>
                      <Link to={`/main/receivable/${officeId}/edit/${item?.receivableId}`}>
                        <Edit 
                        />
                      </Link>
                      <Link>
                        <Delete />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className=" text-center my-2 font-semibold text-sm">
              <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
              No Data Found
              </div>
            </tr>
          )}
        </DataTable>
      </div>
    );
  }
export default ReceivableIndex