import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function ViewReceivable() {
    const { receivableViewId,officeId } = useParams();
    const [data, setData] = useState([]);
    async function getReceivable() {
      await axiosInstance
        .get(`/receivable/${receivableViewId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(()=>{
      getReceivable();
    },[]);
    
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Receivable</p> },
            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
            <Link to={`/main/receivable/${officeId}/edit/${receivableViewId}`}>
              <EditButton />
            </Link>
          </div>
        </div>
        <div>
            <h2 className='text-base font-medium text-[#111537]'>Receivable Information</h2>
            <div className='w-full py-5 flex gap-3'>
              <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
                <div className='w-6/12 flex-col'>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.receivableId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Invoice #</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.invoiceOUTId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Description</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.description || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Account Type</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.accountType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Receive from</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.receiveFrom || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Amount</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.amount && "₹"+data?.amount ||'-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Due Date</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.dueDate || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Days Elapsed</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.daysElapsed || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Received Amount</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.receivedAmount || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Method</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentMethod || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Balance Amount</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.balanceAmount || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Received On</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.receivedOn || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Bank Id</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.bankId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                </div>
               
              </div>
            
              
            </div>
            <div className='w-1/2'>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] mb-3'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data?.comments}</p>
              </div>
             
            </div>
        </div>
      </div>
    )
  }

export default ViewReceivable