import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import TypeDeduction from './TypeDeduction';
import StatusDeduction from './StatusDeducation';


function DeductionSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Deduction" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
       
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeDeduction/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusDeduction/>
        </div>
       
        
        </div>
    </div>
  )
}

export default DeductionSettingsIndex;