import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import SearchBar from "../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../Components/Utils/Buttons";
import DataTable from "../../../Components/Layouts/DataTable";
import { Link, useParams } from "react-router-dom";
import View from "../../../Components/Icons/View";
import Edit from "../../../Components/Icons/Edit";
import Delete from "../../../Components/Icons/Delete";
import axiosInstance from "../../../Services/axiosCommon";

function CapitalIndex() {
  const {officeId} = useParams();
  const [data, setData] = useState([]);
  async function getCapitals() {
    const params = {
      page:1,
      per_page:20,
    };
    await axiosInstance
      .get(`/capital/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data);
        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>Ref</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Description</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Amount</span>,
      width: "w-[10%]",
    },
    // {
    //   label: <span>Towards</span>,
    //   width: "w-[10%]",
    // },
    {
      label: <span>Transaction Method</span>,
      width: "w-[10%]",
    },
    // {
    //   label: <span>Transaction Ref</span>,
    //   width: "w-[10%]",
    // },
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getCapitals();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Capital</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/capital/${officeId}/create`}>
            <CreateButton label="Capital" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={100}
        pageIndex={1}
        setPageIndex={() => {}}
        dataCount={20}
        setDataCount={() => {}}
      >
        {data?.capitalDetails?.length > 0 ? (
          data.capitalDetails.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.capitalId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.date || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.description || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.amount || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                {/* <td className="w-[10%] p-2  text-center bg-white">
                  {item?.colFive || 0}
                </td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.transactionMethod || 0}
                </td>
                {/* <td className="w-[10%] p-2  text-center bg-white">
                  {item?.colSeven || 0}
                </td> */}
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/capital/${officeId}/view/${item?.capitalId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/capital/${officeId}/edit/${item?.capitalId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default CapitalIndex;
