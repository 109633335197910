import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';

import StatusInvoiceIn from './StatusInvoiceIn';
import CategoryInvoiceIn from './CategoryInvoiceIn';
import ProjectInvoiceIn from './ProjectInvoiceIn';
import DivisionInvoiceIn from './DivisionInvoiceIn';
import AccountTypeInvoiceIn from './AccountInvoiceIn';


function InvoiceInSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Invoice-In" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <AccountTypeInvoiceIn/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <CategoryInvoiceIn/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <StatusInvoiceIn/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <ProjectInvoiceIn/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DivisionInvoiceIn/>
        </div>
        
        </div>
    </div>
  )
}

export default InvoiceInSettingsIndex;