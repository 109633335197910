import React from "react";

function View() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.199463" width="25" height="25" rx="7" fill="#D4F3EA" />
      <path
        d="M19.222 11.9766C19.401 12.1748 19.5 12.4324 19.5 12.6995C19.5 12.9665 19.401 13.2241 19.222 13.4224C18.0891 14.6416 15.5103 17.0154 12.5 17.0154C9.48966 17.0154 6.91091 14.6416 5.77799 13.4224C5.59905 13.2241 5.5 12.9665 5.5 12.6995C5.5 12.4324 5.59905 12.1748 5.77799 11.9766C6.91091 10.7573 9.48966 8.38358 12.5 8.38358C15.5103 8.38358 18.0891 10.7573 19.222 11.9766Z"
        stroke="#01D595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 14.8574C13.6918 14.8574 14.6579 13.8913 14.6579 12.6995C14.6579 11.5077 13.6918 10.5415 12.5 10.5415C11.3082 10.5415 10.342 11.5077 10.342 12.6995C10.342 13.8913 11.3082 14.8574 12.5 14.8574Z"
        stroke="#01D595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default View;
