import React, { useEffect, useState } from 'react'
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import { Link, useParams } from 'react-router-dom';
import Delete from '../../../Components/Icons/Delete';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import NuDate from '../../../Components/Utils/NuDate';

function ExpenseIndex() {
  const [data, setData] = useState([]);
  const {officeId} =useParams();
  async function getExpense() {
    const params = {
      page:1,
      per_page:20,
    };
    await axiosInstance
      .get(`/expense/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data.expenseDetails);
        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Account Type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Payee</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Description</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Category</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Invoice #</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Payment Method</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getExpense();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Expense</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/expense/${officeId}/create`}>
            <CreateButton label="Expense" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={100}
        pageIndex={1}
        setPageIndex={() => {}}
        dataCount={20}
        setDataCount={() => {}}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.expenseId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.date !="None" && item?.date !="" && <NuDate value={item?.date} format="DD-MMM-YYYY"  /> || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.accountType || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.Payee || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.description || "-"}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.category || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.invoiceId || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.paymentmethod || ""}
                </td>
               
              
               
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/expense/${officeId}/view/${item?.expenseId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/expense/${officeId}/edit/${item?.expenseId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default ExpenseIndex;