import { Button, Input, Select, Space } from 'antd';
import { mobileCode } from '../../Assets/Others/CountryCode';


export const NuInputText = ({ label, name, formik, handleChange, width,placeholder,disable,isRequired,padding }) => {
  return (
    <div className={`${width} my-3  text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500 '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className={padding} name={name} placeholder={placeholder} value={formik.values[name] || ""}  onChange={handleChange || formik.handleChange} disabled={disable?true:false}/>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuInputTextArray = ({ label, name,value, handleChange, width,placeholder,disable,isRequired,padding }) => {
  const tempFun =(e)=>{
console.log(e.target.value);
handleChange(e.target.value)
  }
  return (
    <div className={`${width} my-3  text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500 '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className={padding} name={name} placeholder={placeholder} value={value||""}   onChange={(e)=>tempFun(e)} disabled={disable?true:false}/>
      </div>
    </div>
  );
};



export const NuInputfile = ({ label, name,  handleChange, width,placeholder,disable,isRequired,acceptType="*" }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500 '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} placeholder={placeholder} type='file'  onChange={handleChange} disabled={disable?true:false} accept={acceptType} />
        {/* {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null} */}
      </div>
    </div>
  );
};

export const NuInputPassword = ({ label, name, formik, handleChange, width,placeholder,isRequired,padding }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className={padding} name={name} type='password' placeholder={placeholder} value={formik.values[name] || ""}  onChange={handleChange || formik.handleChange} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputSubmit = ({ label, name, type='text', formik, handleChange, width,placeholder }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500'>{label}</label>
        <Space.Compact style={{ width: '100%' }}>
        <Input style={{padding:'2px 4px'}} name={name} type={type} placeholder={placeholder} value={formik.values[name] || ""}  onChange={handleChange || formik.handleChange} />
        <Button htmlType='submit' className=' bg-baseColor-secondary text-xs' type='primary'>
              Add
            </Button> 
        </Space.Compact>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputNumber = ({ label, name, formik, handleChange, width,placeholder,disable,isRequired,max,min }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} placeholder={placeholder} value={formik.values[name] || ""} type='number' onChange={handleChange || formik.handleChange} max={max} min={min} disabled={disable?true:false} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputEmail = ({ label, name, formik, handleChange, width,placeholder,isRequired }) => {
    return (
      <div className={`${width} my-3 text-xs`}>
        <div className=' px-3 text-textColor-500'>
          <label className='mb-2 font-medium'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
          <Input name={name} placeholder={placeholder} value={formik.values[name] || ""} type='email' onChange={handleChange || formik.handleChange} />
          {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
        </div>
      </div>
    );
  };

export const NuDatePicker = ({ label, name, formik, handleChange, width,isRequired,max,min }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} type='date' value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} min={min||""} max={max||""} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuTimePicker = ({ label, name, formik, handleChange, width,isRequired,disabled,max,min }) => {
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} disabled={disabled?true:false} type='time' value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} max={max} min={min} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuSelect = ({ label, name, formik, onChange, options, placeholder, width,isRequired,disabled }) => {
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };

  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          style={{ width: '100%' }}
          options={options}
          value={options.find((i) => i.value == formik.values[name]) || undefined}
          allowClear={formik.values[name]?true:false}
          // allowClear={false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
          disabled={disabled}
        />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuMobileNumber = ({ label, name,nunCode, formik, onChange, placeholder, width,isRequired,disable,min,max }) => {
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: nunCode,
      },
    });
  };

  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
       <div className=' flex items-center'>
       <Select
          style={{ width: '20%'}}
          options={mobileCode}
          value={mobileCode.find((i) => i.value == formik.values[nunCode]) || ""}
          // allowClear={formik.values[name]?true:false}
          allowClear={false}
          name={nunCode}
          placeholder={placeholder}
          onChange={onChange || handleChange}
          disabled={disable}
        />
        <Input className=' w-[80%] py-[5px]' name={name} placeholder={placeholder} value={formik.values[name] || ""} type='number' onChange={formik.handleChange||handleChange} max={max} min={min} disabled={disable?true:false} />
       </div>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null||formik.values[name]&&formik.values[nunCode] ==""?<span className='text-xs font-semibold text-red-500'>Select Country Code</span>:null}
        
      </div>
    </div>
  );
};


export const NuMultiSelect = ({ label, name, formik, onChange, options, placeholder, width,isRequired }) => {
  // const [finalValue,setFinalValue] = useState([])
  const handleChange = (e) => {
    // setFinalValue({...finalValue,finalValue:e});
    // console.log(`Filter `,finalValue)
    // console.log(e);
    // console.log(x);
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };
  // console.log('fffff',formik.values[name])
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          mode="multiple"
          showSearch

          style={{ width: '100%' }}
          options={options}
          value={formik.values[name]}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          allowClear={false}
          // allowClear={formik.values[name]?true:false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
        />
        {/* {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null} */}
      </div>
    </div>
  );
};



export const NuSelectwithSearch = ({ label, name, formik, onChange, options, placeholder, width,isRequired,disabled }) => {
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };

  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          showSearch
          style={{ width: '100%' }}
          options={options}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={options.find((i) => i.value == formik.values[name]) || undefined}
          allowClear={formik.values[name]?true:false}
          // allowClear={false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
          disabled={disabled}
        />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};





export const NuTextArea = ({ label, name, formik, handleChange, placeholder,isRequired,width="w-full" }) => {
  const { TextArea } = Input;
  return (
    <div className={`${width} my-3 text-xs`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <TextArea name={name} placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} rows={4} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuLabel = ({ label  }) => {
    return (
      <div className={`w-full my-4`}>
        <div className=' px-3'>
          <label className='  text-xs font-semibold'>{label}</label>
        </div>
      </div>
    );
  };
