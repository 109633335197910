import React, { useState } from 'react'
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import AxiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
function CreateInvoiceIn() {
  const {value:StatusInvoiceIn} = useFetchMataData(`/Metadata/status_invoiceIn`)
  const {value:ProjectInvoiceIn} = useFetchMataData(`/Metadata/project_invoiceIn`)
  const {value:DivisionInvoiceIn} = useFetchMataData(`/Metadata/division_invoiceIn`)
  const {value:CategoryInvoiceIn} = useFetchMataData(`/Metadata/category_invoiceIn`)
  const {value:AccountTypeInvoiceIn} = useFetchMataData(`/Metadata/accountType_invoiceIn`)


  let navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
branchId: 1,
date: "",
invoiceType: "",
accountType: "",
clientId: "",
vendorId: "",
name: "",
project: "",
purchaseOrder: "",
contract: "",
division: "",
category: "",
invoiceItems: [
  {
    itemName: "",
    quantity: "",
    price: "",
    discount:"",
    tax: "",
    taxAmount:"",
    amount:""
  },
  {
    itemName: "",
    quantity: "",
    price: "",
    discount:"",
    tax: "",
    taxAmount:"",
    amount:""
  }
],
amount: "",
vat: "",
totalAmount: "",
invoiceIssueDate: "",
paymentDueDate: "",
preparedBy: "",
reviewedBy: "",
reviewDate: "",
approvedBy: "",
approvedDate: "",
daysElapsed: "",
modeOfPayment: "",
bankId: "",
paidAmount: "",
balanceAmount: "",
paymentIssuedOn: "",
paymentIssuedBy: "",
status: "",
comments: ""
}
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData()
      formData.append('invoice_in',JSON.stringify(value) )
      formData.append('attachments',[])
      AxiosInstance.post(`/invoice_in/`,formData).then(res=>{
        if(res.data.status){
         navigate(-1)
        }else{
          alert("not ok")
        }
      }).catch(err=>{
        alert("Error")
      })
    },
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Invoice_In</p> },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full h-full flex justify-between items-start gap-2">
            <div className=" w-[74%] h-full">
              <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
                {/* <NuInputNumber
                  label="ID"
                  placeholder="ID"
                  // disable={true}
                  formik={formik}
                  name="id"
                  width="w-full"
                /> */}
                <NuDatePicker
                  label="Date"
                  formik={formik}
                  name="date"
                  width=" w-full "
                  max={NuDate({
                    value: Date.now(),
                    format: "yyyy-MM-DD",
                  })}
                  // isRequired={true}
                />
               
                 
                
                <NuSelectwithSearch
                  label="Invoice Type"
                  // isRequired={true}
                  options={[
                    { value: "Bank", label: "Bank" },
                    { value: "Cash", label: "Cash" },
                    { value: "Online", label: "Online" },
                    { value: "Cheque", label: "Cheque" },
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="invoiceType"
                  width="w-full"
                />


                <NuSelectwithSearch
                  label="Account Type"
                  // isRequired={true}
                  options={AccountTypeInvoiceIn}
                  formik={formik}
                  placeholder="Choose"
                  name="accountType"
                  width="w-full"
                />
                 <NuInputNumber
            label="Client Id"
            placeholder="0"
            // disable={true}
            formik={formik}
            name="clientId"
            width="w-full"
          />
           <NuInputNumber
            label="Vendor Id"
            placeholder="0"
            // disable={true}
            formik={formik}
            name="vendorId"
            width="w-full"
          />
           <NuInputText 
          label="Name"
          isRequired={true}
          placeholder="Name"
          formik={formik}
          name="name"
          width="w-full"
        />
          <NuSelectwithSearch
              label="Project"
              isRequired={true}
              options={ProjectInvoiceIn}
              formik={formik}
              placeholder="Choose"
              name="project"
              width="w-full"
            />
         <NuInputText 
          label="Purchase Order"
          isRequired={true}
          placeholder="Purchase Order"
          formik={formik}
          name="purchaseOrder"
          width="w-full"
        />
         <NuInputText 
          label="Contract"
          isRequired={true}
          placeholder="Contract"
          formik={formik}
          name="contract"
          width="w-full"
        />

                  <NuSelectwithSearch
                  label="Division"
                  // isRequired={true}
                  options={DivisionInvoiceIn}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full"
                />
                <NuSelectwithSearch
                  label="Category"
                  // isRequired={true}
                  options={CategoryInvoiceIn}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full"
                />
                 <NuInputNumber
                 label="Amount"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="amount"
                  width="w-full"
                />
                 <NuInputNumber
                 label="Vat"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="vat"
                  width="w-full"
                />
                 <NuInputNumber
                 label="Total Amount"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="totalAmount"
                  width="w-full"
                />

                <NuDatePicker
                  label="Invoice IssueDate"
                  formik={formik}
                  name="invoiceIssueDate  "
                  width=" w-full "
                  max={NuDate({
                    value: Date.now(),
                    format: "yyyy-MM-DD",
                  })}
                  // isRequired={true}
                />
                <NuDatePicker
            label="Payment Due Date"
            formik={formik}
            name="paymentDueDate"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />
           <NuSelectwithSearch
            label="Prepared By"
            isRequired={true}
            options={[
             
              { value: "Paid", label: "Paid" },
              { value: "Received", label: "Received" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="preparedBy"
            width="w-full"
          />
           <NuSelectwithSearch
            label="Reviewed By"
            isRequired={true}
            options={[
             
              { value: "Paid", label: "Paid" },
              { value: "Received", label: "Received" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="reviewedBy"
            width="w-full"
          />
          <NuDatePicker
            label="Review Date"
            formik={formik}
            name="reviewDate"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />
           <NuSelectwithSearch
            label="Approved By"
            isRequired={true}
            options={[
             
              { value: "Paid", label: "Paid" },
              { value: "Received", label: "Received" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="approvedBy"
            width="w-full"
          />
          <NuDatePicker
            label="Approved Date"
            formik={formik}
            name="approvedDate"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />
           <NuInputNumber
                 label="Days Elapsed"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="daysElapsed"
                  width="w-full"
                />
               
              
                 <NuSelectwithSearch
            label="Mode Of Payment"
            isRequired={true}
            options={[
              // { value: "Bank", label: "Bank" },
              { value: "offline", label: "Cash" },
              { value: "online", label: "Online" },
              { value: "cheque", label: "Cheque" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="modeOfPayment"
            width="w-full"
          />
          {(formik.values.modeOfPayment == 'cheque') && (<NuInputNumber
            label="Cheque Number"
            placeholder="0"
            // disable={true}
            formik={formik}
            name="chequeNumber"
            width="w-full"
          />)}

          {(formik.values.modeOfPayment == "online") && (
            <NuSelect
              label="Bank Name"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "SBI", label: "SBI" },
                { value: "Indian", label: "Indian" },
                { value: "HDFC", label: "HDFC" },
              ]}
              placeholder="Name"
              formik={formik}
              name="bankId"
              width="w-full"
            />
            
          )}
           <NuInputNumber
                 label="Bank Id"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="bankId"
                  width="w-full"
                />
                 <NuInputNumber
                 label="Paid Amount"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="paidAmount"
                  width="w-full"
                />
                 <NuInputNumber
                 label="Balance Amount"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="balanceAmount"
                  width="w-full"
                />
                <NuDatePicker
            label="Payment IssuedOn"
            formik={formik}
            name="paymentIssuedOn"
            width=" w-full "
            max={NuDate({
              value: Date.now(),
              format: "yyyy-MM-DD",
            })}
            // isRequired={true}
          />
           <NuSelectwithSearch
            label="Payment IssuedBy"
            isRequired={true}
            options={[
             
              { value: "Paid", label: "Paid" },
              { value: "Received", label: "Received" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="paymentIssuedBy"
            width="w-full"
          />
          <NuSelect
            label="Status"
            placeholder="Choose"
            options={StatusInvoiceIn}
            formik={formik}
            name="status"
            width="w-full"
          />
               
                <NuTextArea
                  label="Comments"
                  // isRequired={true}
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full"
                />
              </div>
            </div>
           
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}

export default CreateInvoiceIn