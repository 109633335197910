import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import PaymentMethod from './PaymentMethod';



function PaymentMethodSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Payment Method" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <PaymentMethod/>
        </div>
        
        </div>
    </div>
  )
}

export default PaymentMethodSettingsIndex;