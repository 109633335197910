import { useEffect, useState } from 'react'
import AxiosInstance from '../../Services/axiosCommon';


function useFetchMataData(url) {
    const [value,setValue] = useState([])
    function getData(){
        AxiosInstance.get(url).then(res=>{
            let data = res.data?.data?.keyValues;
            let tempData = [];
            data.forEach((item) => {
              let tempObject = { value: item, label: item };
              tempData.push(tempObject);
            });
            res.data?.data?.keyValues && setValue(tempData);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {value}||[]
}

export default useFetchMataData