import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import StatusPettyCash from './StatusPettyCash';
import ReviewerPettyCash from './ReviewerDesignationPettyCash';

import RequesterDesignationPettyCash from './RequesterDesignation';
import PurposePettyCash from './PurposePettyCash';
import DesignationPettyCash from './DesignationPettyCash';


function PettyCashSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Petty Cash" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
            <div className=' w-full md:w-1/2 lg:w-1/3'>
                <DesignationPettyCash/>
            </div>
            <div className=' w-full md:w-1/2 lg:w-1/3'>
                <PurposePettyCash/>
            </div>
            <div className=' w-full md:w-1/2 lg:w-1/3'>
                <RequesterDesignationPettyCash/>
            </div>
            <div className=' w-full md:w-1/2 lg:w-1/3'>
                <ReviewerPettyCash/>
            </div>
            <div className=' w-full md:w-1/2 lg:w-1/3'>
                <StatusPettyCash/>
            </div>
        </div>
    </div>
  )
}

export default PettyCashSettingsIndex;