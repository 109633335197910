import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../Components/Layouts/DataTable';
import NuDate from '../../../Components/Utils/NuDate';
import View from '../../../Components/Icons/View';
import Edit from '../../../Components/Icons/Edit';
import Delete from '../../../Components/Icons/Delete';
import AxiosInstance from '../../../Services/axiosCommon';

function PayableIndex() {
  const {officeId} =useParams();
    const [data, setData] = useState([]);
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [totalCount,setTotalCount] = useState(0)
  
  
    let headers = [
      {
        label: <span>ID</span>,
        width: "w-[7%]",
      },
      {
        label: <span>Invoice #</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Description</span>,
        width: "w-[15%]",
      },
      {
        label: <span>Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Days Elapsed</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Paid Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Balance Amount</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Action</span>,
        width: "w-[10%]",
      },
    ];
  
     function getData (pageIndex,pageCount){
       AxiosInstance.get(`/payable/?page=${pageIndex}&per_page=${pageCount}`).then(res=>{
        console.log(res.data);
        res?.data?.data?.payableDetails && setData(res?.data?.data?.payableDetails)
        setTotalCount(res?.data?.data?.totalRecords)
      }).catch(err=>{
        console.log("err",err);
      })
    }
  
  
    useEffect(()=>{
      if(page && pageCount){
        getData(page,pageCount)
      }
    
    },[page,pageCount])
  
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Payable</p> },
            ]}
          />
          <div className=" flex gap-2">
            <SearchBar />
            <CreateButton label="Import" />
            <Link to="/main/payable/${officeId}/create">
              <CreateButton label="Payable" />
            </Link>
          </div>
        </div>
  
        <DataTable
          headers={headers}
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
        >
          {data.length > 0 ? (
            data.map((item, i) => {
              return (
                <tr
                  className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                  key={i}
                >
                  <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                    {item?.payableId || "#"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    { item?.invoiceINId || ""}
                  </td>
                  <td className="w-[15%] p-2 text-center bg-white">
                    {item?.description || "-"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.amount || ""}
                  </td>
                  {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.daysElapsed || ""}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.paidAmount || ""}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.balanceAmount || ""}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                    <div className=" w-full flex justify-center items-center gap-2">
                      <Link to={`/main/payable/${officeId}/view/${item?.payableId}`}>
                        <View />
                      </Link>
                      <Link to={`/main/payable/${officeId}/edit/${item?.payableId}`} state={{data:item}}>
                        <Edit />
                      </Link>
                      <Link>
                        <Delete />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className=" text-center my-2 font-semibold text-sm">
              <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
              No Data Found
              </div>
            </tr>
          )}
        </DataTable>
      </div>
    );
  }
export default PayableIndex