import React, { useEffect, useState } from 'react'
import ViewFiles from '../../../Components/Utils/viewFiles';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function ViewPettyCash() {
  const { pettyCashViewId,officeId } = useParams();
  const [data, setData] = useState([]);
  async function getCapital() {
    await axiosInstance
      .get(`/petty_cash/${pettyCashViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCapital();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Petty-Cash</p> },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/petty-cash/${officeId}/edit/${pettyCashViewId}`}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          <h2 className='text-base font-medium text-[#111537]'>Petty-Cash Information</h2>
          <div className='w-full py-5 flex gap-3'>
            <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2 mb-5'>
              <div className='w-6/12 flex-col  '>
                <div className='w-full flex px-2 '>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.assetId || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Date</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.date || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Purpose</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.purpose || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Requested Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.requestedAmount && "₹"+data?.requestedAmount  || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Requested by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.requestedBy || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Requester Designation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.requesterDesignation  ||'-'}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewedBy || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewer Designation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewerDesignation || '-'}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reviewed on</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.reviewedOn  || '-'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved by</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedBy  || '-'}</p>
                </div>
              </div>
              <div className='w-6/12 flex-col'>
              {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approver Designation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approverDesignation  || '-'}</p>
                </div> */}
              <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved on</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedOn  || '-'}</p>
                </div>
              <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedAmount  || '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Paid To</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paidTo  || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Designation</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.designation  || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Paid On </p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paidOn  || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Bill Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.billAmount && "₹"+data.billAmount || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Balance Amount</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.balanceAmount && "₹"+data.balanceAmount  || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Balance Settled?</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.balanceSettled  || '-'}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                  <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                </div> */}
              </div>
            </div>
            <div className='w-1/2'>
              <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] mb-3'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data?.comments}</p>
              </div>
              
            </div>
            
          </div>
      </div>
    </div>
  )
}
export default ViewPettyCash;