import React from 'react'
import { SearchButton } from './Buttons'

function SearchBar({handleChange}) {
  return (
    <div className=" w-64 md:w-80 h-[34px] relative rounded-md p-1 flex justify-between bg-white items-center overflow-y-hidden" style={{border:'1px solid #EAEBED'}}>
    <input type="text" onChange={handleChange} placeholder="Search here" className=" w-[90%] text-sm px-2 outline-none border-none focus:border-none focus:outline-none" />
    <SearchButton/>
  </div>
  )
}

export default SearchBar