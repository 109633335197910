import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../../Components/Utils/NuInput';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import NuDate from '../../../../Components/Utils/NuDate';
import useBankData from '../../../../Assets/Hooks/useBankData';
import useUserData from '../../../../Assets/Hooks/useUserData';


function CreateInteralTransfer() {
  const {bankList} = useBankData();
  const {userList} = useUserData();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [allBank,setAllBank] = useState([]);
  const [banks, setBanks] = useState([]);
  let navigate = useNavigate  ();
  const [createNew, setCreateNew] = useState({
    fromBankId:"",
    toBankId:"",
    date:"",
    amount:"",
    description:"",
    requestedBy:"",
    approvedBy:"",
    comments:""
});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post("/internalTransaction/", value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'InternalTransaction created.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });



  useMemo(()=>{
    if(formik.values.toBankId && formik.values.fromBankId){
      // let finalValue = allBank.find((item)=>item?.bankId == formik.values.bankId)
      if(formik.values.toBankId === formik.values.fromBankId){
      formik.values.toBankId = ""
      setCreateNew({...createNew,toBankId:""})
      api.error({
        message: `Failed`,
        description:"Please Setect Diffrent Banks" || 'Network error',
        placement: "bottomRight",
      });

      }

    }

  },[formik.values.toBankId,formik.values.fromBankId])

  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Internal Transaction</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuSelectwithSearch
              label="From BankId"
              placeholder="Choose"
              // disable={true}
              formik={formik}
              name="fromBankId"
              width="w-full"
              options={bankList}
            />
             <NuSelectwithSearch
              label="To BankId"
              placeholder="Choose"
              // disable={true}
              formik={formik}
              name="toBankId"
              width="w-full"
              options={bankList}

            />
            <NuDatePicker
              label="Date"
              formik={formik}
              name="date"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuInputNumber
              label="Amount"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="amount"
              width="w-full"
            />
            <NuInputText
              label=" Description"
              // isRequired={true}
              // options={[
              // { value: "A", label: "A" },
              // { value: "B", label: "B" },
              // ]}
              formik={formik}
              placeholder="Choose"
              name="description"
              width="w-full"
            /> 

          <NuSelectwithSearch
              label="Requested By"
              isRequired={true}
              options={userList}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full"
            />
            

            <NuSelectwithSearch
              label="Approved By"
              isRequired={true}
              options={userList}
              formik={formik}
              placeholder="Choose"
              name="approvedBy"
              width="w-full"
            />
            
          
            
{/* {formik.values.transactionMethod == "cheque" && (
              <NuInputNumber
                label="Cheque Number"
                // isRequired={true}
                placeholder="0"
                formik={formik}
                name="chequeNumber"
                width="w-full"
              />
            )} */}

            {/* {(formik.values.transactionMethod == "Bank"||formik.values.transactionMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={banks}
                placeholder="Choose"
                formik={formik}
                name="bankId"
                width="w-full"
              />
            )} */}
            {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
              <NuInputText
                label="IFSE"
                isRequired={true}
                placeholder="IFSE"
                formik={formik}
                name="ifse"
                width="w-full"
              />
            )}

            {formik.values.transaction_method == "Bank" && (
              <NuInputText
                label="Transaction Reference"
                isRequired={true}
                placeholder="Transaction Reference"
                formik={formik}
                name="transaction_reference"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )}

{formik.values.transaction_method == "Online" && (
              <NuInputText
                label="Online Transaction Number"
                isRequired={true}
                placeholder="Online Transaction Number"
                formik={formik}
                name="online_transaction_number"
                width="w-full"
              />
            )} */}

            <div className=" col-span-2">
                <NuTextArea
                label="Comments"
                // isRequired={true}
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full"
              />
              </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}
export default CreateInteralTransfer;