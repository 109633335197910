import React from "react";

function Edit() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.199463" width="25" height="25" rx="7" fill="#DAEBFF" />
      <path
        d="M13.0351 14.8555L9.80579 15.4367L10.344 12.1644L16.512 6.01787C16.6121 5.91698 16.7312 5.8369 16.8623 5.78225C16.9935 5.7276 17.1342 5.69946 17.2763 5.69946C17.4184 5.69946 17.5591 5.7276 17.6903 5.78225C17.8214 5.8369 17.9405 5.91698 18.0406 6.01787L19.1816 7.1589C19.2825 7.25897 19.3626 7.37803 19.4172 7.5092C19.4719 7.64038 19.5 7.78107 19.5 7.92318C19.5 8.06528 19.4719 8.20598 19.4172 8.33715C19.3626 8.46833 19.2825 8.58738 19.1816 8.68745L13.0351 14.8555Z"
        stroke="#4399FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8791 15.3937V18.623C17.8791 18.9085 17.7657 19.1823 17.5638 19.3842C17.3619 19.5861 17.0881 19.6995 16.8027 19.6995H6.57644C6.29095 19.6995 6.01716 19.5861 5.81528 19.3842C5.61341 19.1823 5.5 18.9085 5.5 18.623V8.39682C5.5 8.11133 5.61341 7.83753 5.81528 7.63566C6.01716 7.43378 6.29095 7.32037 6.57644 7.32037H9.80577"
        stroke="#4399FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Edit;
