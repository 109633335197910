import React, { useEffect } from 'react'
import { useState } from 'react';
import Edit from '../../../../Components/Icons/Edit';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../../Components/Layouts/DataTable';
import View from '../../../../Components/Icons/View';
import Delete from '../../../../Components/Icons/Delete';
import AxiosInstance from '../../../../Services/axiosCommon';
// import NuDate from '../../../Components/Utils/NuDate';

function VendorIndex() {
    const {officeId} =useParams();
    const [data, setData] = useState([]);
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [totalCount,setTotalCount] = useState(0)
  
  
    let headers = [
      {
        label: <span>ID</span>,
        width: "w-[7%]",
      },
      {
        label: <span>Company Name</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Website</span>,
        width: "w-[15%]",
      },
      {
        label: <span>Country</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Mobile</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Email</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Vat Number</span>,
        width: "w-[10%]",
      },
      {
        label: <span>Action</span>,
        width: "w-[10%]",
      },
    ];
  
     function getData (pageIndex,pageCount){
       AxiosInstance.get(`/Vendor/?page=${pageIndex}&per_page=${pageCount}`).then(res=>{
        console.log(res.data);
        res?.data?.data?.clientDetails && setData(res?.data?.data?.clientDetails)
        setTotalCount(res?.data?.data?.totalRecords)
      }).catch(err=>{
        console.log("err",err);
      })
    }
  
  
    useEffect(()=>{
      if(page && pageCount){
        getData(page,pageCount)
      }
    
    },[page,pageCount])
  
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Vendor</p> },
            ]}
          />
          <div className=" flex gap-2">
            <SearchBar />
            <CreateButton label="Import" />
            <Link to={`/main/vendor/${officeId}/create`}>
              <CreateButton label="Vendor" />
            </Link>
          </div>
        </div>
  
        <DataTable
          headers={headers}
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
        >
          {data.length > 0 ? (
            data.map((item, i) => {
              return (
                <tr
                  className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                  key={i}
                >
                  <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                    {item?.vendorId || "#"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    { item?.companyName || ""}
                  </td>
                  <td className="w-[15%] p-2 text-center bg-white">
                    {item?.website || "-"}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white">
                    {item?.country || ""}
                  </td>
                  {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.mobile || ""}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.email || ""}
                  </td>
                  <td className="w-[10%] p-2  text-center bg-white">
                    {item?.vat || ""}
                  </td>
                  <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                    <div className=" w-full flex justify-center items-center gap-2">
                      <Link to={`/main/vendor/${officeId}/view/${item?.vendorId}`}>
                        <View />
                      </Link>
                      <Link to={`/main/vendor/${officeId}/edit/${item?.vendorId}`} state={{data:item}}>
                        <Edit />
                      </Link>
                      <Link>
                        <Delete />
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className=" text-center my-2 font-semibold text-sm">
              <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
              No Data Found
              </div>
            </tr>
          )}
        </DataTable>
      </div>
    );
  }
export default VendorIndex