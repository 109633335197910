import React, { useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import SearchBar from '../../../../Components/Utils/SearchBar'
import { CreateButton } from '../../../../Components/Utils/Buttons'
import { Link, useParams } from 'react-router-dom';
import View from '../../../../Components/Icons/View';
import Edit from '../../../../Components/Icons/Edit';
import Delete from '../../../../Components/Icons/Delete';
import DataTable from '../../../../Components/Layouts/DataTable';

function CheckClearenceIndex() {
    // const [data, setData] = useState([]);
    const [pageIndex,setPageIndex] = useState(1);
    const [total,setTotal] = useState(0)
    const [dataCount,setDataCount] = useState(20)    
    
    const {officeId} =useParams();

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[7%]",
        },
        {
          label: <span>Action</span>,
          width: "w-[10%]",
        },
      ];
    const data = [
        {
            "id": 1,
        }
      ]

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Check Clearence</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/bank/${officeId}/check-clearnce/create`}>
            <CreateButton label="Check Clearnce" />
          </Link>
        </div>
      </div>

      <div className=' w-full flex gap-2 '>
      <Link to={`/main/bank/${officeId}/accounts`}><CreateButton svg={false} label='Bank Accounts' color='bg-white' textColor='text-black'  /></Link>
      <Link to={`/main/bank/${officeId}/deposit`}><CreateButton svg={false} label='Deposit' color='bg-white' textColor='text-black'/></Link>
      <Link to={`/main/bank/${officeId}/withdraw`}><CreateButton svg={false} label='Withdrawals' color='bg-white' textColor='text-black' /></Link>
      <Link to={`/main/bank/${officeId}/interal-transfer`}><CreateButton svg={false} label='Internal Transaction' color='bg-white' textColor='text-black' /></Link>
      <CreateButton svg={false} label='Check Clearnce' /> 
      <Link to={`/main/bank/${officeId}/bank-charges`}><CreateButton svg={false} label='Bank Charges' color='bg-white' textColor='text-black' /></Link>  
      </div>
      <DataTable
        headers={headers}
        total={total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.id || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/bank/${officeId}/check-clearnce/view/${item?.id}`}>
                      <View />
                    </Link>
                    <Link to={`/main/bank/${officeId}/check-clearnce/edit/${item?.id}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
 </div>
  )
}

export default CheckClearenceIndex