import { useEffect, useState } from 'react'
import AxiosInstance from '../../Services/axiosCommon';


function useUserData() {
    const [userList,setUserList] = useState([])
    function getData(){
        AxiosInstance.get(`/user/drop_down`).then(res=>{
            let finalArray = [];
            let banks = res?.data?.data;
            res?.data?.data && setUserList(res?.data?.data)
            res?.data?.data && banks.forEach(element => {
                let tempData = {value:element?.userId, label:element?.name};
                finalArray.push(tempData);
            });
            res?.data?.data && setUserList(finalArray);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {userList}||[]
}

export default useUserData;