import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import DivisionVoucher from './DivisionVoucher';
import PaidVoucher from './PaidVoucher';
import TypeVoucher from './TypeVoucher';
import Voucher from './Voucher';
import DescriptionVoucher from './DescriptionVoucher';


function VoucherSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Voucher" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Voucher/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DescriptionVoucher/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            {/* <DivisionVoucher/> */}
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            {/* <PaidVoucher/> */}
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            {/* <TypeVoucher/> */}
        </div>
        </div>
    </div>
  )
}

export default VoucherSettingsIndex;