import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import Receivable from './Receivable';
import TypeReceivable from './TypeReceivable';
import FromReceivable from './FromReceivable';
import DescriptionReceivable from './DescriptionReceivable';


function ReceivableSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Receivable" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Receivable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <TypeReceivable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <FromReceivable/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
            <DescriptionReceivable/>
        </div>
        
        </div>
    </div>
  )
}

export default ReceivableSettingsIndex;