import React, { useEffect, useState } from 'react'
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';

function CreateCash() {
  const {value:CategoryCash} = useFetchMataData(`/Metadata/category_cash`)
  const {value:paidOrReceivedCash} = useFetchMataData(`/Metadata/paidOrReceived_Cash`)
  // const {value:AcceptedByCash} = useFetchMataData(`/Metadata/accepted_by_cash`)
  // const {value:CreditDebitToCash} = useFetchMataData(`/Metadata/credit_debit_to`)
  // const {value:ApprovedByCash} = useFetchMataData(`/Metadata/approved_by_Cash`)
  // const {value:PreparedByCash} = useFetchMataData(`/Metadata/prepared_by_cash`)
  // const {value:ReviewedByCash} = useFetchMataData(`/Metadata/reviewed_by_cash`)
  // const {value:ToOrFromCash} = useFetchMataData(`/Metadata/toOrfrom_cash`)
  // const {value:deliveredBy} = useFetchMataData(`/Metadata/delivered_by`)

  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  let navigate = useNavigate();
  const [createNew, setCreateNew] = useState({
    branchId:1,
    amount:"",
    category:"",
    date:"",
    paidOrReceived:"",
    toOrFrom:"",
    creditOrDebit:"",
    deliveryBy:"",
    acceptedBy:"",
    dateOfAcceptance:"",
    reviewedBy:"",
    approvedBy:"",
    receivedAndAcknowledged:"",
    preparedBy:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post("/cash/", value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Cash created.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    axiosInstance.get('/cash/',{ params })
    .then((res)=>{
        let finalArray = [];
        console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect (()=>{
    bankDropDown();    
  },[]);

  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Cash</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuDatePicker
              label="Date"
              formik={formik}
              name="date"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuInputNumber
            label="Amount"
            isRequired={true}
            placeholder="Amount"
            formik={formik}
            name="amount"
            width="w-full"
          />
           <NuSelectwithSearch
              label="Category"
              isRequired={true}
              options={CategoryCash}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Paid  / Received"
              isRequired={true}
              options={[
                {value:"Paid",label:"Paid"},
                {value:"Received",label:"Received"}
              ]}
              formik={formik}
              placeholder="Choose"
              name="paidOrReceived"
              width="w-full"
            />
            <NuSelectwithSearch
              label="To / From"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="toOrFrom"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Credit / Debit to"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="creditOrDebit"
              width="w-full"
            />
           <NuSelectwithSearch
              label="Delivered by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="deliveryBy"
              width="w-full"
            />
             
             <NuSelectwithSearch
              label="Accepted by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="acceptedBy"
              width="w-full"
            />
            <NuDatePicker
              label="Date of Acceptance"
              formik={formik}
              name="dateOfAcceptance"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuSelectwithSearch
              label="Reviewed by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="reviewedBy"
              width="w-full"
            />
             <NuSelectwithSearch
              label="Approved by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="approvedBy"
              width="w-full"
            />
            
            <NuInputText
            label="Received and Acknowledged"
            isRequired={true}
            placeholder="Received and Acknowledged"
            formik={formik}
            name="receivedAndAcknowledged"
            width="w-full"
          />
             <NuSelectwithSearch
              label="Prepared by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: 1, label: "Messi" },
                { value: 2, label: "Ronaldo" },
                { value: 3, label: "Naymar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="preparedBy"
              width="w-full"
            />
         

{/* {formik.values.transactionMethod == "cheque" && (
              <NuInputNumber
                label="Cheque Number"
                // isRequired={true}
                placeholder="0"
                formik={formik}
                name="chequeNumber"
                width="w-full"
              />
            )} */}

            {/* {(formik.values.transactionMethod == "Bank"||formik.values.transactionMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={banks}
                placeholder="Choose"
                formik={formik}
                name="bankId"
                width="w-full"
              />
            )} */}
            {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
              <NuInputText
                label="IFSE"
                isRequired={true}
                placeholder="IFSE"
                formik={formik}
                name="ifse"
                width="w-full"
              />
            )}

            {formik.values.transaction_method == "Bank" && (
              <NuInputText
                label="Transaction Reference"
                isRequired={true}
                placeholder="Transaction Reference"
                formik={formik}
                name="transaction_reference"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )}

{formik.values.transaction_method == "Online" && (
              <NuInputText
                label="Online Transaction Number"
                isRequired={true}
                placeholder="Online Transaction Number"
                formik={formik}
                name="online_transaction_number"
                width="w-full"
              />
            )} */}

          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}


export default CreateCash;