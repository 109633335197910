import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../Components/Utils/Buttons';
import DataTable from '../../../Components/Layouts/DataTable';
import View from '../../../Components/Icons/View';
import Edit from '../../../Components/Icons/Edit';
import Delete from '../../../Components/Icons/Delete';

function VatIndex() {
 // const [data, setData] = useState([]);
 const [pageIndex,setPageIndex] = useState(1);
 const [total,setTotal] = useState(0)
 const [dataCount,setDataCount] = useState(20)    
 
 const {officeId} =useParams();

 let headers = [
     {
       label: <span>ID</span>,
       width: "w-[7%]",
     },
     {
       label: <span>Action</span>,
       width: "w-[10%]",
     },
   ];
 const data = [
     {
         "id": 1,
     }
   ]

return (
 <div className="w-full h-full px-4 py-2">
   <div className="w-full h-[46px]  flex justify-between ">
     <BreadCrumb
       items={[
         { title: <p className=" text-[12px] ">Test</p> },
         { title: <p className=" text-[12px] ">Vat</p> },
       ]}
     />
     <div className=" flex gap-2">
       <SearchBar />
       <CreateButton label="Import" />
       <Link to={`/main/vat/${officeId}/create`}>
         <CreateButton label="Vat" />
       </Link>
     </div>
   </div>

   <DataTable
     headers={headers}
     total={total}
     pageIndex={pageIndex}
     setPageIndex={setPageIndex}
     dataCount={dataCount}
     setDataCount={setDataCount}
   >
     {data?.length > 0 ? (
       data.map((item, i) => {
         return (
           <tr
             className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
             key={i}
           >
             <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
               {item?.id || "#"}
             </td>
             <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
               <div className=" w-full flex justify-center items-center gap-2">
                 <Link to={`/main/vat/${officeId}/view/${item?.id}`}>
                   <View />
                 </Link>
                 <Link to={`/main/vat/${officeId}/edit/${item?.id}`}>
                   <Edit />
                 </Link>
                 <Link>
                   <Delete />
                 </Link>
               </div>
             </td>
           </tr>
         );
       })
     ) : (
       <tr className=" text-center my-2 font-semibold text-sm">
         <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
         No Data Found
         </div>
       </tr>
     )}
   </DataTable>
</div>
)
}

export default VatIndex