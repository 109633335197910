import React from 'react'
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import Revenue from './Revenue';
import TypeRevenue from './TypeRevenue';
import DescriptionRevenue from './DescriptionRevenue';

function RevenueSettingsIndex() {
    return (
      <div className=' w-full overflow-y-auto'>
          <TextMediumSM content="Revenue" />
          <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
          {/* <div className=' w-full md:w-1/2 lg:w-1/3'>
              <Revenue/>
          </div> */}
           <div className=' w-full md:w-1/2 lg:w-1/3'>
              <DescriptionRevenue/>
          </div>
          <div className=' w-full md:w-1/2 lg:w-1/3'>
              <TypeRevenue/>
          </div>
          
          </div>
      </div>
    )
  }
  
  export default RevenueSettingsIndex;