import React, { useEffect, useState } from 'react'
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";
import useFetchMataData from '../../../Assets/Hooks/useFetchMataData';
function CreatePettyCash() {
  const {value:StatusPettyCash} = useFetchMataData(`/Metadata/status_pettyCash`)
  const {value:ReviewerDesignation} = useFetchMataData(`/Metadata/reviewer_pettyCash`)
  const {value:RequesterDesignationPettyCash} = useFetchMataData(`/Metadata/requesterDesignation_pettyCash`)
  const {value:PurposePettyCash} = useFetchMataData(`/Metadata/purpose_pettyCash`)
  const {value:paidToPettyCash} = useFetchMataData(`/Metadata/paidTo_PettyCash`)
  const {value:DesignationPettyCash} = useFetchMataData(`/Metadata/designation_pettyCash`)
  // const {value:StatusPettyCash} = useFetchMataData(`/Metadata/status_pettyCash`)
  // const {value:StatusPettyCash} = useFetchMataData(`/Metadata/status_pettyCash`)
  // const {value:StatusPettyCash} = useFetchMataData(`/Metadata/status_pettyCash`)


  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  let navigate = useNavigate();
  const [createNew, setCreateNew] = useState({
    branchId:1,
    cashId:"",
    purpose:"",
    date:"",
    requestedAmount:"",
    requestedBy:"",
    approvedBy:"",
    reviewedBy:"",
    reviewedOn:"",
    approvedOn:"",
    approvedAmount:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post("/petty_cash/", value)
          .then((res) => {
            if (res?.data?.status === true) {
              // send_notif();
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description:res.data.msg || 'Petty-Cash created.',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Failed`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  const bankDropDown = () =>{
    const params = {
      page:1,
      per_page:20,
    };
    axiosInstance.get('/petty_cash/',{ params })
    .then((res)=>{
        let finalArray = [];
        console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect (()=>{
    bankDropDown();    
  },[]);

  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Petty-Cash</p> },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
        <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
        <NuInputNumber
              label="cashId"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="cashId"
              width="w-full"
            />
        <NuSelectwithSearch
              label="Purpose"
              isRequired={true}
              options={PurposePettyCash}
              formik={formik}
              placeholder="Choose"
              name="purpose"
              width="w-full"
            />
          <NuDatePicker
              label="Date"
              formik={formik}
              name="date"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            
             <NuInputNumber
              label="Requested Amount"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="requestedAmount"
              width="w-full"
            />
             <NuSelectwithSearch
              label="Requested by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="requestedBy"
              width="w-full"
            />
     
             <NuSelectwithSearch
              label="Approved by"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="approvedBy"
              width="w-full"
            />
             <NuSelectwithSearch
              label="Reviewed by"
              isRequired={true}
              options={[
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="reviewedBy"
              width="w-full"
            />
            <NuDatePicker
              label="Reviewed on"
              formik={formik}
              name="reviewedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Approved On"
              formik={formik}
              name="approvedOn"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
             <NuSelectwithSearch
              label="Approved Amount"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "offline", label: "Cash" },
                { value: "online", label: "Online" },
                { value: "cheque", label: "Cheque" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="approvedAmount"
              width="w-full"
            />
            
            
           
            
            
             
            
           
             {/* {(formik.values.transactionMethod == 'cheque') && (<NuInputNumber
              label="Cheque Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="chequeNumber"
              width="w-full"
            />)} */}

            {/* {(formik.values.transactionMethod == "Bank"||formik.values.transactionMethod == "online") && (
              <NuSelect
                label="Bank Name"
                isRequired={true}
                options={banks}
                placeholder="Name"
                formik={formik}
                name="bankId"
                width="w-full"
              />
            )} */}
           
           
            {/* {(formik.values.transaction_method == "Bank"||formik.values.transaction_method == "Online") && (
              <NuInputText
                label="IFSE"
                isRequired={true}
                placeholder="IFSE"
                formik={formik}
                name="ifse"
                width="w-full"
              />
            )}

            {formik.values.transaction_method == "Bank" && (
              <NuInputText
                label="Transaction Reference"
                isRequired={true}
                placeholder="Transaction Reference"
                formik={formik}
                name="transaction_reference"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Cash" && (
              <NuInputText
                label="Voucher Number"
                isRequired={true}
                placeholder="Voucher Number"
                formik={formik}
                name="voucher_number"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Online" && (
              <NuInputText
                label="Online Transaction Number"
                isRequired={true}
                placeholder="Online Transaction Number"
                formik={formik}
                name="online_transaction_number"
                width="w-full"
              />
            )} */}

{/* {formik.values.transaction_method == "Check" && (
              <NuInputText
                label="Bank Name"
                isRequired={true}
                placeholder="Name"
                formik={formik}
                name="bank"
                width="w-full"
              />
            )} */}

            
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}



export default CreatePettyCash;