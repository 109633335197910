import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText'
import AccountType from './AccountyType'

function AccountTypeIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Account Type" />
         <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
          <div className=' w-full md:w-1/2 lg:w-1/3'>
            <AccountType/>
          </div>
         </div>
    </div>
  )
}

export default AccountTypeIndex