import { Outlet, useParams } from 'react-router-dom';
import Header from '../../Components/Layouts/MainNavbar';
import { Layout } from 'antd';
import MainSideBar from '../../Components/Layouts/MainSideBar';
import Dasboard from '../../Components/Icons/Dasboard';



function MainPage() {
  const {officeId} = useParams();
    const navItems = [
    { 
    name: 'Dashboard', 
    link:`/main/dashboard/${officeId}`, 
    icon: <Dasboard/>,
    subItems: [] 
   },
   {
    name: 'Accounts', 
    // link:`/main/dashboard/${officeId}`, 
    icon: 'A',
    subMenu:true,
    subItems: [
      { 
        name: 'Vouchers', 
        link:`/main/vouchers/${officeId}`, 
        icon: 'VO',
        subItems: [] 
       },
       { 
        name: 'Payroll', 
        link:`/main/payroll/${officeId}`, 
        icon: 'PR',
        subItems: [] 
       },
       { 
        name: 'Loans & Advances', 
        link:`/main/loans-advances/${officeId}`, 
        icon: 'LA',
        subItems: [] 
       },
       { 
        name: 'Cash', 
        link:`/main/cash/${officeId}`, 
        icon: 'CA',
        subItems: [] 
       },
       { 
        name: 'Petty Cash', 
        link:`/main/petty-cash/${officeId}`, 
        icon: 'PC',
        subItems: [] 
       },
       { 
        name: 'Capital', 
        link:`/main/capital/${officeId}`, 
        icon: 'C',
        subItems: [] 
       },
    ] 
   },
   {
    name:"Receipts",
    icon: 'R',
    subMenu:true,
    subItems: [
      { 
        name: 'Invoice Out ', 
        link:`/main/invoice-out/${officeId}`, 
        icon: 'IO',
        subItems: [] 
       },
      { 
        name: 'Revenue', 
        link:`/main/revenue/${officeId}`, 
        icon: 'R',
        subItems: [] 
      },
      { 
        name: 'Receivable', 
        link:`/main/receivable/${officeId}`, 
        icon: 'RE',
        subItems: [] 
       },
       { 
        name: 'Advance', 
        link:`/main/advance/${officeId}`, 
        icon: 'Ad',
        subItems: [] 
       },
       { 
        name: 'Deductions', 
        link:`/main/deductions/${officeId}`, 
        icon: 'D',
        subItems: [] 
       },
       { 
        name: 'Credit Notes', 
        link:`/main/credit-notes/${officeId}`, 
        icon: 'CN',
        subItems: [] 
       },
    ] 
   },
   {
     name:"Payments",
     icon:"P",
     subMenu:true,
     subItems: [
      { 
        name: 'Invoice In ', 
        link:`/main/invoice-in/${officeId}`, 
        icon: 'IN',
        subItems: [] 
      },
      { 
        name: 'Expense', 
        link:`/main/expense/${officeId}`, 
        icon: 'E',
        subItems: [] 
       },
       { 
        name: 'Payable', 
        link:`/main/payable/${officeId}`, 
        icon: 'P',
        subItems: [] 
       },
       { 
        name: 'Debit Notes', 
        link:`/main/debit-notes/${officeId}`, 
        icon: 'DN',
        subItems: [] 
       },
     ]  
   },
  //  {
  //   name:"Asset",
  //   icon:"A",
  //   subMenu:true,
  //   subItems:[
  //     { 
  //       name: 'Asset Register', 
  //       link:`/main/asset/${officeId}`, 
  //       icon: 'A',
  //       subItems: [] 
  //      },
  //   ]
  //  },
   { 
    name: 'Bank', 
    link:`/main/bank/${officeId}/accounts`, 
    icon: 'B',
    subItems: [] 
   },




  //  { 
  //   name: 'Receipts & Payments', 
  //   link:`/main/receipts-payments/${officeId}`, 
  //   icon: 'RP',
  //   subItems: [] 
  //  },



   { 
    name: 'Ledger', 
    // link:`/main/journal-entry/${officeId}`, 
    icon: 'L',
    subMenu:true,
    subItems: [
      { 
        name: 'Journal Entry', 
        link:`/main/journal-entry/${officeId}`, 
        icon: 'JE',
        subItems: [] 
       },
    ] 
   },
   { 
    name: 'Contacts', 
    // link:`/main/contacts/${officeId}`, 
    icon: 'C',
    subMenu:true,
    subItems: [
      { 
        name: 'Client', 
        link:`/main/client/${officeId}`, 
        icon: 'C',
        subItems: [] 
       },
       { 
        name: 'Staff', 
        link:`/main/staff/${officeId}`, 
        icon: 'S',
        subItems: [] 
       },
       { 
        name: 'Sub Contract', 
        link:`/main/sub-contract/${officeId}`, 
        icon: 'S',
        subItems: [] 
       },
       { 
        name: 'Vendor', 
        link:`/main/vendor/${officeId}`, 
        icon: 'V',
        subItems: [] 
       },
    ] 
   },
   { 
    name: 'VAT', 
    link:`/main/vat/${officeId}`, 
    icon: 'V',
    subItems: [] 
   },
  
  ];
  return (
    <div className=" w-[100vw] h-screen overflow-hidden">
        <Layout className=' w-full h-screen'>
         <MainSideBar navItems={navItems}/>
        <Layout>
        <Header/>
      <Outlet />

        </Layout>
        </Layout>
    </div>
  );
}

export default MainPage;