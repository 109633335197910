import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuMobileNumber, NuSelectwithSearch, NuTextArea } from '../../../../Components/Utils/NuInput';
import NuDate from '../../../../Components/Utils/NuDate';
import AxiosInstance from '../../../../Services/axiosCommon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NuInputEmail, NuInputPassword } from '../../../../Components/Utils/NuInput';
import Attachement from '../../../../Components/Utils/Attachement';

function EditStaff() {
  const {staffEditId} = useParams()
  const [api, contextHolder] = notification.useNotification();
  let navigate = useNavigate();
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    name:"", 
    email:"",
    mobileNo:"",
    gender:"",
    dob:"",
    password:"",
    position:"",
    location:"",
    city:"",
    state:"",
    pincode:"",
    country:"",
    dateOfJoin:"",
    branchId:1
  });

  function handleFileChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("file", tempArr);
    setAttachment(tempArr);
  }

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedImage(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (imagetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log("image", tempArr);
    setImage(tempArr);
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // firstName: yup.string().required("Name Required"),
      // // dob: yup
      // //   .string()
      // //   .required("Date of Birth Required"),
      // gender: yup.string().required("Gender is Required"),
      // mobileNo: yup
      //   .string()
      //   .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(9, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData()
      formData.append('user',JSON.stringify(value) )
      formData.append('attachments',[])
      AxiosInstance.put(`/user/${staffEditId}`,formData).then(res=>{
        if(res.data.status){
         navigate(-1)
        }else{
          alert("not ok")
        }
      }).catch(err=>{
        alert("Error")
      })
      
    },
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Staff</p> },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
      <form onSubmit={formik.handleSubmit}>
          <div className=" w-full h-full flex justify-between items-start gap-2">
            <div className=" w-[74%] h-full">
              <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
               
               
                  <NuInputText
                    label="Name"
                    // isRequired={true}
                    placeholder="Name"
                    formik={formik}
                    name="name"
                    width="w-full"
                  />
                   <NuInputEmail
                  label="Email"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="email"
                  width="w-full"
                />
                 <NuInputNumber
                  label="Mobile"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="mobile"
                  width="w-full"
                />
                 <NuSelectwithSearch
                  label="Gender"
                  isRequired={true}
                  options={[
                  
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="gender"
                  width="w-full"
                />
                <NuDatePicker
              label="DOB"
              formik={formik}
              name="dob"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
                   <NuInputPassword
                    label="Password"
                    // isRequired={true}
                    placeholder=" password"
                    formik={formik}
                    name="password"
                    width="w-full"
                  />
                   <NuSelectwithSearch
                    label="Position"
                    isRequired={true}
                    options={[
                    
                      { value: "Paid", label: "Paid" },
                      { value: "Received", label: "Received" },
                    ]}
                    formik={formik}
                    placeholder="Choose"
                    name="position"
                    width="w-full"
                  />
                   <NuInputText
                    label=" Location"
                    // isRequired={true}
                    placeholder="location"
                    formik={formik}
                    name="location"
                    width="w-full"
                  />
                   <NuInputText
                    label=" City"
                    // isRequired={true}
                    placeholder="city"
                    formik={formik}
                    name="city"
                    width="w-full"
                  />
                   <NuInputText
                    label=" State"
                    // isRequired={true}
                    placeholder="state"
                    formik={formik}
                    name="state"
                    width="w-full"
                  />
                   <NuInputNumber
                  label="Pincode"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="pincode"
                  width="w-full"
                />
                
                
                 <NuInputText
                  label="Country"
                  placeholder="0"
                  // disable={true}
                  formik={formik}
                  name="country"
                  width="w-full"
                />
                <NuDatePicker
              label="Date Of Join"
              formik={formik}
              name="dateOfJoin"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />

        
              </div>
            </div>
            <div className='flex-wrap pb-4'>
            <div className="md:flex justify-between px-3 mt-5 w-full">
              <div className="w-full flex gap-2 items-center">
                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full"
                  label="Vendor Images"
                  multiple={true}
                />
              </div>
            </div>
            <div className="md:flex justify-between px-3 mt-5 w-full">
              <div className="w-full flex gap-2 items-center">
                <Attachement
                  handleChange={handleFileChange}
                  preview={attachment || ""}
                  width="w-full"
                  label="Ref. Docs"
                  multiple={true}
                />
              </div>
            </div>
        </div>
          </div>
          <div className='flex justify-center gap-2'><SubmitButton/><CancelButton handleClick={()=>{navigate(-1)}}/></div>
        </form>
      </div>
    </div>
  );
}

export default EditStaff