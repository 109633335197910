import React, { useEffect, useState } from 'react'
import Delete from '../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../Components/Icons/Edit';
import View from '../../../Components/Icons/View';
import DataTable from '../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../Components/Utils/Buttons';
import SearchBar from '../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function DeductionsIndex() {
  const [data, setData] = useState([]);
  const {officeId} =useParams();
  async function getCash() {
    const params = {
      page:1,
      per_page:20,
    };
    await axiosInstance
      .get(`/deduction/`,{ params })
      .then(async (res) => {
        console.log(res.data.data.cashDetails)
        res?.data?.data && setData(res?.data?.data.deductionDetails
          );
        // setTotalSubs(res.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Advance Id</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Deduction From</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Deducted Amount</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Account Type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Status</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Comments</span>,
      width: "w-[10%]",
    },
   
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getCash();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Deductions</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to="/main/deductions/${officeId}/create">
            <CreateButton label="Deductions" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={100}
        pageIndex={1}
        setPageIndex={() => {}}
        dataCount={20}
        setDataCount={() => {}}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {"#"+item?.deductionId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.advanceId || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.deductionFrom || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.deductedAmount || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.accountType || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.status || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.comments || 0}
                </td>
              
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/deductions/${officeId}/view/${item?.deductionId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/deductions/${officeId}/edit/${item?.deductionId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}


export default DeductionsIndex;