import React from 'react'
import Pagination from '../Utils/Pagenation';

function DataTable({headers,total,pageIndex,setPageIndex,dataCount,setDataCount,children,height="h-[calc(100vh-130px)]"}) {
  return (
    <div className={`w-full ${height} rounded-md pt-1  pb-3`}>
    <div className="w-full h-[93%] overflow-hidden">
  <div className="w-full">
    <div className=" w-full bg-baseColor-secondary rounded-md" >
      <table className="w-full border-collapse  ">
        <thead className=' '>
          <tr className="text-xs  font-medium text-white">
            {headers &&
              headers.map((header, i) => (
                <th
                  className={`py-3 font-medium  ${header.width}`}
                  key={i}
                >
                  {header.label || "-"}
                </th>
              ))}
          </tr>
        </thead>
      </table>
    </div>
    <div className="max-h-[calc(100vh-200px)] z-10 pb-4 overflow-y-auto">
      <table className="w-full table-auto border-separate border-spacing-y-1 border">
        <tbody className="text-[13px] w-full ">
            {children}
        </tbody>
      </table>
    </div>
  </div>
</div>
<div className=' w-full h-[40px] '>
<Pagination
  total={total}
  pageIndex={pageIndex}
  setPageIndex={setPageIndex}
  dataCount={dataCount}
  setDataCount={setDataCount}
/>
</div>
</div>
  )
}

export default DataTable