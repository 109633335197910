import React, { useEffect, useState } from 'react'
import { TextBoldSM } from '../../../../Components/Text/BoldText'
import { CreateButton } from '../../../../Components/Utils/Buttons'
import DataTable from '../../../../Components/Layouts/DataTable';
import AxiosInstance from '../../../../Services/axiosCommon';
import { Link } from 'react-router-dom';
import View from '../../../../Components/Icons/View';
import Edit from '../../../../Components/Icons/Edit';
import Delete from '../../../../Components/Icons/Delete';

function BranchSettingIndex() {
    const [data, setData] = useState([]);
    const [pageIndex,setPageIndex] = useState(1);
    const [total,setTotal] = useState(0)
    const [dataCount,setDataCount] = useState(20)


    async function getBranch(){
        const params = {
            page:pageIndex,
            per_page:dataCount,
          };
          await AxiosInstance
            .get(`/company_branch/`,{ params })
            .then(async (res) => {
                console.log(res.data);
              (await res?.data?.data?.allCompanyBranch) && setData(res?.data?.data.allCompanyBranch);
              res?.data?.data && setTotal(res.data?.data?.totalRecords);
            })
            .catch((error) => {
              console.log(error);
            });
    }


    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[7%]",
        },
        {
          label: <span>Name</span>,
          width: "w-[20%]",
        },
        {
          label: <span>Contact Number</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Email</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Website</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Address</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Currency</span>,
          width: "w-[6%]",
        },
        {
          label: <span>Status</span>,
          width: "w-[6%]",
        },
        
        {
          label: <span>Action</span>,
          width: "w-[10%]",
        },
      ];

      useEffect(()=>{
        getBranch()
      },[pageIndex,dataCount])

  return (
    <div className=' w-full h-full overflow-y-auto'>
      <div className=" w-full flex justify-between ">
        <TextBoldSM content={"Company Info"} />
        {/* <Link to={`/settings/branch-other-business/create`}><CreateButton /></Link> */}
      </div>
      <DataTable
        headers={headers}
        total={total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
        height="h-[calc(100vh-160px)]"
      >
               {data?.length > 0 ? (
          data?.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.branchId || "#"}
                </td>
                <td className="w-[20%] p-2 text-left bg-white">
                  {item?.branchName || ""}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.branchPhone|| ""}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.branchEmail|| ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.website || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.city || ""}
                </td>
                <td className="w-[6%] p-2  text-center bg-white">
                  {item?.currency || ""}
                </td>
                <td className="w-[6%] p-2  text-center bg-white">
                  {item?.isActive==true?"Active":"In-active" || ""}
                </td>
                
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    {/* <Link to={`/main/bank/deposit/view/${item?.depositId}`}>
                      <View />
                    </Link> */}
                    <Link to={`/settings/branch-other-business/edit/${item?.branchId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  )
}

export default BranchSettingIndex