import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import AxiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

function ViewVendor() {
    const { vendorViewId } = useParams();
    const [data, setData] = useState([]);
    async function getCapital() {
      await AxiosInstance
        .get(`/Vendor/${vendorViewId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(()=>{
      getCapital();
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Vendor</p> },
            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
            <Link to={`/main/contracts/vendor/edit/${vendorViewId}`} state={{data:data}}>
              <EditButton />
            </Link>
          </div>
        </div>
        <div>
            
            <div className='w-full pb-5 flex gap-3'>
              <div className='w-1/2  rounded-[20px]  bg-white py-4 px-5 flex flex-wrap gap-4'>
              {/* <h2 className='text-base font-medium text-[#111537]'>Capital Information</h2> */}
                <div className=' w-[48%] flex flex-col gap-[3px] h-full '>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>ID</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.VendorId||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Company Name</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.companyName||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Website</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.website||"-"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Country</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.country||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>State</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.state||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>City</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.city||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Pincode</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.pincode||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Mobile</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.mobile||""}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Email</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.email||"-"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Country-Code-On</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.country_code_on||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Country-Code-Ttwo</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.country_code_two||"#"}</span>
                  </div>
                </div>
    
                <div className=' w-[48%] flex flex-col gap-[3px] h-full'>
                <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Vendor Name</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.vendorName||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Vendor Position</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.vendorPosition||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Vendor Mobile</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.vendorMobile||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Vendor Email</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.vendorEmail||"#"}</span>
                  </div>
                  <div className='flex justify-between items-center w-full'>
                    <span className='py-3 text-sm font-medium text-[#111537]'>Vat Number</span>
                    <span className='py-3 text-sm font-medium text-[#606279]'>{data?.vatNumber||"#"}</span>
                  </div>
                </div>
                
                
                {/* <div className='w-4/12 flex-col'>
                  <p className='py-3 text-sm font-medium text-[#111537]'>ID</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Date</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Description</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Amount</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Towards</p>
                  <p className='py-3 text-sm font-medium text-[#111537]'>Transaction Reference</p>
                </div>
                <div className='w-4/12'>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.capitalId}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.date}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.description}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.amount && '₹'+data.amount}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.towards || '-'}</p>
                  <p className='py-3 text-sm font-medium text-[#606279]'>{data.chequeNumber}</p>
                </div> */}
              </div>
              <div className='w-1/2 flex flex-col gap-3'>
              <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p>
              </div>
              <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
                <p className='py-1 text-sm font-medium text-[#111537]'></p>
                {/* <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p> */}
              </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
  

export default ViewVendor