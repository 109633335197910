import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import AxiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

function ViewStaff() {
  const { staffViewId } = useParams();
  const [data, setData] = useState([]);
  async function getCapital() {
    await AxiosInstance
      .get(`/user/${staffViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getCapital();
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Staff</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          <Link to={`/main/contracts/staff/edit/${staffViewId}`} state={{data:data}}>
            <EditButton />
          </Link>
        </div>
      </div>
      <div>
          
          <div className='w-full pb-5 flex gap-3'>
            <div className='w-1/2  rounded-[20px]  bg-white py-4 px-5 flex flex-wrap gap-4'>
            {/* <h2 className='text-base font-medium text-[#111537]'>Capital Information</h2> */}
              <div className=' w-[48%] flex flex-col gap-[3px] h-full '>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>ID</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.userId||"#"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Name</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.name||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Email</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.email||"-"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Mobile No</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.mobileNo||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Gender</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.gender||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>DOB</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.dob||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Password</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.password||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Position</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.position||""}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Location</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.location||"-"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>City</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.city||"#"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>State</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.state||"#"}</span>
                </div>
              </div>
  
              <div className=' w-[48%] flex flex-col gap-[3px] h-full'>
              <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Pincode</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.pincode||"#"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Country</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.country||"#"}</span>
                </div>
                <div className='flex justify-between items-center w-full'>
                  <span className='py-3 text-sm font-medium text-[#111537]'>Date Of   Join</span>
                  <span className='py-3 text-sm font-medium text-[#606279]'>{data?.dateOfJoin||"#"}</span>
                </div>
               
              </div>
              
              
              {/* <div className='w-4/12 flex-col'>
                <p className='py-3 text-sm font-medium text-[#111537]'>ID</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Date</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Description</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Amount</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Towards</p>
                <p className='py-3 text-sm font-medium text-[#111537]'>Transaction Reference</p>
              </div>
              <div className='w-4/12'>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.capitalId}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.date}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.description}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.amount && '₹'+data.amount}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.towards || '-'}</p>
                <p className='py-3 text-sm font-medium text-[#606279]'>{data.chequeNumber}</p>
              </div> */}
            </div>
            <div className='w-1/2 flex flex-col gap-3'>
            <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
              <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
              <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p>
            </div>
            <div className='w-full rounded-[20px] bg-white py-4 px-5 h-[262.469px]'>
              <p className='py-1 text-sm font-medium text-[#111537]'></p>
              {/* <p className='py-1 text-sm font-medium text-[#606279]'>{data.comments}</p> */}
            </div>
            </div>
          </div>
      </div>
    </div>
  )
}

  

export default ViewStaff