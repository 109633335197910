import { Breadcrumb } from 'antd';

export function BreadCrumb({items}){
    return (
        <Breadcrumb
        style={{margin:'2px 0'}}
        separator="/"
        items={items}
      />
    )
}

export default BreadCrumb;