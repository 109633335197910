import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText'
import Deposit from './Deposit/Deposit'

function BankSettingsIndex() {
  return (
    <div className=' w-full overflow-y-auto'>
        <TextMediumSM content="Bank" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
         <div className=' w-full md:w-1/2 lg:w-1/3'>
            <Deposit />
         </div>
        </div>
    </div>
  )
}

export default BankSettingsIndex