import React, { useEffect, useState } from 'react'
import ViewFiles from '../../../Components/Utils/viewFiles';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';

function ViewAdvance() {
    const { advanceViewId,officeId } = useParams();
    const [data, setData] = useState([]);
    async function getCapital() {
      await axiosInstance
        .get(`/advance/${advanceViewId}`)
        .then(async (res) => {
          console.log("find", res?.data?.data);
          (await res?.data?.data) && setData(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(()=>{
      getCapital();
    },[]);
    return (
      <div className="w-full h-full px-4 py-2">
        <div className="w-full h-[46px]  flex justify-between ">
          <BreadCrumb
            items={[
              { title: <p className=" text-[12px] ">Test</p> },
              { title: <p className=" text-[12px] ">Advance</p> },
              { title: <p className=" text-[12px] ">View</p> },


            ]}
          />
          <div className=" flex gap-2">
            <BackButton />
            <Link to={`/main/advance/${officeId}/edit/${advanceViewId}`}>
              <EditButton />
            </Link>
          </div>
        </div>
        <div>
            <h2 className='text-base font-medium text-[#111537]'>Advance Information</h2>
            <div className='w-full py-5 flex gap-3'>
              <div className='w-1/2 rounded-[20px] pb-10 bg-white py-4 px-5 flex gap-2'>
                <div className='w-6/12 flex-col'>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.advanceId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Account Type</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.accountType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Amount</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.amount && "₹"+data?.amount  || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved By</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedBy || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Approved On</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.approvedOn || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Bank Id</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.bankId ||'-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Deduction Frequency</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.deductionFrequency || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Deduction Start Date</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.deductionStartDate || '-'}</p>
                  </div>
                </div>
                <div className='w-6/12 flex-col'>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Deduction Type</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.deductionType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Designation</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.designation || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Division </p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.division || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Issued On</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.issuedOn || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Issued To</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.issuedTo || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Payment Method</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.paymentMethod || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-3 pl-5 text-sm font-medium text-[#606279]'>{data?.status || '-'}</p>
                  </div>
                </div>
              </div>
              <div className='w-1/2'>
                <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] mb-3'>
                  <p className='py-1 text-sm font-medium text-[#111537]'>Comments</p>
                  <p className='py-1 text-sm font-medium text-[#606279]'>{data?.comments}</p>
                </div>
                <div className='rounded-[20px] bg-white py-4 px-5 h-[160.469px] max-h-[260.469px]'>
                  <p className='py-1 text-sm font-medium text-[#111537]'>Advance Images</p>
                  <div className="flex flex-wrap gap-3">
                  {data?.advanceImage &&
                    data?.advanceImage.map((imgs, idx) => {
                      return (
                        <div
                          className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                          key={idx}
                          style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                        >
  
                          <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                            {imgs && <ViewFiles data={imgs} key={idx} />}
                          </div>
                        </div>
                      );
                    })}
                </div>
                </div>
              </div>
              
            </div>
        </div>
      </div>
    )
  }
export default ViewAdvance