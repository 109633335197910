import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Submenu = ({ subItems }) => {
  const [topPosition, setTopPosition] = useState(0);
 
 
  useEffect(() => {
    const screenHeight = window.innerHeight;
    const submenuHeight = subItems.length * 80; // Adjust this value based on the actual submenu item height
    if (screenHeight < submenuHeight) {
      console.log(screenHeight - submenuHeight);
      setTopPosition(screenHeight - submenuHeight);
    }
  }, [subItems]);

  return(
  
    <div   style={{ top: `${topPosition}px` }}
     className={`absolute left-full  bg-baseColor-primary  w-48 shadow-lg  transition-all duration-500 ease-in-out`}>
      {subItems.map((subItem, subIndex) => (
        <Link
          key={subIndex}
          to={`${subItem.link}`}
          className="flex items-center px-3 py-2 text-gray-100 hover:bg-gray-100 hover:text-baseColor-primary"
        >
          {/* <i className={`fas fa-${subItem.icon} text-lg mr-2`}></i> */}
          {subItem.name}
        </Link>
      ))}
    </div>
  );
}


function MainSideBar({navItems}) {
  const location = useLocation()
  let navpath =location.pathname.split("/")
  let navslice = navpath.slice(0, 3)
  let joinedString = navslice.join('/');
  console.log(joinedString);
  const { Sider } = Layout;

  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const handleMouseEnter = (itemName) => {
    setActiveItem(itemName);
    setSidebarExpanded(true);
  };

  const handleMouseLeave = () => {
    setActiveItem(null);
    setSidebarExpanded(false);
  };



  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      width="70px"
      style={{
        borderRight: '1px solid #E5E8ED',
        zIndex:"99"
      }}
    >
      <div className='w-full h-screen bg-baseColor-primary relative z-10'>
        
        
        <div
        className={`bg-baseColor-primary shadow-lg text-white h-screen ${isSidebarExpanded ? 'w-38' : 'w-[70px]'} fixed flex flex-col  items-center transition-all text-xs ease-in-out`}
        //   className={`bg-red-600 text-white h-screen ${isSidebarExpanded ? 'w-36' : 'w-[75px]'} fixed flex flex-col items-center transition-transform duration-500 ease-in-out`}
          onMouseEnter={() => setSidebarExpanded(true)}
          onMouseLeave={() => setSidebarExpanded(false)}
        >
          <div className=' h-[38px] text-center mt-3'>Logo</div>
          {navItems&&navItems.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(item.name)}
              onMouseLeave={handleMouseLeave}
              className={`relative w-full  ${
                activeItem === item.name ? 'bg-gray-100 text-baseColor-primary' : ''
              }`}
            >
              {item.subMenu && item.subItems.length > 0 ? (
                <div className="flex gap-2 items-center px-7 py-2 w-full">
                  {/* <span>W</span> */}
                  <span>{item.icon}</span>
                  {isSidebarExpanded && <span>{item.name}</span>}
                </div>
              ) : (
                <NavLink

                  to={`${item.link}`}
                  
                  className="flex gap-2 text-white hover:text-baseColor-primary  items-center px-7 py-2 w-full"
                >
                  {/* <span>{location.pathname.split("/")[2] == item.link.split("/")[2]?"Yes":"No"}</span> */}
                  <span>{item.icon}</span>
                  {isSidebarExpanded && <span>{item.name}</span>}
                </NavLink>
              )}

              {item.subMenu  && item.subItems.length > 0 && activeItem === item.name && (
                <Submenu subItems={item.subItems} />
              )}
            </div>
          ))}
        </div>
      </div>
    </Sider>
  );
}

export default MainSideBar;
