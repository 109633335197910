import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuSelect, NuSelectwithSearch, NuTextArea } from '../../../Components/Utils/NuInput';
import NuDate from '../../../Components/Utils/NuDate';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import * as yup from "yup";

function EditVat() {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const navigate = useNavigate();
  const { accountsEditId } = useParams();
  const [createNew, setCreateNew] = useState({
    branchId:1,
    accountType:"",
    accountName:"",
    accountNumber:"",
    bankName:"",
    address:"",
    routingNumber:"",
    ifscCode:"",
    swiftBicCode:"",
    micrCode:"",
    contactNumber:"",
    email:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({

    }),
    onSubmit: async (value, { resetForm }) => {
      await axiosInstance.put(`/bank/${accountsEditId}`, value)
          .then(async (res) => {
            // console.log("ficcc",res?.data?.status == true);
             if (res?.data?.status == true) {
              // send_notif();
               api.success({
                message: 'Success',
                description:res.data.msg || 'Accounts Updated.',
                placement: "bottomRight",
              });
              isLoading(false)
              resetForm();
              navigate(-1);
            } else {
              api.error({
                message: `Failed in Update`,
                description: res?.data?.msg || 'Network error',
                placement: "bottomRight",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            api.error({
              message: `Failed`,
              description: err?.data?.msg || 'Network error',
              placement: "bottomRight",
            });
          });
    },
  });

  async function getCapital() {
    await axiosInstance
      .get(`/bank/${accountsEditId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setCreateNew(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const bankDropDown = () =>{

    axiosInstance.get('/bank/',)
    .then((res)=>{
        let finalArray = [];
        // console.log(res?.data?.data,'ddddd');
        let banks = res?.data?.data.bankDetails
        banks.forEach(element => {
            let tempData = {value:element?.bankId, label:element?.bankName};
            finalArray.push(tempData);
        });
        res?.data?.data && setBanks(finalArray);
       })
      .catch(
        (err)=>{
          console.log(err);
        }
      )
}
  useEffect(()=>{
    getCapital();
    bankDropDown();    
  },[]);
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Bank Changes</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[calc(100%-55px)]">
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3">
          <NuSelectwithSearch
              label="Account Type"
              isRequired={true}
              options={[
                // { value: "Bank", label: "Bank" },
                { value: "current", label: "Current" },
                { value: "savings", label: "Saving" },
               
              ]}
              formik={formik}
              placeholder="Choose"
              name="accountType"
              width="w-full"
            />
             <NuInputText
                label="Account Name"
                isRequired={true}
                placeholder="Account Name"
                formik={formik}
                name="accountName"
                width="w-full"
            />
           
            <NuInputNumber
              label="Account Number"
              placeholder="0"
              // disable={true}
              formik={formik}
              name="accountNumber"
              width="w-full"
            />
           
            <NuTextArea
                label="Address"
                // isRequired={true}
                placeholder="Address"
                formik={formik}
                name="address"
                width="w-full"
            />
          </div>
          <div className='flex justify-center gap-2'>
            <SubmitButton/>
            <CancelButton handleClick={()=>{navigate(-1)}}/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditVat