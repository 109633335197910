import axios from "axios";

const SERVER_URL = window.WEB_APP_API_URL;

const auth_key = "userToken";

const getAuthKey = () => {
  const tokenString = localStorage.getItem(auth_key);
  const userToken = JSON.parse(tokenString);
  if (!userToken) {
    // toast.warning('please login again')
  }
  return userToken || "";
};

let token = getAuthKey() || "";
const AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    token: token,
  },
});

AxiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      if (response.data?.message === "Token is invalid") {
        localStorage.removeItem(auth_key);
        window.location = `/auth/login`;
      } else {
        resolve(response);
      }
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.removeItem(auth_key);
      window.location = `/login`;
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default AxiosInstance;