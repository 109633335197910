import React, { useEffect, useState } from 'react'
import Delete from '../../../../Components/Icons/Delete';
import { Link, useParams } from 'react-router-dom';
import Edit from '../../../../Components/Icons/Edit';
import View from '../../../../Components/Icons/View';
import DataTable from '../../../../Components/Layouts/DataTable';
import { CreateButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import axiosInstance from '../../../../Services/axiosCommon';

function WithdrawIndex() {
  const [data, setData] = useState([]);
  const {officeId} =useParams();
  const [pageIndex,setPageIndex] = useState(1);
  const [total,setTotal] = useState(0)
  const [dataCount,setDataCount] = useState(20)

  async function getWithdraw() {
    const params = {
      page:pageIndex,
      per_page:dataCount,
    };
    await axiosInstance 
      .get(`/withdraw/`,{ params })
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data.withdrawDetails
          );
        setTotal(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Bank Id</span>,
      width: "w-[10%]",
    },
   
    {
      label: <span>Amount</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Withdrawn Method</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Withdraw On</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Withdraw By</span>,
      width: "w-[10%]",
    },
   
    {
      label: <span>Command</span>,
      width: "w-[10%]",
    },
   
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];
  useEffect(()=>{
    getWithdraw();
  },[pageIndex,dataCount]);
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Withdraw</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/bank/${officeId}/withdraw/create`}>
            <CreateButton label="Withdraw" />
          </Link>
        </div>
      </div>
      <div className=' w-full flex gap-2 '>
      <Link to={`/main/bank/${officeId}/accounts`}><CreateButton svg={false} label='Bank Accounts' color='bg-white' textColor='text-black'  /></Link>
      <Link to={`/main/bank/${officeId}/deposit`}><CreateButton svg={false} label='Deposit' color='bg-white' textColor='text-black'/></Link>
      <CreateButton svg={false} label='Withdrawals'  />
      <Link to={`/main/bank/${officeId}/interal-transfer`}><CreateButton svg={false} label='Internal Transaction' color='bg-white' textColor='text-black' /></Link>
      <Link to={`/main/bank/${officeId}/check-clearnce`}><CreateButton svg={false} label='Check Clearence' color='bg-white' textColor='text-black' /></Link>    
      <Link to={`/main/bank/${officeId}/bank-charges`}><CreateButton svg={false} label='Bank Charges' color='bg-white' textColor='text-black' /></Link>  
      </div>
      
      
      <DataTable
        headers={headers}
        total={total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
      >
        {data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {"#"+item?.withdrawId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.bankId || ""}
                </td>
                
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.amount || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.withdrawnMethod || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.withdrawnOn || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.withdrawnBy || 0}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.comments || 0}
                </td>
               
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/bank/${officeId}/withdraw/view/${item?.withdrawId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/bank/${officeId}/withdraw/edit/${item?.withdrawId}`}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-250px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}
export default WithdrawIndex;