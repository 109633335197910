import { Layout } from "antd";
import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import Header from "../../Components/Layouts/MainNavbar";
import { BackButton } from "../../Components/Utils/Buttons";
import { TextSM } from "../../Components/Text/NormalText";

function MainSettings() {
  let { Content } = Layout;
  const settingsItems = [
    {
      name: "Company Info",
      link: `/settings/company-info`,
    },
    {
      name: "Branch / Other Business",
      link: `/settings/branch-other-business`,
    },
    {
      name: "Payment Method",
      link: `/settings/payment-method`,
    },
    // {
    //   name: "Capital",
    //   link: `/settings/capital`,
    // },
    {
      name: "Cash",
      link: `/settings/cash`,
    },
    // {
    //   name: "Credit Notes",
    //   link: `/settings/credit-notes`,
    // },
    // {
    //   name: "Debit Notes",
    //   link: `/settings/debit-notes`,
    // },
    // {
    //   name: "Advance",
    //   link: `/settings/advance`,
    // },
    // {
    //   name: "Deductions",
    //   link: `/settings/deductions`,
    // },
    // {
    //   name: "Asset",
    //   link: `/settings/asset`,
    // },
    {
      name: "Bank",
      link: `/settings/bank`,
    },
    // {
    //   name: "Temp",
    //   link: `/settings/temp`,
    // },
    {
      name: "Revenue",
      link: `/settings/revenue`,
    },
    {
      name: "Expense",
      link: `/settings/expense`,
    },
    {
      name: "Job Position",
      link: `/settings/job-position`,
    },
    {
      name: "Account Type",
      link: `/settings/account-type`,
    },
    // {
    //   name: "InvoiceOut ",
    //   link: `/settings/invoice-out`,
    // },
    // {
    //   name: "InvoiceIn ",
    //   link: `/settings/invoice-in`,
    // },
    // {
    //   name: "Receipts & Payments",
    //   link: `/settings/receipts-payments`,
    // },
    // {
    //   name: "Payable",
    //   link: `/settings/payable`,
    // },
    // {
    //   name: "Payroll",
    //   link: `/settings/payroll`,
    // },
    // {
    //   name: "Receivable",
    //   link: `/settings/receivable`,
    // },
    // {
    //   name: "Petty Cash",
    //   link: `/settings/petty-cash`,
    // },
    // {
    //   name: "Journal Entry",
    //   link: `/settings/journal-entry`,
    // },
    // {
    //   name: "Contracts",
    //   link: `/settings/contracts`,
    // },
    // {
    //   name: "Loans & Advances",
    //   link: `/settings/loans-advances`,
    // },
    // {
    //   name: "VAT",
    //   link: `/settings/vat`,
    // },
    {
      name: "Vouchers",
      link: `/settings/vouchers`,
    },
  ];
  return (
    <div className=" w-[100vw] h-screen">
      <Layout className=" w-full h-screen">
        <Layout>
          <Header />
          <Content className=" py-2 px-6">
            {/* <div className=" w-full h-[35px] flex justify-end ">
              <BackButton />
            </div> */}
            <div className=" w-full flex gap-4 items-center h-[calc(100vh-110px)] ">
              <div className="w-[15%] bg-white rounded-lg shadow-lg h-[calc(100vh-110px)] p-2 overflow-y-hidden">
                <div className=" w-full h-full overflow-y-auto">
                  {settingsItems &&
                    settingsItems.map((item) => {
                      return (
                        <div className=" my-2">
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? " bg-baseColor-secondary rounded-lg px-4 py-1 w-full text-xs font-medium hover:text-white text-white"
                                : "  hover:bg-baseColor-secondary rounded-lg px-4 py-1 text-xs font-medium w-full hover:text-white text-black"
                            }
                            to={item.link}
                          >
                            <span>{item.name}</span>
                          </NavLink>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="w-[85%] bg-white rounded-lg shadow-lg h-[calc(100vh-110px)] p-2 overflow-y-hidden">
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainSettings;
