import React, { useEffect } from 'react'
import { useState } from 'react';
import Edit from '../../../Components/Icons/Edit';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import SearchBar from '../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../Components/Layouts/DataTable';
import View from '../../../Components/Icons/View';
import Delete from '../../../Components/Icons/Delete';
import AxiosInstance from '../../../Services/axiosCommon';
import NuDate from '../../../Components/Utils/NuDate';

function VouchersIndex() {
  const {officeId} =useParams();
  const [data, setData] = useState([]);
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [totalCount,setTotalCount] = useState(0)


  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[7%]",
    },
    {
      label: <span>Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Description</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Received/Paid</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Payment type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Received from / Paid to</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Comments</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Action</span>,
      width: "w-[10%]",
    },
  ];

   function getData (pageIndex,pageCount){
     AxiosInstance.get(`/voucher/?page=${pageIndex}&per_page=${pageCount}`).then(res=>{
      console.log(res.data);
      res?.data?.data?.voucherDetails && setData(res?.data?.data?.voucherDetails)
      setTotalCount(res?.data?.data?.totalRecords)
    }).catch(err=>{
      console.log("err",err);
    })
  }


  useEffect(()=>{
    if(page && pageCount){
      getData(page,pageCount)
    }
  
  },[page,pageCount])

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            { title: <p className=" text-[12px] ">Test</p> },
            { title: <p className=" text-[12px] ">Vouchers</p> },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <CreateButton label="Import" />
          <Link to={`/main/vouchers/${officeId}/create`}>
            <CreateButton label="Vouchers" />
          </Link>
        </div>
      </div>

      <DataTable
        headers={headers}
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
      >
        {data.length > 0 ? (
          data.map((item, i) => {
            return (
              <tr
                className=" p-1 hover:scale-y-105 rounded-l-md hover:shadow-md"
                key={i}
              >
                <td className="w-[7%] py-3 my-6 text-center bg-white rounded-l-md ">
                  {item?.voucherId || "#"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  { item?.date!="" && item?.date!="None" && <NuDate value={item?.date} format="DD-MMM-YYYY"/> || ""}
                </td>
                <td className="w-[15%] p-2 text-center bg-white">
                  {item?.description || "-"}
                </td>
                <td className="w-[10%] p-2 text-center bg-white">
                  {item?.receivedOrPaid || ""}
                </td>
                {/* <td className="w-[15%] p-2 text-right ">test</td> */}
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.paymentType || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.receivedFromOrPaidTo || ""}
                </td>
                <td className="w-[10%] p-2  text-center bg-white">
                  {item?.comments || ""}
                </td>
                <td className="w-[10%] p-2 text-center bg-white rounded-r-md">
                  <div className=" w-full flex justify-center items-center gap-2">
                    <Link to={`/main/vouchers/${officeId}/view/${item?.voucherId}`}>
                      <View />
                    </Link>
                    <Link to={`/main/vouchers/${officeId}/edit/${item?.voucherId}`} state={{data:item}}>
                      <Edit />
                    </Link>
                    <Link>
                      <Delete />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            <div className=" w-full h-[calc(100vh-240px)] rounded-md flex justify-center items-center bg-slate-300">
            No Data Found
            </div>
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default VouchersIndex;