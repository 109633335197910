import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const auth_key = "userToken";

  const getAuthKey = () => {
    const tokenString = localStorage.getItem(auth_key);
    const userToken = JSON.parse(tokenString);
    if (!userToken) {
      return false;
    } else {
      return true;
    }
  };
  const auth = getAuthKey();
  return auth ? children : <Navigate to="/auth/login" />;
}

export default PrivateRoute;